.ourprocess-container {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
}
.ourprocess-header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.ourprocess-heading {
  color: rgb(255, 255, 255);
  font-size: 48px;
  text-align: center;
  font-family: Space Grotesk;
}
.ourprocess-text {
  font-weight: 600;
}
.ourprocess-text01 {
  color: var(--dl-color-primary-purple);
  font-weight: 600;
  text-decoration: underline;
}
.ourprocess-cards {
  gap: var(--dl-space-space-fourunits);
  width: auto;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.ourprocess-container01 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourprocess-card {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container02 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container03 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description1 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-container04 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourprocess-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container05 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description2 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header4 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container06 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description3 {
  color: rgb(194, 198, 204);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-text10 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-cards1 {
  gap: var(--dl-space-space-fourunits);
  width: auto;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.ourprocess-container07 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourprocess-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header5 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container08 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description4 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header6 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container09 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description5 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-container10 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourprocess-card6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content6 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header7 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container11 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description6 {
  color: rgb(194, 198, 204);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-text15 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-card7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 420px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourprocess-content7 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourprocess-header8 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourprocess-container12 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourprocess-description7 {
  color: rgb(194, 198, 204);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourprocess-text17 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
@media (max-width: 991px) {
  .ourprocess-header {
    gap: var(--dl-space-space-unit);
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ourprocess-heading {
    align-self: center;
  }
  .ourprocess-cards {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .ourprocess-container01 {
    width: 100%;
  }
  .ourprocess-card {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-card1 {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-container04 {
    width: 100%;
  }
  .ourprocess-card2 {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-card3 {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-cards1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .ourprocess-container07 {
    width: 100%;
  }
  .ourprocess-card4 {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-card5 {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-container10 {
    width: 100%;
  }
  .ourprocess-card6 {
    width: 50%;
    min-height: 254px;
  }
  .ourprocess-card7 {
    width: 50%;
    min-height: 254px;
  }
}
@media (max-width: 767px) {
  .ourprocess-container {
    gap: var(--dl-space-space-fourunits);
  }
  .ourprocess-header {
    padding-bottom: 0px;
  }
  .ourprocess-heading {
    font-size: 38px;
  }
  .ourprocess-cards {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .ourprocess-card {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-card1 {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-card2 {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-card3 {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-cards1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .ourprocess-card4 {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-card5 {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-card6 {
    width: 100%;
    max-width: 100%;
  }
  .ourprocess-card7 {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 479px) {
  .ourprocess-container {
    gap: var(--dl-space-space-fourunits);
  }
  .ourprocess-heading {
    font-size: 38px;
  }
  .ourprocess-cards {
    gap: var(--dl-space-space-fourunits);
  }
  .ourprocess-container01 {
    flex-direction: column;
  }
  .ourprocess-container04 {
    flex-direction: column;
  }
  .ourprocess-cards1 {
    gap: var(--dl-space-space-fourunits);
  }
  .ourprocess-container07 {
    flex-direction: column;
  }
  .ourprocess-container10 {
    flex-direction: column;
  }
}
