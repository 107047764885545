.pricing-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 61%
  );
}
.pricing-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.pricing-tagline {
  position: relative;
  top: -30px;
}
.pricing-tagline-h {
  color: var(--dl-color-primary-purple);
}
.pricing-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.pricing-navlink {
  display: contents;
}
.pricing-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.pricing-desktop-menu {
  display: flex;
}
.pricing-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.pricing-navlink01 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-navlink01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.pricing-navlink02 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.pricing-navlink02:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.pricing-navlink03 {
  color: #ffffff;
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-purple);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-bottom-width: 1px;
}
.pricing-navlink03:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.pricing-navlinkk04 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.pricing-navlinkk04:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.build-your-team-navlink6 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.pricing-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.pricing-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.pricing-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.pricing-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.pricing-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.pricing-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.pricing-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.pricing-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.pricing-navlink04 {
  display: contents;
}
.pricing-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.pricing-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-icon2 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.pricing-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-navlink05 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.pricing-navlink06 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.pricing-navlink07 {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.pricing-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.pricing-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.pricing-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.pricing-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.pricing-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.pricing-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.pricing-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-image {
  width: 150px;
  object-fit: cover;
}
.pricing-icon4 {
  width: 28px;
  height: 28px;
}
.pricing-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.pricing-text {
  text-decoration: none;
}
.pricing-text001 {
  text-decoration: none;
}
.pricing-text002 {
  text-decoration: none;
}
.pricing-text003 {
  text-decoration: none;
}
.pricing-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.pricing-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.pricing-text004 {
  font-style: normal;
  font-weight: 500;
}
.pricing-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.pricing-text005 {
  font-style: normal;
  font-weight: 500;
}
.pricing-banner {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pricing-text006 {
  color: rgb(255, 255, 255);
  font-size: 4rem;
  text-align: center;
  font-family: Space Grotesk;
}
.pricing-text007 {
  color: #ffffff;
  font-size: 3.5rem;
}
.pricing-text008 {
  color: var(--dl-color-primary-purple);
  font-size: 3.5rem;
  text-decoration: underline;
}
.pricing-text009 {
  color: #ffffff;
  font-size: 3.5rem;
}
.pricing-text011 {
  color: var(--dl-color-gray-900);
  text-align: center;
  font-family: Space Grotesk;
}
.pricing-separator {
  width: 100%;
  height: 0.5px;
  background-color: var(--dl-color-primary-skybue);
}
.pricing-pricing {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pricing-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pricing-container04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-size: cover;
  justify-content: center;
}
.pricing-container05 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-pricing-card {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pricing-pricing-card:hover {
  transform: scale(1.02);
}
.pricing-container06 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-text012 {
  color: var(--dl-color-gray-white);
  font-size: 36px;
  align-self: flex-start;
  font-family: Space Grotesk;
  font-weight: 600;
  text-transform: capitalize;
}
.pricing-text013 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.pricing-separator1 {
  width: 100%;
  height: 0.5px;
  background-color: rgba(207, 217, 240, 0.5);
}
.pricing-container07 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-text016 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgb(31, 29, 38);
}
.pricing-text017 {
  color: var(--dl-color-primary-purple);
}
.pricing-text019 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
}
.pricing-text020 {
  color: var(--dl-color-primary-purple);
}
.pricing-text022 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgb(31, 29, 38);
}
.pricing-text023 {
  color: var(--dl-color-primary-purple);
}
.pricing-text025 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
}
.pricing-text026 {
  color: var(--dl-color-primary-purple);
}
.pricing-text028 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgb(31, 29, 38);
}
.pricing-text029 {
  color: var(--dl-color-primary-purple);
}
.pricing-separator2 {
  width: 100%;
  height: 0.5px;
  background-color: rgba(207, 217, 240, 0.5);
}
.pricing-container08 {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.pricing-text031 {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.pricing-text032 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-family: Space Grotesk;
}
.pricing-navlink08 {
  color: #ffffff;
  width: 100%;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.pricing-navlink08:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.pricing-text033 {
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.pricing-pricing-card1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pricing-pricing-card1:hover {
  transform: scale(1.02);
}
.pricing-container09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container10 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-text034 {
  color: var(--dl-color-gray-white);
  font-size: 36px;
  font-family: Space Grotesk;
  font-weight: 600;
  text-transform: capitalize;
}
.pricing-text035 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-top: 10px;
  padding-left: 12px;
  border-radius: 8px;
  padding-right: 12px;
  padding-bottom: 10px;
  text-transform: capitalize;
  background-color: var(--dl-color-primary-purple);
}
.pricing-text036 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.pricing-separator3 {
  width: 100%;
  height: 0.5px;
  background-color: rgba(207, 217, 240, 0.5);
}
.pricing-container11 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-text039 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgb(31, 29, 38);
}
.pricing-text040 {
  color: var(--dl-color-primary-purple);
}
.pricing-text042 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
}
.pricing-text043 {
  color: var(--dl-color-primary-purple);
}
.pricing-text045 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgb(31, 29, 38);
}
.pricing-text046 {
  color: var(--dl-color-primary-purple);
}
.pricing-text048 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
}
.pricing-text049 {
  color: var(--dl-color-primary-purple);
}
.pricing-text051 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgb(31, 29, 38);
}
.pricing-text052 {
  color: var(--dl-color-primary-purple);
}
.pricing-separator4 {
  width: 100%;
  height: 0.5px;
  background-color: rgba(207, 217, 240, 0.5);
}
.pricing-container12 {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.pricing-text054 {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.pricing-text055 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-family: Space Grotesk;
}
.pricing-navlink09 {
  color: #ffffff;
  width: 100%;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.pricing-navlink09:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.pricing-text056 {
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.pricing-pricing-card2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pricing-pricing-card2:hover {
  transform: scale(1.02);
}
.pricing-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-text057 {
  color: var(--dl-color-gray-white);
  font-size: 36px;
  align-self: flex-start;
  font-family: Space Grotesk;
  font-weight: 600;
  text-transform: capitalize;
}
.pricing-text058 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.pricing-separator5 {
  width: 100%;
  height: 0.5px;
  background-color: rgba(207, 217, 240, 0.5);
}
.pricing-container14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-text061 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: #1f1d26;
}
.pricing-text062 {
  color: var(--dl-color-primary-purple);
}
.pricing-text064 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
}
.pricing-text065 {
  color: var(--dl-color-primary-purple);
}
.pricing-text067 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: #1f1d26;
}
.pricing-text068 {
  color: var(--dl-color-primary-purple);
}
.pricing-text070 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
}
.pricing-text071 {
  color: var(--dl-color-primary-purple);
}
.pricing-text073 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  padding: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: #1f1d26;
}
.pricing-text074 {
  color: var(--dl-color-primary-purple);
}
.pricing-separator6 {
  width: 100%;
  height: 0.5px;
  background-color: rgba(207, 217, 240, 0.5);
}
.pricing-container15 {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.pricing-text076 {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.pricing-text077 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-family: Space Grotesk;
}
.pricing-navlink10 {
  color: #ffffff;
  width: 100%;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.pricing-navlink10:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.pricing-text078 {
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.pricing-container16 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.pricing-text079 {
  color: var(--dl-color-primary-skybue);
  width: 100%;
  text-align: center;
  font-family: Space Grotesk;
  padding-top: 18px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 8px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 18px;
  background-color: rgb(31, 29, 38);
}
.pricing-navlink11 {
  text-decoration: underline;
}
.pricing-navlink12 {
  text-decoration: underline;
}
.pricing-container17 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.pricing-container18 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.pricing-text083 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-unit);
  font-size: 56px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.pricing-text085 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.pricing-container19 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.pricing-container20 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.pricing-text086 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.pricing-textinput {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.pricing-container21 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.pricing-text091 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.pricing-textinput1 {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.pricing-container22 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.pricing-text096 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.pricing-textinput2 {
  color: #ffffff;
  width: 716px;
  height: var(--dl-size-size-large);
  align-items: stretch;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.pricing-button {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #6741ff;
}

.pricing-text101 {
  align-self: center;
  font-family: Space Grotesk;
}
.pricing-container23 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 1200px) {
  .pricing-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .pricing-text086 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .pricing-textinput {
    width: 300px;
    height: 46px;
  }
  .pricing-text091 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .pricing-textinput1 {
    width: 300px;
    height: 46px;
  }
  .pricing-text096 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .pricing-textinput2 {
    width: 616px;
    height: var(--dl-size-size-large);
  }
  .pricing-button {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .pricing-desktop-menu {
    display: none;
  }
  .pricing-btn-group {
    display: none;
  }
  .pricing-burger-menu {
    display: flex;
  }
  .pricing-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .pricing-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .pricing-banner {
    padding: var(--dl-space-space-twounits);
  }
  .pricing-text006 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-family: Space Grotesk;
  }
  .pricing-container04 {
    flex-direction: column;
  }
  .pricing-container05 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pricing-pricing-card {
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .pricing-pricing-card1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .pricing-pricing-card2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .pricing-text086 {
    text-align: left;
    padding-left: 0px;
  }
  .pricing-text091 {
    text-align: left;
    padding-left: 0px;
  }
  .pricing-text096 {
    text-align: left;
    padding-left: 0px;
  }
  .pricing-textinput2 {
    height: var(--dl-size-size-large);
  }
  .pricing-button {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .pricing-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .pricing-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .pricing-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .pricing-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .pricing-container04 {
    flex-flow: column;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .pricing-pricing-card {
    width: 100%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .pricing-pricing-card1 {
    width: 100%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .pricing-pricing-card2 {
    width: 100%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .pricing-container19 {
    flex-direction: column;
  }
  .pricing-textinput {
    width: 440px;
  }
  .pricing-textinput1 {
    width: 440px;
  }
  .pricing-container22 {
    align-self: center;
  }
  .pricing-textinput2 {
    width: 440px;
  }
  .pricing-button {
    align-self: center;
  }
}
@media (max-width: 479px) {
  .pricing-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .pricing-mobile-menu {
    padding: 16px;
  }
  .pricing-text006 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .pricing-container04 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .pricing-pricing-card {
    margin-bottom: 0px;
  }
  .pricing-pricing-card1 {
    margin-bottom: 0px;
  }
  .pricing-pricing-card2 {
    margin-bottom: 0px;
  }
  .pricing-container18 {
    width: 100%;
    height: auto;
  }
  .pricing-text083 {
    text-align: center;
  }
  .pricing-text086 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pricing-textinput {
    width: 320px;
  }
  .pricing-text091 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pricing-textinput1 {
    width: 320px;
  }
  .pricing-container22 {
    align-self: center;
  }
  .pricing-text096 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pricing-textinput2 {
    width: 320px;
  }
}
