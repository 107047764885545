.error-border {
  outline: 1px solid red;
}

.error-border:focus-visible {
  outline: 2px solid red;
}

.error-text {
  color: red;
  font-size: 13px;
  margin-top: 8px;
}

.candidate-step0-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 59%
  );
}
.candidate-step0-hero {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.candidate-step0-container01 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.candidate-step0-container02 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 32px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.candidate-step0-text01 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: 0px;
}
.candidate-step0-container04 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-step0-container05 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text06 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-textinput {
  color: #ffffff;
  width: 240px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step0-container06 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text11 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-textinput1 {
  color: #ffffff;
  width: 240px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step0-container07 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text16 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-textinput2 {
  color: #ffffff;
  width: 496px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
  padding: 0.5rem 1rem;
}
.candidate-step0-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-step0-container09 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text21 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-textinput3 {
  color: #ffffff;
  width: 240px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step0-container10 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text26 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-textinput4 {
  color: #ffffff;
  width: 240px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step0-container11 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-step0-container12 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text31 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-select {
  color: #fff;
  width: 240px;
  height: var(--dl-size-size-small);
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
  padding: 0.5rem 1rem;
}
.candidate-step0-container13 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step0-text32 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step0-textinput5 {
  color: #ffffff;
  width: 240px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step0-container14 {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.candidate-step0-checkbox {
  align-self: center;
}
.candidate-step0-text37 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
}
.candidate-step0-navlink {
  color: var(--dl-color-primary-300);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: underline;
}
.candidate-step0-navlink1 {
  color: #ffffff;
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.candidate-step0-text38 {
  align-self: center;
  font-family: Space Grotesk;
}
.candidate-step0-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.candidate-step0-text39 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: 0px;
}
.candidate-step0-navlink2 {
  color: var(--dl-color-primary-300);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: 0px;
  text-decoration: none;
}
.candidate-step0-image {
  width: 37rem;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .candidate-step0-hero {
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .candidate-step0-text {
    font-size: 24px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-text06 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-textinput {
    width: 240px;
    height: 46px;
  }
  .candidate-step0-text11 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-textinput1 {
    width: 240px;
    height: 46px;
  }
  .candidate-step0-text16 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-textinput2 {
    width: 496px;
    height: 46px;
  }
  .candidate-step0-text21 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-textinput3 {
    width: 240px;
    height: 46px;
  }
  .candidate-step0-text26 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-textinput4 {
    width: 240px;
    height: 46px;
  }
  .candidate-step0-text31 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-select {
    width: 240px;
  }
  .candidate-step0-text32 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step0-textinput5 {
    width: 240px;
    height: 46px;
  }
  .candidate-step0-navlink1 {
    width: 100%;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .candidate-step0-image {
    width: 30rem;
  }
}
@media (max-width: 991px) {
  .candidate-step0-hero {
    flex-direction: column;
  }
  .candidate-step0-text {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text06 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text11 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text16 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text21 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text26 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text31 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-text32 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step0-navlink1 {
    width: 100%;
    height: var(--dl-size-size-small);
  }
  .candidate-step0-image {
    order: 2;
    display: none;
  }
}
@media (max-width: 767px) {
  .candidate-step0-container04 {
    flex-direction: column;
  }
  .candidate-step0-textinput {
    width: 440px;
  }
  .candidate-step0-textinput1 {
    width: 440px;
  }
  .candidate-step0-textinput2 {
    width: 440px;
  }
  .candidate-step0-container08 {
    flex-direction: column;
  }
  .candidate-step0-textinput3 {
    width: 440px;
  }
  .candidate-step0-textinput4 {
    width: 440px;
  }
  .candidate-step0-container11 {
    flex-direction: column;
  }
  .candidate-step0-select {
    width: 440px;
  }
  .candidate-step0-textinput5 {
    width: 440px;
  }
  .candidate-step0-image {
    width: 80%;
  }
}
@media (max-width: 479px) {
  .candidate-step0-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-text06 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-textinput {
    width: 320px;
  }
  .candidate-step0-text11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-textinput1 {
    width: 320px;
  }
  .candidate-step0-container07 {
    align-self: center;
  }
  .candidate-step0-text16 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-textinput2 {
    width: 320px;
  }
  .candidate-step0-text21 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-textinput3 {
    width: 320px;
  }
  .candidate-step0-text26 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-textinput4 {
    width: 320px;
  }
  .candidate-step0-text31 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-select {
    width: 320px;
  }
  .candidate-step0-text32 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step0-textinput5 {
    width: 320px;
  }
}
