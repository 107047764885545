.authincation-content {
    background: #1F1D26 !important;
    border-radius: 5px !important;
    min-height: 598px !important;
    height: 600px !important;
    box-shadow: none !important;
}
.form-control {
    border-radius: 20px !important;
    background: #35333E !important;
    border: 1px solid #F0F1F5 !important;
    color: #fff !important;
    height: 56px !important;
}

.form-control:hover, .form-control:focus, .form-control.active {
    box-shadow: none;
    background: #35333E;
    color: #fff;
}

.auth-form {
    font-family: "Poppins", "sans-serif";
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
}

.auth-form .form-group label, .auth-form h4 {
    font-weight: 400;
}


.auth-form a.text-white, .auth-form .new-account, .auth-form label.custom-control-label.text-white, .auth-form .form-group label {
    font-size: 16px;
}

.auth-form button.btn.bg-white.text-primary.btn-block {
    background: #6741FF !important;
    color: #fff !important;
    border-radius: 10px !important;
    height: 55px;
    font-family: 'Space Grotesk', 'sans-serif';
    font-weight: 500;
    font-size: 18px;
}