.error-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(21, 14, 49) 3.00%,rgb(15, 15, 15) 59.00%);
}
.error-page-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.error-page-container1 {
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.error-page-text {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-size: 86px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.error-page-text1 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-size: 68px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.error-page-container2 {
  flex: 0 0 auto;
  width: var(--dl-size-size-xlarge);
  height: 6px;
  margin: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.error-page-text2 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.error-page-btn-group {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.error-page-button {
  color: #ffffff;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.error-page-button:hover {
  transform: scale(1.02);
}
.error-page-text6 {
  font-family: Space Grotesk;
}
.error-page-image {
  width: 40%;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .error-page-text {
    color: rgb(255, 255, 255);
  }
  .error-page-text1 {
    color: rgb(255, 255, 255);
    font-size: 74px;
    font-style: normal;
    font-weight: 400;
  }
  .error-page-image {
    width: 50%;
  }
}
@media(max-width: 991px) {
  .error-page-hero {
    flex-direction: column;
  }
  .error-page-container1 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .error-page-text {
    text-align: center;
  }
  .error-page-text1 {
    text-align: center;
  }
  .error-page-text2 {
    text-align: center;
  }
  .error-page-image {
    order: 2;
    width: 80%;
  }
}
@media(max-width: 767px) {
  .error-page-text {
    font-size: 66px;
  }
  .error-page-text1 {
    font-size: 54px;
  }
  .error-page-text2 {
    font-size: 24px;
  }
  .error-page-image {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .error-page-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .error-page-btn-group {
    flex-direction: column;
  }
}
