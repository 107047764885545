.candidate-step1-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 59%
  );
}

.ReactModalPortal .ReactModal__Overlay {
  backdrop-filter: blur(4px);
}

.candidate-step1-container01 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.candidate-step2-container0_7 {
  background-color: #353535;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
}
.candidate-step1-container02 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step1-text {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-container03 {
  flex: 0 0 auto;
  width: 420px;
  height: 6px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step1-container04 {
  flex: 0 0 auto;
  width: 20%;
  height: 6px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-purple);
}
.candidate-step1-container05 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step1-text01 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 26px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-text02 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-container06 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step1-text03 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-select {
  color: #757575;
  width: 616px;
  height: var(--dl-size-size-small);
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step1-container07 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step1-text04 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-select1 {
  color: #757575;
  width: 616px;
  height: var(--dl-size-size-small);
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step1-container08 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step1-text05 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-textinput {
  color: #ffffff;
  width: 616px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step1-container09 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step1-text06 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step1-textinput1 {
  color: #ffffff;
  width: 616px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step1-container10 {
  width: 616px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.candidate-step1-navlink {
  color: rgba(255, 255, 255, 0.5);
  padding: 0px;
  font-size: 16px;
  align-self: center;
  transition: 0.3s;
  font-family: Space Grotesk;
  border-width: 0px;
  border-radius: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.candidate-step1-navlink:hover {
  color: #ffffff;
}
.candidate-step1-text07 {
  transition: 0.3s;
}
.candidate-step1-text07:hover {
  color: #ffffff;
}
.candidate-step1-navlink1 {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.candidate-step1-text10 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .candidate-step1-text {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step1-text01 {
    font-size: 24px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step1-text02 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step1-text03 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step1-text04 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step1-text05 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step1-textinput {
    width: 616px;
    height: 46px;
  }
  .candidate-step1-text06 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step1-textinput1 {
    width: 616px;
    height: 46px;
  }
  .candidate-step1-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .candidate-step1-text {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-text01 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-text02 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-text03 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-text04 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-text05 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-text06 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step1-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .candidate-step1-select {
    width: 440px;
    height: var(--dl-size-size-small);
  }
  .candidate-step1-select1 {
    width: 440px;
  }
  .candidate-step1-textinput {
    width: 440px;
  }
  .candidate-step1-textinput1 {
    width: 440px;
  }
  .candidate-step1-container10 {
    width: 440px;
  }
}
@media (max-width: 479px) {
  .candidate-step1-container01 {
    width: 300px;
    align-items: center;
  }
  .candidate-step1-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-container03 {
    width: 300px;
  }
  .candidate-step1-container04 {
    width: 16%;
  }
  .candidate-step1-text01 {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-text02 {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-text03 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-select {
    width: 300px;
  }
  .candidate-step1-text04 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-select1 {
    width: 300px;
  }
  .candidate-step1-container08 {
    width: 300px;
    align-self: center;
    align-items: center;
  }
  .candidate-step1-text05 {
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-textinput {
    width: 300px;
  }
  .candidate-step1-container09 {
    align-self: center;
  }
  .candidate-step1-text06 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step1-textinput1 {
    width: 300px;
  }
  .candidate-step1-container10 {
    width: 300px;
  }
}
