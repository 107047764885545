.contact-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(12, 8, 23) 6%,
    rgb(21, 21, 21) 54%,
    rgb(9, 3, 25) 100%
  );
}
.contact-us-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-us-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.contact-us-navlink {
  display: contents;
}
.contact-us-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.contact-us-desktop-menu {
  display: flex;
}
.contact-us-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.contact-us-navlink1 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.contact-us-navlink1:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.contact-us-navlink2 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-us-navlink2:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.contact-us-navlink3 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.contact-us-navlink3:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.contact-us-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.contact-us-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.contact-us-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.contact-us-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.contact-us-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.contact-us-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.contact-us-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.contact-us-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-us-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.contact-us-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.contact-us-navlink4 {
  display: contents;
}
.contact-us-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.contact-us-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.contact-us-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-navlink5 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-navlink6 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-navlink7 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-us-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.contact-us-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.contact-us-login2 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.contact-us-login2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.contact-us-login3 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.contact-us-login3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.contact-us-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.contact-us-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-us-image {
  width: 150px;
  object-fit: cover;
}
.contact-us-icon04 {
  width: 28px;
  height: 28px;
}
.contact-us-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-us-text {
  text-decoration: none;
}
.contact-us-text01 {
  text-decoration: none;
}
.contact-us-text02 {
  text-decoration: none;
}
.contact-us-text03 {
  text-decoration: none;
}
.contact-us-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.contact-us-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.contact-us-text04 {
  font-style: normal;
  font-weight: 500;
}
.contact-us-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.contact-us-text05 {
  font-style: normal;
  font-weight: 500;
}
.contact-us-hero {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.contact-us-container03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.contact-us-text06 {
  color: rgb(255, 255, 255);
  font-size: 84px;
  font-family: Space Grotesk;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}
.contact-us-text08 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.contact-us-container04 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contact-us-container05 {
  margin: var(--dl-space-space-halfunit);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(103, 65, 255, 0.5);
}
.contact-us-container05:hover {
  background-color: rgba(103, 65, 255, 1);
}
.contact-us-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.contact-us-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.contact-us-text09 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-us-text11 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.contact-us-text12 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-us-text13 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-us-container07 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contact-us-container08 {
  margin: var(--dl-space-space-halfunit);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(103, 65, 255, 0.5);
}
.contact-us-container08:hover {
  background-color: rgba(103, 65, 255, 1);
}
.contact-us-icon08 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.contact-us-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.contact-us-text14 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-us-text16 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.contact-us-text17 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  margin-top: HalfUnit0;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-us-text18 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-us-icon-group {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.contact-us-link {
  display: contents;
}
.contact-us-container10 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.contact-us-container10:hover {
  background-color: rgba(103, 65, 255, 1);
}
.contact-us-icon10 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.contact-us-link1 {
  display: contents;
}
.contact-us-container11 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.contact-us-container11:hover {
  background-color: rgba(103, 65, 255, 1);
}
.contact-us-image1 {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.contact-us-link2 {
  display: contents;
}
.contact-us-container12 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.contact-us-container12:hover {
  background-color: rgba(103, 65, 255, 1);
}
.contact-us-icon14 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.contact-us-link3 {
  display: contents;
}
.contact-us-container13 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.contact-us-container13:hover {
  background-color: rgba(103, 65, 255, 1);
}
.contact-us-icon16 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.contact-us-image2 {
  width: 40rem;
  object-fit: cover;
}
.contact-us-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.contact-us-container14 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-us-container15 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.contact-us-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.contact-us-text19 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-unit);
  font-size: 56px;
  font-family: Space Grotesk;
}
.contact-us-container17 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-us-container18 {
  display: flex;
  width: 100%;
  margin-top: var(--dl-space-space-halfunit);
  /* margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit); */
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.contact-us-text20 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.contact-us-textinput {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.contact-us-container19 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.contact-us-text25 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.contact-us-textinput1 {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.contact-us-container20 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.contact-us-text30 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.contact-us-textinput2 {
  color: #ffffff;
  width: 716px;
  height: var(--dl-size-size-large);
  align-items: stretch;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.contact-us-button {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #6741ff;
}

.contact-us-text35 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .contact-us-container {
    background-image: radial-gradient(
      circle at center,
      rgb(9, 4, 25) 4%,
      rgb(15, 15, 15) 50%,
      rgb(5, 1, 15) 100%
    );
  }
  .contact-us-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .contact-us-text20 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .contact-us-textinput {
    width: 300px;
    height: 46px;
  }
  .contact-us-text25 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .contact-us-textinput1 {
    width: 300px;
    height: 46px;
  }
  .contact-us-text30 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .contact-us-textinput2 {
    width: 616px;
    height: var(--dl-size-size-large);
  }
  .contact-us-button {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .contact-us-desktop-menu {
    display: none;
  }
  .contact-us-btn-group {
    display: none;
  }
  .contact-us-burger-menu {
    display: flex;
  }
  .contact-us-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .contact-us-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .contact-us-hero {
    flex-direction: column;
  }
  .contact-us-container03 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .contact-us-text06 {
    text-align: center;
  }
  .contact-us-container04 {
    align-self: flex-start;
  }
  .contact-us-container07 {
    align-self: flex-start;
  }
  .contact-us-image2 {
    order: 2;
  }
  .contact-us-text20 {
    text-align: left;
    padding-left: 0px;
  }
  .contact-us-text25 {
    text-align: left;
    padding-left: 0px;
  }
  .contact-us-text30 {
    text-align: left;
    padding-left: 0px;
  }
  .contact-us-textinput2 {
    height: var(--dl-size-size-large);
  }
  .contact-us-button {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .contact-us-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-us-navlink2 {
    margin-left: var(--dl-space-space-unit);
  }
  .contact-us-navlink3 {
    margin-left: var(--dl-space-space-unit);
  }
  .contact-us-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contact-us-image2 {
    width: 80%;
  }
  .contact-us-container17 {
    flex-direction: column;
  }
  .contact-us-container18 {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .contact-us-container19 {
    width: 100%;
  }
  .contact-us-container20 {
    width: 100%;
  }
  .contact-us-textinput {
    width: 100%;
  }
  .contact-us-textinput1 {
    width: 100%;
  }
  .contact-us-container20 {
    align-self: center;
  }
  .contact-us-textinput2 {
    width: 100%;
  }
  .contact-us-button {
    width: 440px;
    align-self: center;
  }
}
@media (max-width: 479px) {
  .contact-us-container {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-us-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .contact-us-mobile-menu {
    padding: 16px;
  }
  .contact-us-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-text06 {
    color: rgb(255, 255, 255);
    font-size: 84px;
    font-style: normal;
    font-weight: 500;
  }
  .contact-us-container17 {
    align-items: center;
  }
  .contact-us-container16 {
    width: 100%;
    height: auto;
  }
  .contact-us-text20 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .contact-us-text25 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .contact-us-container20 {
    align-self: center;
  }
  .contact-us-text30 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .contact-us-button {
    width: 320px;
  }
}
