.candidate-step5-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 59%
  );
}
.candidate-step5-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-step5-container2 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step5-text {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step5-container3 {
  flex: 0 0 auto;
  width: 420px;
  height: 6px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step5-container4 {
  flex: 0 0 auto;
  width: 83%;
  height: 6px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-purple);
}
.candidate-step5-container5 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step5-text01 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 26px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step5-text02 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step5-container6 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step5-text03 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step5-container7 {
  flex: 0 0 auto;
  margin-left: 6px;
  margin-top: 10px;
  width: 500px;
  height: 220px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step5-icon {
  fill: var(--dl-color-gray-white);
  width: 51px;
  height: 47px;
}
.candidate-step5-text04 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-step5-text05 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-step5-container8 {
  width: 616px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.candidate-step5-navlink {
  color: rgba(255, 255, 255, 0.5);
  padding: 0px;
  font-size: 16px;
  align-self: center;
  transition: 0.3s;
  font-family: Space Grotesk;
  border-width: 0px;
  border-radius: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.candidate-step5-navlink:hover {
  color: #ffffff;
}
.candidate-step5-text06 {
  transition: 0.3s;
}
.candidate-step5-text06:hover {
  color: #ffffff;
}
.candidate-step5-navlink1 {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.candidate-step5-text09 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .candidate-step5-text {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step5-text01 {
    font-size: 24px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step5-text02 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step5-text03 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step5-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .candidate-step5-text {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step5-text01 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step5-text02 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step5-text03 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step5-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .candidate-step5-container1 {
    align-items: center;
  }
  .candidate-step5-container6 {
    align-self: center;
  }
  .candidate-step5-container7 {
    width: 316px;
    align-self: center;
  }
  .candidate-step5-container8 {
    width: 440px;
  }
}
@media (max-width: 479px) {
  .candidate-step5-container1 {
    align-items: center;
  }
  .candidate-step5-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step5-container3 {
    width: 300px;
  }
  .candidate-step5-text01 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step5-text02 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step5-container6 {
    align-self: center;
  }
  .candidate-step5-text03 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step5-container8 {
    width: 320px;
  }
}
