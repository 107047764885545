.spinner {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.spinner-inner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 6px;
  border: 3px solid #ccc;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}

.spinner-inner:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner-inner:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner-inner:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
