.about-us-com-ast {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.about-us-com-image {
  width: 40%;
  display: none;
  align-self: flex-start;
  object-fit: cover;
}
.about-us-com-container {
  gap: var(--dl-space-space-fourunits);
  width: 60%;
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}
.about-us-com-text {
  color: rgb(255, 255, 255);
  width: auto;
  padding: var(--dl-space-space-twounits);
  font-size: 64px;
  font-family: Space Grotesk;
  font-weight: 600;
}
.about-us-com-text02 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.about-us-com-text03 {
  color: rgba(255, 255, 255, 0.95);
  width: 85%;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.3;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
  letter-spacing: 1px;
}
.about-us-com-image1 {
  width: 40%;
  align-self: flex-start;
  object-fit: cover;
}
@media(max-width: 991px) {
  .about-us-com-ast {
    align-items: flex-start;
    flex-direction: column;
  }
  .about-us-com-image {
    order: 2;
    display: flex;
  }
  .about-us-com-container {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .about-us-com-text {
    color: rgb(255, 255, 255);
    font-size: 38px;
    text-align: center;
    font-family: Space Grotesk;
    font-weight: 600;
  }
  .about-us-com-text03 {
    align-self: center;
    text-align: left;
    padding-left: 0px;
  }
  .about-us-com-image1 {
    order: 2;
  }
}
@media(max-width: 767px) {
  .about-us-com-ast {
    padding-top: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-us-com-image {
    width: 80%;
  }
  .about-us-com-container {
    gap: var(--dl-space-space-twounits);
  }
  .about-us-com-text {
    padding: var(--dl-space-space-unit);
    font-size: 28px;
  }
  .about-us-com-text03 {
    width: 100%;
    font-size: 14px;
  }
  .about-us-com-image1 {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .about-us-com-container {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-us-com-text03 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
