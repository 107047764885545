.matched-talents-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 61%
  );
}
.matched-talents-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.matched-talents-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.matched-talents-navlink {
  display: contents;
}
.matched-talents-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.matched-talents-desktop-menu {
  display: flex;
}
.matched-talents-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.matched-talents-navlink1 {
  color: rgba(255, 255, 255, 1);
  padding: var(--dl-space-space-halfunit);
  font-size: 15px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.matched-talents-navlink1:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.matched-talents-text {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  background-color: transparent;
}
.matched-talents-text:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.matched-talents-text01 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  background-color: transparent;
}
.matched-talents-text01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.matched-talents-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.matched-talents-quick-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  height: var(--dl-size-size-small);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(41, 41, 41, 0.5);
}
.matched-talents-quick-actions:hover {
  background-color: rgba(41, 41, 41, 1);
}
.matched-talents-text02 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.matched-talents-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.matched-talents-hamburger-menu {
  width: 20px;
  height: 12px;
  display: none;
}
.matched-talents-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.matched-talents-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.matched-talents-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.matched-talents-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.matched-talents-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.matched-talents-navlink2 {
  display: contents;
}
.matched-talents-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.matched-talents-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.matched-talents-icon04 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.matched-talents-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.matched-talents-navlink3 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.matched-talents-navlink4 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.matched-talents-navlink5 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.matched-talents-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.matched-talents-quick-actions1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  height: var(--dl-size-size-small);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(41, 41, 41, 0.5);
}
.matched-talents-quick-actions1:hover {
  background-color: rgba(41, 41, 41, 1);
}
.matched-talents-text03 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.matched-talents-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.matched-talents-hero {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-container03 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.matched-talents-text04 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-size: 42px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.matched-talents-text06 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.matched-talents-text08 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-unit);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 300;
}
.matched-talents-card {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1200px;
  min-height: auto;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-text13 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 400;
}
.matched-talents-text14 {
  font-size: 18px;
}
.matched-talents-text15 {
  color: var(--dl-color-primary-purple);
  font-size: 18px;
}
.matched-talents-text16 {
  font-size: 18px;
}
.matched-talents-text17 {
  color: var(--dl-color-primary-purple);
  font-size: 18px;
}
.matched-talents-text18 {
  font-size: 18px;
}
.matched-talents-text20 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 400;
}
.matched-talents-card1 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1200px;
  min-height: auto;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1f1d26;
}
.matched-talents-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  transition: 0.3s;
}
.matched-talents-icon08:hover {
  fill: var(--dl-color-primary-purple);
}
.matched-talents-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.matched-talents-container04 {
  display: flex;
  align-items: flex-start;
  flex-direction: wrap;
  width: max-content;
  position: relative;
  transition: cubic-bezier(0.84, 0, 0.05, 1.02) 0.2s all;
}

.matched-talents-role-slider {
  max-width: 1050px;
  overflow: hidden;
}

.matched-talents-card1 .hover-pointer:hover {
  cursor: pointer;
}

.matched-talents-button {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text23 {
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button01 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button01:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text24 {
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button02 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button02:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text25 {
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button03 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button03:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text26 {
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button04 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button04:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text27 {
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button05 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button05:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text28 {
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  transition: 0.3s;
}
.matched-talents-icon10:hover {
  fill: var(--dl-color-primary-purple);
}
.matched-talents-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  min-height: auto;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-text29 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.matched-talents-cards {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: start;
}
.matched-talents-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  min-height: 334px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-image {
  width: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.matched-talents-header {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  line-height: 36px;
}
.matched-talents-header01 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 36px;
}
.matched-talents-container05 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-flow: wrap;
  height: 47px;
  overflow: hidden;
}

.matched-talents-card3 {
  z-index: 999 !important;
}

section.matched-talents-card3:hover .matched-talents-container05 {
  height: auto;
}

.matched-talents-card3:after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  margin: 0px;
  background: #1f1d26;
  z-index: -1;
  border-radius: 12px;
}

.matched-talents-card3:hover,
.matched-talents-card3:after {
  height: 100% !important;
  cursor: pointer !important;
}

/* 
*****************************************************
 */

.matched-talents-button06 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button06:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text30 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button07 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button07:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text31 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button08 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button08:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text32 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  min-height: 334px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-image1 {
  width: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.matched-talents-header02 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  line-height: 36px;
}
.matched-talents-header03 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 36px;
}
.matched-talents-container06 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.matched-talents-button09 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button09:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text33 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button10 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button10:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text34 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button11 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button11:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text35 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  min-height: 334px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-content3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-image2 {
  width: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.matched-talents-header04 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  line-height: 36px;
}
.matched-talents-header05 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 36px;
}
.matched-talents-container07 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.matched-talents-button12 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button12:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text36 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button13 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button13:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text37 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button14 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button14:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text38 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-cards1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: start;
}
.matched-talents-card6 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  min-height: 334px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-content4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-image3 {
  width: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.matched-talents-header06 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  line-height: 36px;
}
.matched-talents-header07 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 36px;
}
.matched-talents-container08 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.matched-talents-button15 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button15:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text39 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button16 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button16:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text40 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button17 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button17:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text41 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-card7 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  min-height: 334px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-content5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-image4 {
  width: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.matched-talents-header08 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  line-height: 36px;
}
.matched-talents-header09 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 36px;
}
.matched-talents-container09 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.matched-talents-button18 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button18:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text42 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button19 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button19:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text43 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button20 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button20:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text44 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-card8 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  min-height: 334px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.matched-talents-content6 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.matched-talents-image5 {
  width: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.matched-talents-header10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  line-height: 36px;
}
.matched-talents-header11 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 36px;
}
.matched-talents-container10 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.matched-talents-button21 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button21:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text45 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button22 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button22:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text46 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button23 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button23:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text47 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-container11 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.matched-talents-button24 {
  color: var(--dl-color-gray-700);
  width: 38px;
  height: 53px;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: 12px;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button24:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-icon12 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.matched-talents-button25 {
  color: var(--dl-color-gray-700);
  width: 38px;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button25:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text48 {
  font-size: 18px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button26 {
  color: var(--dl-color-gray-700);
  width: 38px;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button26:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text49 {
  font-size: 18px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button27 {
  color: var(--dl-color-gray-700);
  width: 38px;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button27:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text50 {
  font-size: 18px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button28 {
  color: var(--dl-color-gray-700);
  width: 38px;
  height: auto;
  margin: 4px;
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.matched-talents-button28:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.matched-talents-text51 {
  font-size: 18px;
  align-self: center;
  font-family: Space Grotesk;
}
.matched-talents-button29 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: 12px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  background-color: var(--dl-color-primary-purple);
}
.matched-talents-button29:active {
  color: var(--dl-color-gray-white);
  background-color: rgba(103, 65, 255, 0.75);
}
.matched-talents-text52 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
@media (max-width: 1200px) {
  .matched-talents-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .matched-talents-text04 {
    color: rgb(255, 255, 255);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
  }
  .matched-talents-button {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button01 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button02 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button03 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button04 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button05 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button06 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button07 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button08 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button09 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button10 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button11 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button12 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button13 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button14 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button15 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button16 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button17 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button18 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button19 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button20 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button21 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button22 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button23 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button24 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button25 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button26 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button27 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button28 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .matched-talents-button29 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .matched-talents-desktop-menu {
    display: none;
  }
  .matched-talents-btn-group {
    display: none;
  }
  .matched-talents-hamburger-menu {
    display: flex;
  }
  .matched-talents-burger-menu {
    display: flex;
  }
  .matched-talents-icon02 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .matched-talents-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .matched-talents-hero {
    flex-direction: column;
  }
  .matched-talents-container03 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .matched-talents-text04 {
    text-align: center;
  }
  .matched-talents-text08 {
    text-align: center;
  }
  .matched-talents-card {
    height: auto;
  }
  .matched-talents-text13 {
    text-align: center;
  }
  .matched-talents-text20 {
    text-align: center;
  }
  .matched-talents-card1 {
    height: auto;
  }
  .matched-talents-button {
    width: auto;
    height: auto;
  }
  .matched-talents-button01 {
    width: auto;
    height: auto;
  }
  .matched-talents-button02 {
    width: auto;
    height: auto;
  }
  .matched-talents-button03 {
    width: auto;
    height: auto;
  }
  .matched-talents-button04 {
    width: auto;
    height: auto;
  }
  .matched-talents-button05 {
    width: auto;
    height: auto;
  }
  .matched-talents-card2 {
    height: auto;
  }
  .matched-talents-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .matched-talents-card3 {
    height: auto;
  }
  .matched-talents-button06 {
    width: auto;
    height: auto;
  }
  .matched-talents-button07 {
    width: auto;
    height: auto;
  }
  .matched-talents-button08 {
    width: auto;
    height: auto;
  }
  .matched-talents-card4 {
    height: auto;
  }
  .matched-talents-button09 {
    width: auto;
    height: auto;
  }
  .matched-talents-button10 {
    width: auto;
    height: auto;
  }
  .matched-talents-button11 {
    width: auto;
    height: auto;
  }
  .matched-talents-card5 {
    height: auto;
  }
  .matched-talents-button12 {
    width: auto;
    height: auto;
  }
  .matched-talents-button13 {
    width: auto;
    height: auto;
  }
  .matched-talents-button14 {
    width: auto;
    height: auto;
  }
  .matched-talents-cards1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .matched-talents-card6 {
    height: auto;
  }
  .matched-talents-button15 {
    width: auto;
    height: auto;
  }
  .matched-talents-button16 {
    width: auto;
    height: auto;
  }
  .matched-talents-button17 {
    width: auto;
    height: auto;
  }
  .matched-talents-card7 {
    height: auto;
  }
  .matched-talents-button18 {
    width: auto;
    height: auto;
  }
  .matched-talents-button19 {
    width: auto;
    height: auto;
  }
  .matched-talents-button20 {
    width: auto;
    height: auto;
  }
  .matched-talents-card8 {
    height: auto;
  }
  .matched-talents-button21 {
    width: auto;
    height: auto;
  }
  .matched-talents-button22 {
    width: auto;
    height: auto;
  }
  .matched-talents-button23 {
    width: auto;
    height: auto;
  }
  .matched-talents-button24 {
    width: auto;
    height: auto;
  }
  .matched-talents-button25 {
    width: auto;
    height: auto;
  }
  .matched-talents-button26 {
    width: auto;
    height: auto;
  }
  .matched-talents-button27 {
    width: auto;
    height: auto;
  }
  .matched-talents-button28 {
    width: auto;
    height: auto;
  }
  .matched-talents-button29 {
    width: auto;
    height: auto;
  }
}
@media (max-width: 767px) {
  .matched-talents-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .matched-talents-text {
    margin-left: var(--dl-space-space-unit);
  }
  .matched-talents-text01 {
    margin-left: var(--dl-space-space-unit);
  }
  .matched-talents-text04 {
    font-size: 42px;
  }
  .matched-talents-text08 {
    font-size: 16px;
  }
  .matched-talents-text13 {
    font-size: 16px;
  }
  .matched-talents-text20 {
    font-size: 16px;
  }
  .matched-talents-button {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button01 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button02 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button03 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button04 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button05 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-cards {
    flex-direction: column;
  }
  .matched-talents-card3 {
    gap: 0;
    width: 100%;
  }
  .matched-talents-button06 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button07 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button08 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-card4 {
    gap: 0;
    width: 100%;
  }
  .matched-talents-button09 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button10 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button11 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-card5 {
    gap: 0;
    width: 100%;
  }
  .matched-talents-button12 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button13 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button14 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-cards1 {
    flex-direction: column;
  }
  .matched-talents-card6 {
    gap: 0;
    width: 100%;
  }
  .matched-talents-button15 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button16 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button17 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-card7 {
    gap: 0;
    width: 100%;
  }
  .matched-talents-button18 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button19 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button20 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-card8 {
    gap: 0;
    width: 100%;
  }
  .matched-talents-button21 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button22 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button23 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button24 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button25 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button26 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button27 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button28 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .matched-talents-button29 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
}
@media (max-width: 479px) {
  .matched-talents-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .matched-talents-mobile-menu {
    padding: 16px;
  }
  .matched-talents-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .matched-talents-text23 {
    text-align: center;
  }
  .matched-talents-text24 {
    text-align: center;
  }
  .matched-talents-text25 {
    text-align: center;
  }
  .matched-talents-text26 {
    text-align: center;
  }
  .matched-talents-text27 {
    text-align: center;
  }
  .matched-talents-text28 {
    text-align: center;
  }
  .matched-talents-text30 {
    text-align: center;
  }
  .matched-talents-text31 {
    text-align: center;
  }
  .matched-talents-text32 {
    text-align: center;
  }
  .matched-talents-text33 {
    text-align: center;
  }
  .matched-talents-text34 {
    text-align: center;
  }
  .matched-talents-text35 {
    text-align: center;
  }
  .matched-talents-text36 {
    text-align: center;
  }
  .matched-talents-text37 {
    text-align: center;
  }
  .matched-talents-text38 {
    text-align: center;
  }
  .matched-talents-text39 {
    text-align: center;
  }
  .matched-talents-text40 {
    text-align: center;
  }
  .matched-talents-text41 {
    text-align: center;
  }
  .matched-talents-text42 {
    text-align: center;
  }
  .matched-talents-text43 {
    text-align: center;
  }
  .matched-talents-text44 {
    text-align: center;
  }
  .matched-talents-text45 {
    text-align: center;
  }
  .matched-talents-text46 {
    text-align: center;
  }
  .matched-talents-text47 {
    text-align: center;
  }
  .matched-talents-text48 {
    text-align: center;
  }
  .matched-talents-text49 {
    text-align: center;
  }
  .matched-talents-text50 {
    text-align: center;
  }
  .matched-talents-text51 {
    text-align: center;
  }
  .matched-talents-text52 {
    text-align: center;
  }
}
