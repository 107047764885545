.f-a-qs-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(12, 8, 23) 6.00%,rgb(21, 21, 21) 54.00%,rgb(9, 3, 25) 100.00%);
}
.f-a-qs-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.f-a-qs-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.f-a-qs-navlink {
  display: contents;
}
.f-a-qs-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.f-a-qs-desktop-menu {
  display: flex;
}
.f-a-qs-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.f-a-qs-navlink01 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-navlink01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.f-a-qs-navlink02 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-navlink02:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.f-a-qs-navlink03 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-navlink03:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.f-a-qs-navlink04 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.f-a-qs-navlink04:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.f-a-qs-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.f-a-qs-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.f-a-qs-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.f-a-qs-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.f-a-qs-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.f-a-qs-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.f-a-qs-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.f-a-qs-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.f-a-qs-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.f-a-qs-navlink05 {
  display: contents;
}
.f-a-qs-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.f-a-qs-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.f-a-qs-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.f-a-qs-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.f-a-qs-navlink06 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.f-a-qs-navlink07 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.f-a-qs-navlink08 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.f-a-qs-navlink09 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.f-a-qs-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.f-a-qs-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.f-a-qs-login2 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.f-a-qs-login2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.f-a-qs-login3 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.f-a-qs-login3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.f-a-qs-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.f-a-qs-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.f-a-qs-image {
  width: 150px;
  object-fit: cover;
}
.f-a-qs-icon04 {
  width: 28px;
  height: 28px;
}
.f-a-qs-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text {
  text-decoration: none;
}
.f-a-qs-text01 {
  text-decoration: none;
}
.f-a-qs-text02 {
  text-decoration: none;
}
.f-a-qs-text03 {
  text-decoration: none;
}
.f-a-qs-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.f-a-qs-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.f-a-qs-text04 {
  font-style: normal;
  font-weight: 500;
}
.f-a-qs-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.f-a-qs-text05 {
  font-style: normal;
  font-weight: 500;
}
.f-a-qs-accordion {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-banner {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.f-a-qs-text06 {
  color: rgb(255, 255, 255);
  font-size: 4rem;
  text-align: center;
  font-family: Space Grotesk;
}
.f-a-qs-text07 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.f-a-qs-text09 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.f-a-qs-text12 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.f-a-qs-text14 {
  color: #ffffff;
}
.f-a-qs-text15 {
  color: var(--dl-color-gray-900);
  text-align: center;
  font-family: Space Grotesk;
}
.f-a-qs-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.f-a-qs-element {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text19 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text20 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 24px;
  user-select: text;
}
.f-a-qs-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element1 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text23 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text24 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element2 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text27 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text28 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element3 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text31 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text32 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element4 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text35 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text38 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon14 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element5 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text41 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text42 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon16 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element6 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.f-a-qs-details6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text45 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text46 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon18 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.f-a-qs-element7 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 0px;
}
.f-a-qs-details7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.f-a-qs-text49 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.f-a-qs-text50 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.f-a-qs-icon20 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 1200px) {
  .f-a-qs-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .f-a-qs-text19 {
    font-family: Space Grotesk;
  }
  .f-a-qs-text20 {
    font-family: Space Grotesk;
  }
}
@media(max-width: 991px) {
  .f-a-qs-desktop-menu {
    display: none;
  }
  .f-a-qs-btn-group {
    display: none;
  }
  .f-a-qs-burger-menu {
    display: flex;
  }
  .f-a-qs-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .f-a-qs-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .f-a-qs-banner {
    padding: var(--dl-space-space-twounits);
  }
  .f-a-qs-text06 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-family: Space Grotesk;
  }
}
@media(max-width: 767px) {
  .f-a-qs-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .f-a-qs-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .f-a-qs-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .f-a-qs-navlink04 {
    margin-left: var(--dl-space-space-unit);
  }
  .f-a-qs-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .f-a-qs-text19 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text23 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text27 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text31 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text35 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text41 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text45 {
    font-size: 16px;
    line-height: 24px;
  }
  .f-a-qs-text49 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .f-a-qs-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .f-a-qs-mobile-menu {
    padding: 16px;
  }
  .f-a-qs-text06 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
}
