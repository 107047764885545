.log-in-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(21, 14, 49) 3.00%,rgb(15, 15, 15) 59.00%);
}
.log-in-hero {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.log-in-container1 {
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}
.log-in-text {
  color: var(--dl-color-gray-white);
  font-size: 34px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.log-in-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.log-in-text01 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: 0px;
}
.log-in-navlink {
  color: var(--dl-color-primary-300);
  margin: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  text-decoration: none;
}
.log-in-container3 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.log-in-text02 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.log-in-text04 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.log-in-textinput {
  color: #ffffff;
  width: 440px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.log-in-container4 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.log-in-text05 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.log-in-text07 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.log-in-textinput1 {
  color: #ffffff;
  width: 440px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.log-in-container5 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.log-in-container6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.log-in-checkbox {
  align-self: center;
}
.log-in-text08 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
}
.log-in-navlink1 {
  color: var(--dl-color-primary-300);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: underline;
}
.log-in-btn-group {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.log-in-navlink2 {
  color: #ffffff;
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.log-in-text09 {
  align-self: center;
  font-family: Space Grotesk;
}
.log-in-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.log-in-text10 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: 0px;
}
.log-in-navlink3 {
  color: var(--dl-color-primary-300);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: 0px;
  text-decoration: none;
}
.log-in-image {
  width: 37rem;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .log-in-hero {
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .log-in-text02 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .log-in-textinput {
    width: 440px;
    height: 46px;
  }
  .log-in-text05 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .log-in-textinput1 {
    width: 440px;
    height: 46px;
  }
  .log-in-navlink2 {
    width: 100%;
    height: 40px;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .log-in-image {
    width: 30rem;
  }
}
@media(max-width: 991px) {
  .log-in-hero {
    flex-direction: column;
  }
  .log-in-container1 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .log-in-text02 {
    text-align: left;
    padding-left: 0px;
  }
  .log-in-text05 {
    text-align: left;
    padding-left: 0px;
  }
  .log-in-navlink2 {
    width: 440PX;
    height: 46px;
  }
  .log-in-image {
    order: 2;
    display: none;
  }
}
@media(max-width: 767px) {
  .log-in-image {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .log-in-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .log-in-text02 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .log-in-text05 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .log-in-btn-group {
    margin-bottom: 0px;
    flex-direction: column;
  }
}
