.add-informations-container {
  width: 550px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-componant);
}
.add-informations-container01 {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.add-informations-text {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-family: Space Grotesk;
}
.add-informations-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.add-informations-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 1);
}
.add-informations-container02 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.add-informations-image {
  width: 140px;
  margin: var(--dl-space-space-halfunit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.add-informations-button {
  color: var(--dl-color-primary-300);
  margin: var(--dl-space-space-halfunit);
  padding: 0px;
  border-width: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.add-informations-separator1 {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.5);
}
.add-informations-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.add-informations-container04 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-container05 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-text01 {
  color: var(--dl-color-gray-900);
  margin: 12px;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-textinput {
  color: #ffffff;
  width: 200px;
  height: 36px;
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-container06 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-text02 {
  color: var(--dl-color-gray-900);
  margin: 12px;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-textinput01 {
  color: #ffffff;
  width: 200px;
  height: 36px;
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-container07 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-container08 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-text03 {
  color: var(--dl-color-gray-900);
  margin: 12px;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.add-informations-textarea {
  color: #ffffff;
  width: 100%;
  height: var(--dl-size-size-medium);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-text04 {
  color: var(--dl-color-gray-900);
  margin: 12px;
  font-size: 12px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-separator2 {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.5);
}
.add-informations-container10 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.add-informations-container11 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.add-informations-container12 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container13 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.add-informations-container14 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.add-informations-container15 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-text05 {
  color: var(--dl-color-gray-900);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-select {
  color: #757575;
  width: 160px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  font-size: 14;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-container16 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-text06 {
  color: var(--dl-color-gray-900);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-container17 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-select1 {
  color: #757575;
  width: 100px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  font-size: 14;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-select2 {
  color: #757575;
  width: 100px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  font-size: 14;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-separator3 {
  width: 100%;
  height: 0.25px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.add-informations-container18 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.add-informations-container19 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container20 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.add-informations-container21 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.add-informations-container22 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-text07 {
  color: var(--dl-color-gray-900);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-select3 {
  color: #757575;
  width: 160px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  font-size: 14;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-container23 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-text08 {
  color: var(--dl-color-gray-900);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.add-informations-container24 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-select4 {
  color: #757575;
  width: 100px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  font-size: 14;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-select5 {
  color: #757575;
  width: 100px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  font-size: 14;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-separator4 {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.5);
}
.add-informations-container25 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.add-informations-container26 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-text09 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  text-decoration: underline;
}
.add-informations-container27 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.add-informations-container28 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-textinput02 {
  color: #ffffff;
  width: 200px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-textinput03 {
  color: #ffffff;
  width: 200px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-container29 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-textinput04 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-separator5 {
  width: 100%;
  height: 0.25px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.add-informations-container30 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.add-informations-container31 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-textinput05 {
  color: #ffffff;
  width: 200px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-textinput06 {
  color: #ffffff;
  width: 200px;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-container32 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-textinput07 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.add-informations-button1 {
  color: #ffffff;
  width: auto;
  height: 36px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.add-informations-button1:hover {
  background-color: var(--dl-color-gray-500);
}
.add-informations-icon02 {
  fill: #D9D9D9;
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-halfunit);
}
.add-informations-text10 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.add-informations-separator6 {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.5);
}
.add-informations-container33 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.add-informations-container34 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.add-informations-text11 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  text-decoration: underline;
}
.add-informations-container35 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container36 {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.add-informations-icon04 {
  fill: var(--dl-color-primary-300);
  width: 24px;
  height: 24px;
  margin: var(--dl-space-space-halfunit);
}
.add-informations-textinput08 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-informations-container37 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container38 {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
}
.add-informations-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin: var(--dl-space-space-halfunit);
}
.add-informations-textinput09 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-informations-container39 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container40 {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.add-informations-icon08 {
  fill: #ff3faa;
  width: 24px;
  height: 24px;
  margin: var(--dl-space-space-halfunit);
}
.add-informations-textinput10 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-informations-container41 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container42 {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.add-informations-icon10 {
  fill: var(--dl-color-primary-300);
  width: 24px;
  height: 24px;
  margin: var(--dl-space-space-halfunit);
}
.add-informations-textinput11 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-informations-container43 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.add-informations-container44 {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.add-informations-icon12 {
  fill: var(--dl-color-primary-skybue);
  width: 24px;
  height: 24px;
  margin: var(--dl-space-space-halfunit);
}
.add-informations-textinput12 {
  color: #ffffff;
  width: 100%;
  height: 36px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-informations-container45 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.add-informations-button2 {
  color: #ffffff;
  width: 50%;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-500);
}
.add-informations-button2:hover {
  background-color: var(--dl-color-gray-700);
}
.add-informations-text12 {
  align-self: center;
  font-family: Space Grotesk;
}
.add-informations-button3 {
  color: #ffffff;
  width: 50%;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #6741ff;
}
.add-informations-button3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.add-informations-text13 {
  align-self: center;
  font-family: Space Grotesk;
}
@media(max-width: 1200px) {
  .add-informations-textinput {
    width: 200px;
    height: 36px;
  }
  .add-informations-textinput01 {
    width: 200px;
    height: 36px;
  }
  .add-informations-textinput02 {
    width: 200px;
    height: 36px;
  }
  .add-informations-textinput03 {
    width: 200px;
    height: 36px;
  }
  .add-informations-textinput04 {
    width: 100%;
    height: 36px;
  }
  .add-informations-textinput05 {
    width: 200px;
    height: 36px;
  }
  .add-informations-textinput06 {
    width: 200px;
    height: 36px;
  }
  .add-informations-textinput07 {
    width: 100%;
    height: 36px;
  }
  .add-informations-button1 {
    width: auto;
    height: 36px;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .add-informations-textinput08 {
    width: 100%;
    height: 36px;
  }
  .add-informations-textinput09 {
    width: 100%;
    height: 36px;
  }
  .add-informations-container40 {
    width: 100%;
  }
  .add-informations-textinput10 {
    width: 100%;
    height: 36px;
  }
  .add-informations-container42 {
    width: 100%;
  }
  .add-informations-textinput11 {
    width: 100%;
    height: 36px;
  }
  .add-informations-container44 {
    width: 100%;
  }
  .add-informations-textinput12 {
    width: 100%;
    height: 36px;
  }
  .add-informations-button2 {
    width: 50%;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .add-informations-button3 {
    width: 50%;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .add-informations-button1 {
    width: auto;
    height: 36px;
  }
  .add-informations-container45 {
    width: 100%;
    flex-direction: row;
  }
  .add-informations-button2 {
    width: 50%;
    height: var(--dl-size-size-small);
  }
  .add-informations-button3 {
    width: 50%;
    height: var(--dl-size-size-small);
  }
}
@media(max-width: 767px) {
  .add-informations-container {
    width: 90%;
  }
  .add-informations-container04 {
    flex-direction: column;
  }
  .add-informations-container05 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .add-informations-textinput {
    width: auto;
  }
  .add-informations-container06 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .add-informations-textinput01 {
    width: auto;
  }
  .add-informations-container07 {
    flex-direction: column;
  }
  .add-informations-container08 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .add-informations-container11 {
    flex-direction: column;
  }
  .add-informations-select {
    width: 160px;
  }
  .add-informations-select1 {
    width: 100px;
  }
  .add-informations-select2 {
    width: 100px;
  }
  .add-informations-container18 {
    flex-direction: column;
  }
  .add-informations-select3 {
    width: 160px;
  }
  .add-informations-select4 {
    width: 100px;
  }
  .add-informations-select5 {
    width: 100px;
  }
  .add-informations-container28 {
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
  }
  .add-informations-textinput02 {
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }
  .add-informations-textinput03 {
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }
  .add-informations-container29 {
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
  }
  .add-informations-textinput04 {
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }
  .add-informations-container31 {
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
  }
  .add-informations-textinput05 {
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }
  .add-informations-textinput06 {
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }
  .add-informations-container32 {
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
  }
  .add-informations-textinput07 {
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }
  .add-informations-container36 {
    width: 100%;
  }
  .add-informations-textinput08 {
    width: 100%;
  }
  .add-informations-container38 {
    width: 100%;
  }
  .add-informations-textinput09 {
    width: 100%;
  }
  .add-informations-container40 {
    width: 100%;
  }
  .add-informations-textinput10 {
    width: 100%;
  }
  .add-informations-container42 {
    width: 100%;
  }
  .add-informations-textinput11 {
    width: 100%;
  }
  .add-informations-container44 {
    width: 100%;
  }
  .add-informations-textinput12 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .add-informations-container {
    width: 90%;
  }
  .add-informations-textinput {
    width: 100%;
  }
  .add-informations-textinput01 {
    width: 100%;
  }
  .add-informations-select {
    width: 160px;
  }
  .add-informations-select1 {
    width: 100px;
  }
  .add-informations-select2 {
    width: 100px;
  }
  .add-informations-select3 {
    width: 160px;
  }
  .add-informations-select4 {
    width: 100px;
  }
  .add-informations-select5 {
    width: 100px;
  }
  .add-informations-container26 {
    align-self: flex-start;
    justify-content: space-between;
  }
  .add-informations-textinput02 {
    width: 100%;
  }
  .add-informations-textinput03 {
    width: 100%;
  }
  .add-informations-textinput04 {
    width: 100%;
  }
  .add-informations-textinput05 {
    width: 100%;
  }
  .add-informations-textinput06 {
    width: 100%;
  }
  .add-informations-textinput07 {
    width: 100%;
  }
  .add-informations-container34 {
    align-self: flex-start;
    justify-content: space-between;
  }
  .add-informations-container35 {
    align-self: center;
  }
  .add-informations-textinput08 {
    width: 100%;
  }
  .add-informations-container37 {
    align-self: center;
  }
  .add-informations-textinput09 {
    width: 100%;
  }
  .add-informations-container39 {
    align-self: center;
  }
  .add-informations-textinput10 {
    width: 100%;
  }
  .add-informations-container41 {
    align-self: center;
  }
  .add-informations-textinput11 {
    width: 100%;
  }
  .add-informations-container43 {
    align-self: center;
  }
  .add-informations-textinput12 {
    width: 100%;
  }
}



@media (max-width: 560px) {
  .ReactModal__Content.ReactModal__Content--after-open {
      /* transform: none !important; */
      width: 100% !important;
      height: 120% !important;
      overflow-y: scroll !important;
    }
  }
  
  @media (min-width: 560px) and (max-width: 780px) {
    .ReactModal__Content.ReactModal__Content--after-open {
      width: 100% !important;
      height: 120% !important;
      overflow-y: scroll !important;
    }
  }
  @media (min-width: 1020px) and (max-width: 1440px) {
    .ReactModal__Content.ReactModal__Content--after-open {
      width: 100% !important;
      height: 120% !important;
      overflow: auto;
  }
}
@media (min-width: 1440px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 100% !important;
    height: 120% !important;
    overflow-y: scroll !important;
  }
}
