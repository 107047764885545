.job-description-component-container01 svg:hover,  span.job-description-component-text:hover {
  cursor:pointer;
}



@media (max-width: 560px) {
  .ReactModal__Content.ReactModal__Content--after-open.jobs-page {
    /* transform: none !important; */
    width: 100% !important;
    height: 120% !important;
    overflow-y: scroll !important;
  }
  
}
@media (min-width: 560px) and (max-width: 780px) {
  .ReactModal__Content.ReactModal__Content--after-open.jobs-page {
    width: 100% !important;
    height: 120% !important;
    overflow-y: scroll !important;
  }
}


/* 
@media (min-width: 1020px) and (max-width: 1440px) {
  .ReactModal__Content.ReactModal__Content--after-open.jobs-page {
    width: 100% !important;
    height: 120% !important;
    overflow: auto;
  }
}
@media (min-width: 1440px) {
  .ReactModal__Content.ReactModal__Content--after-open.jobs-page {
    width: 100% !important;
    height: 120% !important;
    overflow-y: scroll !important;
  }
} */


.job-description-component-container {
  width: 80%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-radius: 12px;
  min-height: 100vh;
  z-index: 999999 !important;
  padding-bottom: 100px;
}
.job-description-component-container01 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.job-description-component-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.job-description-component-text {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-separator {
  width: 100%;
  height: 0.5px;
  background-color: var(--dl-color-primary-skybue);
}
.job-description-component-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.job-description-component-icon2 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-primary-skybue);
  right: var(--dl-space-space-twounits);
  width: 24px;
  height: 24px;
  position: absolute;
}
.job-description-component-container03 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.job-description-component-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
}
.job-description-component-container04 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-text01 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-family: Space Grotesk;
}
.job-description-component-container05 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-text02 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text03 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-container06 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.job-description-component-container07 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-container08 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-text04 {
  color: rgba(255, 255, 255, 0.80);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.job-description-component-text07 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-container09 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-text08 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: Space Grotesk;
}
.job-description-component-text11 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-navlink {
  display: contents;
}
.job-description-component-sign-up-btn {
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.job-description-component-sign-up-btn:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.job-description-component-sign-up {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.job-description-component-separator1 {
  width: 100%;
  height: 0.5px;
  background-color: var(--dl-color-primary-skybue);
}
.job-description-component-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.job-description-component-container11 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-text12 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-family: Space Grotesk;
}
.job-description-component-container12 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.job-description-component-text13 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text17 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text20 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text23 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text26 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text29 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-text32 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.job-description-component-separator2 {
  width: 100%;
  height: 0.5px;
  background-color: var(--dl-color-primary-skybue);
}
.job-description-component-container13 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: flex-start;
  align-items: flex-start;
}
.job-description-component-button {
  color: var(--dl-color-primary-300);
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(89, 89, 89, 0.5);
}
.job-description-component-button:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.job-description-component-button1 {
  color: var(--dl-color-primary-300);
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(89, 89, 89, 0.5);
}
.job-description-component-button1:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.job-description-component-button2 {
  color: var(--dl-color-primary-300);
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(89, 89, 89, 0.5);
}
.job-description-component-button2:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
@media(max-width: 1600px) {
  .job-description-component-sign-up-btn {
    align-self: center;
  }
}
@media(max-width: 1200px) {
  .job-description-component-container {
    width: 80%;
  }
}
@media(max-width: 991px) {
  .job-description-component-container {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .job-description-component-container02 {
    flex-direction: column;
    justify-content: flex-start;
  }
  .job-description-component-container03 {
    align-self: flex-start;
    flex-direction: row;
  }
  .job-description-component-image { margin-bottom: 25px; }
  .job-description-component-container06 {
    gap: var(--dl-space-space-twounits);
    justify-content: space-between;
    flex-direction: row;
    align-self: normal;
  }
  .job-description-component-container07 {
    flex-direction: column;
  }
  .job-description-component-sign-up-btn {
    align-self: flex-end;
  }
}
@media(max-width: 479px) {
  .job-description-component-container02 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .job-description-component-icon2 {
    top: var(--dl-space-space-twounits);
    right: var(--dl-space-space-twounits);
    width: 32px;
    height: 32px;
  }
  .job-description-component-container03 {
    align-self: center;
    flex-direction: column;
  }
  .job-description-component-container06 {
    align-self: center;
    flex-direction: column;
  }
  .job-description-component-container07 {
    flex-direction: column;
  }
}
