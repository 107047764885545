.candidate-step4-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 59%
  );
}
.candidate-step4-container01 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-step4-container02 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step4-text {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step4-container03 {
  flex: 0 0 auto;
  width: 420px;
  height: 6px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step4-container04 {
  flex: 0 0 auto;
  width: 66%;
  height: 6px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-purple);
}
.candidate-step4-container05 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step4-text01 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 26px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step4-text02 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step4-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: var(--dl-space-space-oneunit);
}
.candidate-step4-container07 {
  display: flex;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step4-text07 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step4-textinput {
  color: #ffffff;
  width: 300px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step4-container08 {
  display: flex;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step4-text08 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step4-textinput1 {
  color: #ffffff;
  width: 300px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step4-container09 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-bottom: 30px;
  flex-direction: column;
  align-self: center;
}
.candidate-step4-text13 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step4-textinput2 {
  color: #ffffff;
  width: 616px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step4-container10 {
  width: 616px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.candidate-step4-button {
  color: #ffffff;
  width: auto;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: transparent;
}

.candidate-step4-icon {
  fill: #d9d9d9;
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-halfunit);
}
.candidate-step4-text14 {
  align-self: center;
  font-family: Space Grotesk;
}
.candidate-step4-container11 {
  width: 616px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.candidate-step4-navlink {
  color: rgba(255, 255, 255, 0.5);
  padding: 0px;
  font-size: 16px;
  align-self: center;
  transition: 0.3s;
  font-family: Space Grotesk;
  border-width: 0px;
  border-radius: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.candidate-step4-navlink:hover {
  color: #ffffff;
}
.candidate-step4-text15 {
  transition: 0.3s;
}
.candidate-step4-text15:hover {
  color: #ffffff;
}
.candidate-step4-navlink1 {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.candidate-step4-text18 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .candidate-step4-text {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-text01 {
    font-size: 24px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-text02 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-text03 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-text07 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-textinput {
    width: 300px;
    height: 46px;
  }
  .candidate-step4-text08 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-textinput1 {
    width: 300px;
    height: 46px;
  }
  .candidate-step4-text13 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step4-textinput2 {
    width: 616px;
    height: 46px;
  }
  .candidate-step4-button {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .candidate-step4-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}

#step-4-form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .candidate-step4-text {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-text01 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-text02 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-text03 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-text07 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-text08 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-text13 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step4-button {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .candidate-step4-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .candidate-step4-container06 {
    flex-direction: column;
  }
  .candidate-step4-textinput {
    width: 440px;
  }
  .candidate-step4-textinput1 {
    width: 440px;
  }
  .candidate-step4-textinput2 {
    width: 440px;
  }
  .candidate-step4-container10 {
    width: 440px;
  }
  .candidate-step4-container11 {
    width: 440px;
  }
}
@media (max-width: 479px) {
  .candidate-step4-container01 {
    align-items: center;
  }
  .candidate-step4-text {
    color: var(--dl-color-gray-900);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-container03 {
    width: 300px;
  }
  .candidate-step4-text01 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-text02 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-text03 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-text07 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-textinput {
    width: 320px;
  }
  .candidate-step4-text08 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-textinput1 {
    width: 320px;
  }
  .candidate-step4-container09 {
    align-self: center;
  }
  .candidate-step4-text13 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step4-textinput2 {
    width: 320px;
  }
  .candidate-step4-container10 {
    width: 320px;
  }
  .candidate-step4-container11 {
    width: 320px;
  }
}
