.companys-container {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-xlarge);
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
}
.companys-container1 {
  gap: var(--dl-space-space-fourunits);
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.companys-image {
  width: auto;
  height: 58px;
  object-fit: cover;
}
.companys-image1 {
  width: auto;
  height: 64px;
  object-fit: cover;
}
.companys-container2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.companys-image2 {
  width: auto;
  height: 58px;
  object-fit: cover;
}
.companys-image3 {
  width: auto;
  height: 52px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .companys-container {
    height: auto;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .companys-container {
    flex-direction: column;
  }
  .companys-image {
    height: 48px;
  }
  .companys-image1 {
    height: 48px;
  }
  .companys-image2 {
    height: 48px;
  }
  .companys-image3 {
    height: 42px;
  }
}
@media(max-width: 479px) {
  .companys-container1 {
    gap: var(--dl-space-space-twounits);
  }
  .companys-image {
    height: 42px;
  }
  .companys-image1 {
    height: 42px;
  }
  .companys-container2 {
    gap: var(--dl-space-space-twounits);
  }
  .companys-image2 {
    height: 42px;
  }
  .companys-image3 {
    height: 38px;
  }
}
