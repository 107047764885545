.testimonials-tagline {
  padding-top: 0 !important;
  line-height: 1;
}

.stats-stats {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  /* padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit); */
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
}
.stats-testimonial {
  gap: var(--dl-space-space-fourunits);
  width: auto;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.stats-text {
  color: var(--dl-color-primary-skybue);
  font-size: 48px;
  font-style: normal;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 600;
}
.stats-text01 {
  color: var(--dl-color-gray-white);
}
.stats-text03 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.stats-text05 {
  color: var(--dl-color-gray-white);
}
.stats-text08 {
  color: var(--dl-color-gray-white);
}
.stats-text10 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.stats-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: stretch;
  padding-top: 0px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  justify-content: flex-start;
}
.stats-container1 {
  gap: var(--dl-space-space-threeunits);
  flex: 2;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.stats-testimonial-card {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: stretch;
  border-radius: 25px;
  justify-content: center;
  background-color: rgb(32, 30, 45);
}
.stats-testimonial1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.stats-text11 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-twounits);
}
.stats-text12 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-bottom: 0px;
}
.stats-text13 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.stats-image {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xlarge);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
}
.stats-testimonial-card1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: stretch;
  border-radius: 25px;
  justify-content: center;
  background-color: rgb(32, 30, 45);
}
.stats-testimonial2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.stats-text14 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-twounits);
}
.stats-text15 {
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-bottom: 0px;
}
.stats-text16 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.stats-image1 {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xlarge);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
}
.stats-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stats-testimonial-card2 {
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: stretch;
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: stretch;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 25px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  background-color: rgb(32, 30, 45);
}
.stats-text17 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.stats-profile {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.stats-container3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.stats-text23 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-bottom: 0px;
}
.stats-text24 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 400;
}
.stats-image2 {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xlarge);
  align-self: center;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
}
@media (max-width: 1200px) {
  .stats-text12 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .stats-text13 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .stats-text15 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .stats-text16 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .stats-testimonial-card2 {
    border-radius: 25px;
  }
  .stats-profile {
    flex-direction: column;
  }
  .stats-container3 {
    margin: var(--dl-space-space-unit);
  }
  .stats-text23 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .stats-text24 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
}
@media (max-width: 991px) {
  .stats-container {
    flex-direction: column;
  }
  .stats-container1 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .stats-testimonial-card2 {
    flex-direction: column;
  }
  .stats-profile {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
}
@media (max-width: 767px) {
  .stats-text {
    font-size: 42px;
  }
  .stats-container {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .stats-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .stats-testimonial1 {
    margin-right: var(--dl-space-space-twounits);
  }
  .stats-testimonial-card1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .stats-testimonial2 {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media (max-width: 479px) {
  .stats-text {
    font-size: 32px;
    text-align: center;
  }
  .stats-container {
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .stats-testimonial-card {
    align-self: center;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .stats-testimonial1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .stats-text11 {
    text-align: left;
  }
  .stats-text12 {
    align-self: center;
  }
  .stats-text13 {
    align-self: center;
  }
  .stats-testimonial-card1 {
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .stats-testimonial2 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .stats-text14 {
    text-align: left;
  }
  .stats-text15 {
    align-self: center;
  }
  .stats-text16 {
    align-self: center;
  }
  .stats-testimonial-card2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .stats-profile {
    flex-direction: column;
    justify-content: center;
  }
  .stats-container3 {
    margin-left: 0px;
  }
  .stats-text23 {
    align-self: center;
  }
  .stats-text24 {
    align-self: center;
    padding-bottom: var(--dl-space-space-unit);
  }
}
