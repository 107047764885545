.build-your-team-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(12, 8, 23) 6%,
    rgb(21, 21, 21) 54%,
    rgb(9, 3, 25) 100%
  );
}
.white-text {
  color: white;
  font-size: 1.3em;
}
.build-your-team-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.build-your-team-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.build-your-team-navlink {
  display: contents;
}
.build-your-team-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.build-your-team-desktop-menu {
  display: flex;
}
.build-your-team-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.build-your-team-navlink1 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.build-your-team-navlink1:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.build-your-team-navlink2 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.build-your-team-navlink2:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.build-your-team-navlink3 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.build-your-team-navlink3:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.build-your-team-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.build-your-team-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.build-your-team-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.build-your-team-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.build-your-team-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.build-your-team-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.build-your-team-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.build-your-team-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.build-your-team-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.build-your-team-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.build-your-team-navlink4 {
  display: contents;
}
.build-your-team-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.build-your-team-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.build-your-team-icon2 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.build-your-team-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.build-your-team-navlink5 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.build-your-team-navlink6 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.build-your-team-navlink7 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.build-your-team-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.build-your-team-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.build-your-team-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.build-your-team-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.build-your-team-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.build-your-team-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.build-your-team-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.build-your-team-image {
  width: 150px;
  object-fit: cover;
}
.build-your-team-icon4 {
  width: 28px;
  height: 28px;
}
.build-your-team-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.build-your-team-text {
  text-decoration: none;
}
.build-your-team-text01 {
  text-decoration: none;
}
.build-your-team-text02 {
  text-decoration: none;
}
.build-your-team-text03 {
  text-decoration: none;
}
.build-your-team-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.build-your-team-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.build-your-team-text04 {
  font-style: normal;
  font-weight: 500;
}
.build-your-team-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.build-your-team-text05 {
  font-style: normal;
  font-weight: 500;
}
.build-your-team-hero {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.build-your-team-container03 {
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}
.build-your-team-text06 {
  color: var(--dl-color-primary-300);
  font-size: 18px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  letter-spacing: 4px;
}
.build-your-team-text07 {
  color: rgb(255, 255, 255);
  width: 678px;
  font-size: 52px;
  margin-top: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}
.build-your-team-text10 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.build-your-team-text12 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.build-your-team-text13 {
  color: rgba(255, 255, 255, 0.75);
  width: 80%;
  font-size: 14px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
}
.build-your-team-btn-group1 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.build-your-team-navlink8 {
  color: #ffffff;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #6741ff;
}
.build-your-team-navlink8:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.build-your-team-text18 {
  font-size: 18px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.build-your-team-btn-group2 {
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.build-your-team-container04 {
  height: 120px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-direction: row;
  background-color: #1f1d26;
}
.build-your-team-container05 {
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  flex-direction: column;
}
.build-your-team-text19 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-halfunit);
  font-size: 28px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.build-your-team-text21 {
  color: var(--dl-color-primary-purple);
}
.build-your-team-text22 {
  color: var(--dl-color-gray-900);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.build-your-team-container06 {
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  display: flex;
  margin-left: 0px;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  margin-right: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.build-your-team-text23 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-halfunit);
  font-size: 28px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.build-your-team-text25 {
  color: var(--dl-color-primary-purple);
}
.build-your-team-text26 {
  color: var(--dl-color-gray-900);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.build-your-team-container07 {
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  display: flex;
  margin-left: 0px;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  margin-right: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.build-your-team-text27 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-halfunit);
  font-size: 28px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.build-your-team-text28 {
  color: var(--dl-color-primary-purple);
}
.build-your-team-text30 {
  color: var(--dl-color-gray-900);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.build-your-team-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: 0px;
  justify-content: flex-start;
}
.build-your-team-text31 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
}
.build-your-team-container09 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.build-your-team-image01 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.build-your-team-image02 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.build-your-team-image03 {
  width: 36px;
  height: 36px;
  padding: 4px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.build-your-team-image04 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.build-your-team-image05 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.build-your-team-text32 {
  color: var(--dl-color-gray-white);
  font-size: 28px;
  font-family: Space Grotesk;
}
.build-your-team-image06 {
  width: 40rem;
  object-fit: cover;
}
.build-your-team-section {
  gap: 0;
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.build-your-team-header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.build-your-team-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.build-your-team-heading {
  font-size: 32px;
  max-width: auto;
  font-family: Space Grotesk;
}
.build-your-team-text34 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.build-your-team-text36 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.build-your-team-right {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.build-your-team-paragraph {
  width: 100%;
  font-size: 14px;
  max-width: 480px;
  font-family: Space Grotesk;
}
.build-your-team-cards {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.build-your-team-card {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  margin-top: 128px;
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.build-your-team-image07 {
  width: 100px;
  align-self: flex-start;
  object-fit: cover;
}
.build-your-team-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-header1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.build-your-team-description {
  color: rgb(194, 198, 204);
  font-family: Space Grotesk;
  line-height: 28px;
}
.build-your-team-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  margin-top: var(--dl-space-space-fourunits);
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.build-your-team-image08 {
  width: 100px;
  object-fit: cover;
}
.build-your-team-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-header2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.build-your-team-description1 {
  color: rgb(194, 198, 204);
  font-family: Space Grotesk;
  line-height: 28px;
}
.build-your-team-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.build-your-team-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.build-your-team-image09 {
  width: 100px;
  align-self: flex-start;
  object-fit: cover;
}
.build-your-team-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-header3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.build-your-team-description2 {
  color: rgb(194, 198, 204);
  font-family: Space Grotesk;
  line-height: 28px;
}
.build-your-team-cards1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.build-your-team-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  margin-top: 128px;
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.build-your-team-image10 {
  width: 100px;
  align-self: flex-start;
  object-fit: cover;
}
.build-your-team-content3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-header4 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.build-your-team-description3 {
  color: rgb(194, 198, 204);
  font-family: Space Grotesk;
  line-height: 28px;
}
.build-your-team-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  margin-top: var(--dl-space-space-fourunits);
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.build-your-team-image11 {
  width: 100px;
  object-fit: cover;
}
.build-your-team-content4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-header5 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.build-your-team-description4 {
  color: rgb(194, 198, 204);
  font-family: Space Grotesk;
  line-height: 28px;
}
.build-your-team-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.build-your-team-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.build-your-team-image12 {
  width: 100px;
  align-self: flex-start;
  object-fit: cover;
}
.build-your-team-content5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-header6 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.build-your-team-description5 {
  color: rgb(194, 198, 204);
  font-family: Space Grotesk;
  line-height: 28px;
}
.build-your-team-stats {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
}
.build-your-team-text42 {
  color: var(--dl-color-primary-skybue);
  font-size: 52px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.build-your-team-text43 {
  color: var(--dl-color-gray-white);
}
.build-your-team-text44 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.build-your-team-container12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.build-your-team-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 2px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.build-your-team-stat:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
}
.build-your-team-caption {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 56px;
}
.build-your-team-description6 {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 27px;
}
.build-your-team-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 2px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.build-your-team-stat1:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
}
.build-your-team-caption1 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 56px;
}
.build-your-team-description7 {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 27px;
}
.build-your-team-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 2px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.build-your-team-stat2:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
}
.build-your-team-caption2 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 56px;
}
.build-your-team-description8 {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 27px;
}
.build-your-team-text46 {
  font-size: 18px;
  font-family: Space Grotesk;
  line-height: 27px;
}
.build-your-team-container13 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.build-your-team-container14 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.build-your-team-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.build-your-team-text48 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-unit);
  font-size: 56px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.build-your-team-text50 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.build-your-team-container16 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.build-your-team-container17 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.build-your-team-text51 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.build-your-team-textinput {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.build-your-team-container18 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.build-your-team-text56 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.build-your-team-textinput1 {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.build-your-team-container19 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.build-your-team-text61 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.build-your-team-textinput2 {
  color: #ffffff;
  width: 716px;
  height: var(--dl-size-size-large);
  align-items: stretch;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.build-your-team-button {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #6741ff;
}

.build-your-team-text66 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .build-your-team-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .build-your-team-container03 {
    width: 617px;
  }
  .build-your-team-text06 {
    color: var(--dl-color-primary-300);
  }
  .build-your-team-text07 {
    color: rgb(255, 255, 255);
    width: auto;
    font-size: 44px;
    font-weight: 600;
  }
  .build-your-team-container05 {
    width: 150px;
  }
  .build-your-team-text19 {
    font-size: 24px;
  }
  .build-your-team-text22 {
    font-size: 10px;
  }
  .build-your-team-container06 {
    width: 150px;
  }
  .build-your-team-text23 {
    font-size: 24px;
  }
  .build-your-team-text26 {
    font-size: 10px;
  }
  .build-your-team-container07 {
    width: 150px;
  }
  .build-your-team-text27 {
    font-size: 24px;
  }
  .build-your-team-text30 {
    font-size: 10px;
  }
  .build-your-team-image06 {
    width: 35rem;
  }
  .build-your-team-heading {
    font-size: 32px;
  }
  .build-your-team-text51 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .build-your-team-textinput {
    width: 300px;
    height: 46px;
  }
  .build-your-team-text56 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .build-your-team-textinput1 {
    width: 300px;
    height: 46px;
  }
  .build-your-team-text61 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .build-your-team-textinput2 {
    width: 616px;
    height: var(--dl-size-size-large);
  }
  .build-your-team-button {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .build-your-team-desktop-menu {
    display: none;
  }
  .build-your-team-btn-group {
    display: none;
  }
  .build-your-team-burger-menu {
    display: flex;
  }
  .build-your-team-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .build-your-team-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .build-your-team-hero {
    flex-direction: column;
  }
  .build-your-team-container03 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .build-your-team-text06 {
    font-size: 16px;
    text-align: center;
  }
  .build-your-team-text07 {
    text-align: center;
  }
  .build-your-team-text13 {
    text-align: center;
    padding-left: 0px;
  }
  .build-your-team-container08 {
    justify-content: center;
  }
  .build-your-team-image06 {
    order: 2;
  }
  .build-your-team-header {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .build-your-team-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .build-your-team-header1 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 36px;
  }
  .build-your-team-header2 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 36px;
  }
  .build-your-team-card2 {
    height: auto;
  }
  .build-your-team-cards1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .build-your-team-card5 {
    height: auto;
  }
  .build-your-team-header6 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 36px;
  }
  .build-your-team-container12 {
    flex-direction: column;
  }
  .build-your-team-stat {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
    border-right-width: 2px;
  }
  .build-your-team-caption {
    text-align: center;
    line-height: 32px;
  }
  .build-your-team-stat1 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .build-your-team-caption1 {
    text-align: center;
    line-height: 32px;
  }
  .build-your-team-stat2 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
    border-left-width: 2px;
  }
  .build-your-team-caption2 {
    text-align: center;
    line-height: 32px;
  }
  .build-your-team-text51 {
    text-align: left;
    padding-left: 0px;
  }
  .build-your-team-text56 {
    text-align: left;
    padding-left: 0px;
  }
  .build-your-team-text61 {
    text-align: left;
    padding-left: 0px;
  }
  .build-your-team-textinput2 {
    height: var(--dl-size-size-large);
  }
  .build-your-team-button {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .build-your-team-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .build-your-team-navlink2 {
    margin-left: var(--dl-space-space-unit);
  }
  .build-your-team-navlink3 {
    margin-left: var(--dl-space-space-unit);
  }
  .build-your-team-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .build-your-team-text07 {
    font-size: 38px;
  }
  .build-your-team-text13 {
    font-size: 14px;
  }
  .build-your-team-container05 {
    width: 140px;
  }
  .build-your-team-container06 {
    width: 140px;
  }
  .build-your-team-container07 {
    width: 140px;
  }
  .build-your-team-container08 {
    justify-content: center;
  }
  .build-your-team-image06 {
    width: 80%;
  }
  .build-your-team-left {
    gap: var(--dl-space-space-unit);
  }
  .build-your-team-cards {
    flex-direction: column;
  }
  .build-your-team-cards1 {
    flex-direction: column;
  }
  .build-your-team-text42 {
    font-size: 42px;
  }
  .build-your-team-caption {
    font-size: 32px;
  }
  .build-your-team-description6 {
    font-size: 16px;
    line-height: 24px;
  }
  .build-your-team-caption1 {
    font-size: 32px;
  }
  .build-your-team-description7 {
    font-size: 16px;
    line-height: 24px;
  }
  .build-your-team-caption2 {
    font-size: 32px;
  }
  .build-your-team-description8 {
    font-size: 16px;
    line-height: 24px;
  }
  .build-your-team-text45 {
    font-size: 16px;
    line-height: 24px;
  }
  .build-your-team-text47 {
    font-size: 16px;
    line-height: 24px;
  }
  .build-your-team-container16 {
    flex-direction: column;
  }
  .build-your-team-textinput {
    width: 440px;
  }
  .build-your-team-textinput1 {
    width: 440px;
  }
  .build-your-team-textinput2 {
    width: 440px;
  }
}
@media (max-width: 479px) {
  .build-your-team-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .build-your-team-mobile-menu {
    padding: 16px;
  }
  .build-your-team-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .build-your-team-text13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .build-your-team-btn-group1 {
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .build-your-team-btn-group2 {
    margin-top: 0px;
    align-items: flex-start;
    margin-bottom: 0px;
    flex-direction: column;
  }
  .build-your-team-container04 {
    margin-top: var(--dl-space-space-unit);
  }
  .build-your-team-container05 {
    width: 120px;
  }
  .build-your-team-text22 {
    font-size: 9px;
  }
  .build-your-team-container06 {
    width: 120px;
  }
  .build-your-team-text26 {
    font-size: 9px;
  }
  .build-your-team-container07 {
    width: 120px;
  }
  .build-your-team-text30 {
    font-size: 9px;
  }
  .build-your-team-container08 {
    flex-direction: column;
  }
  .build-your-team-container09 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .build-your-team-text42 {
    text-align: center;
  }
  .build-your-team-stat {
    border-right-width: 2px;
  }
  .build-your-team-stat2 {
    border-left-width: 2px;
  }
  .build-your-team-container15 {
    width: 100%;
    height: auto;
  }
  .build-your-team-text51 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .build-your-team-textinput {
    width: 320px;
  }
  .build-your-team-text56 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .build-your-team-textinput1 {
    width: 320px;
  }
  .build-your-team-container19 {
    align-self: center;
  }
  .build-your-team-text61 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .build-your-team-textinput2 {
    width: 320px;
  }
}
