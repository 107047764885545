.disabled {
  cursor: not-allowed !important;
  background-color: #1f1d26 !important;
}

.jobs-board-section {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.jobs-board-header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-board-heading {
  font-size: 52px;
  font-style: normal;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 600;
}
.jobs-board-text01 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.jobs-board-cards {
  gap: var(--dl-space-space-threeunits);
  width: auto;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.jobs-board-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.jobs-board-container01 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 354px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1f1d26;
  cursor: pointer;
}
.jobs-board-image {
  width: 72px;
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-board-icon {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-gray-white);
  right: var(--dl-space-space-unit);
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-board-text02 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-halfunit);
  font-size: 22px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.jobs-board-text03 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-board-button {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button01 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button01:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button02 {
  color: var(--dl-color-primary-300);
  width: auto;
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button02:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  justify-content: flex-end;
}
.jobs-board-navlink {
  display: contents;
}
.jobs-board-sign-up-btn {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-board-sign-up-btn:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-board-sign-up {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container04 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 354px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1f1d26;
}
.jobs-board-image1 {
  width: 72px;
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-board-icon02 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-gray-white);
  right: var(--dl-space-space-unit);
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-board-text04 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-halfunit);
  font-size: 22px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.jobs-board-text05 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-board-button03 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button03:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button04 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button04:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button05 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button05:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  justify-content: flex-end;
}
.jobs-board-navlink1 {
  display: contents;
}
.jobs-board-sign-up-btn1 {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-board-sign-up-btn1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-board-sign-up1 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container07 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.jobs-board-container08 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 354px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1f1d26;
}
.jobs-board-image2 {
  width: 72px;
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-board-icon04 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-gray-white);
  right: var(--dl-space-space-unit);
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-board-text06 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-halfunit);
  font-size: 22px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.jobs-board-text07 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-board-button06 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button06:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button07 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button07:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button08 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button08:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  justify-content: flex-end;
}
.jobs-board-navlink2 {
  display: contents;
}
.jobs-board-sign-up-btn2 {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-board-sign-up-btn2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-board-sign-up2 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container11 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 354px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1f1d26;
}
.jobs-board-image3 {
  width: 72px;
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-board-icon06 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-gray-white);
  right: var(--dl-space-space-unit);
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-board-text08 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-halfunit);
  font-size: 22px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.jobs-board-text09 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-board-button09 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button09:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button10 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button10:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button11 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button11:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  justify-content: flex-end;
}
.jobs-board-navlink3 {
  display: contents;
}
.jobs-board-sign-up-btn3 {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-board-sign-up-btn3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-board-sign-up3 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container14 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.jobs-board-container15 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 354px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1f1d26;
}
.jobs-board-image4 {
  width: 72px;
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-board-icon08 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-gray-white);
  right: var(--dl-space-space-unit);
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-board-text10 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-halfunit);
  font-size: 22px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.jobs-board-text11 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-board-button12 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button12:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button13 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button13:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button14 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button14:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  justify-content: flex-end;
}
.jobs-board-navlink4 {
  display: contents;
}
.jobs-board-sign-up-btn4 {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-board-sign-up-btn4:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-board-sign-up4 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container18 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 354px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1f1d26;
}
.jobs-board-image5 {
  width: 72px;
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-board-icon10 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-gray-white);
  right: var(--dl-space-space-unit);
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-board-text12 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-halfunit);
  font-size: 22px;
  align-self: flex-start;
  font-family: Space Grotesk;
}
.jobs-board-text13 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-board-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-board-button15 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button15:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button16 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button16:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-button17 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-board-button17:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-board-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  justify-content: flex-end;
}
.jobs-board-navlink5 {
  display: contents;
}
.jobs-board-sign-up-btn5 {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-board-sign-up-btn5:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-board-sign-up5 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
@media (max-width: 991px) {
  .jobs-board-header {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .jobs-board-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .jobs-board-container01 {
    max-width: 100%;
  }
  .jobs-board-container04 {
    max-width: 100%;
  }
  .jobs-board-container08 {
    max-width: 100%;
  }
  .jobs-board-container11 {
    max-width: 100%;
  }
  .jobs-board-container15 {
    max-width: 100%;
  }
  .jobs-board-container18 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .jobs-board-heading {
    font-size: 42px;
    align-self: center;
  }
  .jobs-board-cards {
    flex-direction: column;
  }
  .jobs-board-container {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .jobs-board-container07 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .jobs-board-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media (max-width: 479px) {
  .jobs-board-section {
    padding-left: 0;
    padding-right: 0;
  }
}
