.jobs_applied {
  margin-bottom: 0;
}
.card {
  background: #1f1d26 !important;
  color: #fff !important;
  border-radius: 1rem !important;
  position: relative !important;
  box-shadow: none !important;
}

div#main-wrapper {
  font-family: "Space Grotesk", sans-serif;
}

.profile-bx .profile-image img {
  margin: -20px auto !important;
}

.sidebar-profile .profile-image {
  padding-top: 48px;
}

.sidebar-profile > .card .card-body h4 {
  margin-top: 42px;
}

.sidebar-profile .card {
  padding: 0px;
}

.sidebar-profile > .card {
  padding-top: 8px;
}

.card.socials .bg-secondary {
  background: #35333e !important;
  overflow: hidden;
}

.card.socials .card-body > div:hover {
  cursor: pointer;
}

.card.socials .card-body > div {
  border: 0.5px solid #595959;
  border-radius: 8px !important;
}
.card.socials .profile-social-icon {
  padding: 2px;
  border-right: 1px solid #595959;
  margin-right: 10px;
  position: fixed;
}

.card.socials .card-body {
  margin-top: 10px;
  padding: 0px 20px;
}

.user-infos {
  padding: 15px 30px;
}

.card.profile-card {
  padding: 0px;
}

.profile-card .btn {
  border-radius: 12px !important;
  height: 50px;
  line-height: 14px;
}

.profile-card .btn.btn-primary {
  background: #6741ff;
  border-color: #6741ff;
}

.profile-card .btn.btn-dark {
  background: #35333e;
  color: #fff;
}

.card.profile-card input.form-control,
.profile-card textarea,
.profile-card select {
  background: #35333e !important;
  border-color: #c2c2c2 !important;
  color: #fff !important;
  border-radius: 12px !important;
}

.profile-card label,
.profile-card .subtitle {
  font-size: 18px;
  font-weight: 400;
}

.profile-card .skills-list {
  margin-top: 10px;
  border: 1px solid #c2c2c2;
  background: #35333e;
  padding: 6px 11px;
  border-radius: 12px;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-card .skills-list .skill {
  background: #2b2b2b;
  height: 46px;
  margin-right: 10px;
  line-height: 46px;
  padding: 0 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
}

/* 
************************************
* 
*/
.mt-2.dropdown-menu.show {
  background: #35333e;
  border-radius: 12px;
  padding: 0px !important;
  overflow: hidden;
}
.dropdown-menu .dropdown-item:hover {
  background: #35333e !important;
}

.dropdown-menu .dropdown-item.ai-icon {
  padding: 15px 15px !important;
}

div#profile .socials input {
  display: flex;
  background: transparent;
  color: #fff;
  border: none;
  padding: 8px 20px 8px 5px;
  margin-left: -10px;
}

#profile .btn.has-loading .spinner {
  position: relative;
  left: -20px;
  top: -17px;
}
#profile .btn.has-loading span {
  display: inline-flex;
}

#profile .form-control.has-error {
  border-color: red !important;
}
