.footer-footer {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  flex-direction: column;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.footer-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  max-width: 1440px;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-text {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.footer-container01 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-items: center;
}
.footer-textinput {
  color: #ffffff;
  width: 300px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-unit);
  border-color: rgba(255, 255, 255, 0.1);
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.footer-button {
  color: #ffffff;
  width: auto;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #6741ff;
}
.footer-button:hover {
  font-family: Space Grotesk;
  background-color: rgba(103, 65, 255, 0.75);
}
.footer-text01 {
  align-self: center;
  font-family: Space Grotesk;
}
.footer-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.footer-separator {
  width: 100%;
  height: 0.5px;
  max-width: 1440px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.footer-container03 {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-logo {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.footer-image {
  height: 2rem;
}
.footer-text02 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-family: Space Grotesk;
}
.footer-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container04 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.footer-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text06 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink01 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink02 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-product-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text07 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink03 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink04 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink05 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-company-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text08 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink06 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink07 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink08 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-container05 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text09 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text10 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text11 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-link {
  display: contents;
}
.footer-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.footer-container06:hover {
  background-color: rgba(103, 65, 255, 1);
}
.footer-icon {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.footer-link1 {
  display: contents;
}
.footer-container07 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.footer-container07:hover {
  background-color: rgba(103, 65, 255, 1);
}
.footer-image1 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.footer-link2 {
  display: contents;
}
.footer-container08 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.footer-container08:hover {
  background-color: rgba(103, 65, 255, 1);
}
.footer-icon4 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.footer-link3 {
  display: contents;
}
.footer-container09 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(103, 65, 255, 0.5);
}
.footer-container09:hover {
  background-color: rgba(103, 65, 255, 1);
}
.footer-icon6 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.footer-separator1 {
  width: 100%;
  height: 0.5px;
  max-width: 1440px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.footer-container10 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-text12 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-family: Space Grotesk;
}
.footer-navlink09 {
  color: var(--dl-color-primary-300);
  max-width: 1440px;
  align-self: center;
  font-family: Space Grotesk;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer-textinput {
    width: 300px;
    height: var(--dl-size-size-small);
  }
  .footer-button {
    width: auto;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-text {
    font-size: 16px;
  }
  .footer-button {
    width: auto;
    height: var(--dl-size-size-small);
  }
  .footer-container04 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .footer-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .footer-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media (max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-container {
    flex-direction: column;
    align-items: center;
  }
  .footer-container01 {
    flex-direction: column;
  }
  .footer-textinput {
    width: 300px;
    margin: var(--dl-space-space-unit);
  }
  .footer-container03 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-logo {
    align-items: center;
  }
  .footer-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-text02 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .footer-container04 {
    margin-right: var(--dl-space-space-fourunits);
    flex-direction: column;
    align-items: center;
  }
  .footer-product-container {
    align-items: center;
  }
  .footer-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
    align-items: center;
  }
}
@media (max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .footer-textinput {
    width: 320px;
  }
  .footer-container03 {
    align-items: center;
    flex-direction: column;
  }
  .footer-image {
    margin-bottom: 0px;
  }
  .footer-text02 {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-links-container {
    align-items: center;
    flex-direction: column;
  }
  .footer-container04 {
    margin-right: 0px;
  }
  .footer-product-container {
    margin-right: 0;
  }
  .footer-product-container1 {
    margin-right: 0;
  }
  .footer-socials {
    align-items: center;
  }
  .footer-company-container {
    margin-right: 0;
    align-items: center;
  }
  .footer-container {
    height: auto;
  }

  .footer-container05 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    margin-bottom: 0px;
  }
  .footer-contact {
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .footer-text12 {
    text-align: center;
  }
  .footer-navlink09 {
    text-align: center;
  }
}
