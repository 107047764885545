.candidate-step3-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(21, 14, 49) 3%,
    rgb(15, 15, 15) 59%
  );
}
.candidate-step3-container01 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-step3-container02 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step3-text {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-text01 {
  width: auto;
  align-self: center;
  margin-top: 12px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-container03 {
  flex: 0 0 auto;
  width: 420px;
  height: 6px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
}
.candidate-step3-container04 {
  flex: 0 0 auto;
  width: 60%;
  height: 6px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-purple);
}
.candidate-step3-container05 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-step3-text03 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 26px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-text06 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-container-custom {
  width: 480px;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: rgba(15, 15, 15, 0.2);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.dl-top-threeunits {
  margin-top: var(--dl-space-space-threeunits);
}

.candidate-step3-container06 {
  width: 480px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  transition: 0.3s;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-color: rgba(15, 15, 15, 0.2);
}
.candidate-step3-container06:active {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-step3-text11 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 18px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-text12 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 12px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.5;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-container07 {
  width: 480px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-color: rgba(15, 15, 15, 0.2);
}
.candidate-step3-container07:active {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-step3-text13 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 18px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-text14 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 12px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.5;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-container08 {
  width: 480px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-color: rgba(15, 15, 15, 0.2);
}
.candidate-step3-container08:active {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-step3-text15 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 18px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-text16 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 12px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.5;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 12px;
  padding-right: 0px;
}
.candidate-step3-container09 {
  width: 616px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.candidate-step3-navlink {
  color: rgba(255, 255, 255, 0.5);
  padding: 0px;
  font-size: 16px;
  align-self: center;
  transition: 0.3s;
  font-family: Space Grotesk;
  border-width: 0px;
  border-radius: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.candidate-step3-navlink:hover {
  color: #ffffff;
}
.candidate-step3-text17 {
  transition: 0.3s;
}
.candidate-step3-text17:hover {
  color: #ffffff;
}
.candidate-step3-navlink1 {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.candidate-step3-text20 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .candidate-step3-text {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text01 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text03 {
    font-size: 26px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text04 {
    font-size: 26px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text05 {
    font-size: 26px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text06 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text07 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .candidate-step3-text11 {
    font-size: 18px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .candidate-step3-text12 {
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step3-text13 {
    font-size: 18px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .candidate-step3-text14 {
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step3-text15 {
    font-size: 18px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .candidate-step3-text16 {
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .candidate-step3-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .candidate-step3-text {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text01 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text03 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text04 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text05 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text06 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text07 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text11 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text12 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text13 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text14 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text15 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-text16 {
    text-align: left;
    padding-left: 0px;
  }
  .candidate-step3-navlink1 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .candidate-step3-container06 {
    width: 344px;
  }
  .candidate-step3-container07 {
    width: 344px;
  }
  .candidate-step3-container08 {
    width: 344px;
  }
  .candidate-step3-container09 {
    width: 440px;
  }
}
@media (max-width: 479px) {
  .candidate-step3-container01 {
    align-items: center;
  }
  .candidate-step3-text {
    color: var(--dl-color-gray-900);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text01 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-container03 {
    width: 300px;
  }
  .candidate-step3-container04 {
    width: 50%;
  }
  .candidate-step3-text03 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text04 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text05 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text06 {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text07 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-container06 {
    align-self: center;
  }
  .candidate-step3-text11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text12 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-container07 {
    align-self: center;
  }
  .candidate-step3-text13 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text14 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-container08 {
    align-self: center;
  }
  .candidate-step3-text15 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-text16 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .candidate-step3-container09 {
    width: 320px;
  }
}
