.candidate-profile-edit-container20 .resume-preview {
  width: 100%;
}
.add-informations-popup.ReactModal__Content.ReactModal__Content--after-open {
  position: absolute !important;
  /* left: 25px !important; */
}
.candidate-profile-edit-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(21, 14, 49) 3.00%,rgb(15, 15, 15) 61.00%);
}
.candidate-profile-edit-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.candidate-profile-edit-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.candidate-profile-edit-navlink {
  display: contents;
}
.candidate-profile-edit-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.candidate-profile-edit-desktop-menu {
  display: flex;
}
.candidate-profile-edit-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.candidate-profile-edit-navlink1 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.candidate-profile-edit-navlink1:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.candidate-profile-edit-navlink2 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.candidate-profile-edit-navlink2:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.candidate-profile-edit-navlink3 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.candidate-profile-edit-navlink3:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.candidate-profile-edit-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.candidate-profile-edit-quick-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  height: var(--dl-size-size-small);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(41, 41, 41, 0.5);
}
.candidate-profile-edit-quick-actions:hover {
  background-color: rgba(41, 41, 41, 1);
}
.candidate-profile-edit-image {
  width: 32px;
  object-fit: cover;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
}
.candidate-profile-edit-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.candidate-profile-edit-hamburger-menu {
  width: 20px;
  height: 12px;
  display: none;
}
.candidate-profile-edit-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.candidate-profile-edit-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.candidate-profile-edit-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.candidate-profile-edit-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.candidate-profile-edit-navlink4 {
  display: contents;
}
.candidate-profile-edit-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.candidate-profile-edit-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.candidate-profile-edit-icon04 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.candidate-profile-edit-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-navlink5 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.candidate-profile-edit-navlink6 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.candidate-profile-edit-navlink7 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.candidate-profile-edit-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-quick-actions1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  height: var(--dl-size-size-small);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(41, 41, 41, 0.5);
}
.candidate-profile-edit-quick-actions1:hover {
  background-color: rgba(41, 41, 41, 1);
}
.candidate-profile-edit-image1 {
  width: 32px;
  object-fit: cover;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
}
.candidate-profile-edit-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.candidate-profile-edit-container03 {
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 1000px;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.candidate-profile-edit-container04 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.candidate-profile-edit-container05 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.candidate-profile-edit-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-button {
  top: 18px;
  color: var(--dl-color-gray-900);
  right: 18px;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: absolute;
  font-size: 14px;
  align-self: flex-end;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 300;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.candidate-profile-edit-button:hover {
  background-color: rgba(41, 41, 41, 1);
}
.candidate-profile-edit-icon08 {
  fill: var(--dl-color-gray-900);
  width: 18px;
  height: 18px;
  margin-right: 0px;
}
.candidate-profile-edit-container07 {
  width: 256px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.candidate-profile-edit-container08 {
  flex: 0 0 auto;
  width: 140px;
  height: 140px;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/49793a963de7f9427c4713-200h.webp");
}
.candidate-profile-edit-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.candidate-profile-edit-text {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidate-profile-edit-text01 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidate-profile-edit-text02 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidate-profile-edit-text03 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidate-profile-edit-separator {
  width: 336px;
  height: 0.5px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.candidate-profile-edit-container10 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-container11 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-container12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-text04 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.candidate-profile-edit-text05 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-text06 {
  top: 0px;
  color: var(--dl-color-gray-900);
  right: 0px;
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.candidate-profile-edit-text07 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-text08 {
  top: 0px;
  color: var(--dl-color-gray-900);
  right: 0px;
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container15 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-text09 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.candidate-profile-edit-text10 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-text11 {
  top: 0px;
  color: var(--dl-color-gray-900);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-text12 {
  top: 0px;
  color: var(--dl-color-gray-900);
  right: 0px;
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.candidate-profile-edit-text13 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-text14 {
  top: 0px;
  color: var(--dl-color-gray-900);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-text15 {
  top: 0px;
  color: var(--dl-color-gray-900);
  right: 0px;
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-separator1 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.candidate-profile-edit-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-container19 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-text16 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  /* border-color: var(--dl-color-gray-500);
  border-width: 0.5px; */
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: rgba(41, 41, 41, 0.5);
}
.candidate-profile-edit-text17 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-container21 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.candidate-profile-edit-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: auto;
  align-self: flex-start;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-container23 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-container24 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.candidate-profile-edit-text18 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container25 {
  width: auto;
  display: flex;
  max-width: 540px;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-profile-edit-text19 {
  color: rgba(217, 217, 217, 0.5);
  width: auto;
  font-size: 12px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-separator2 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.candidate-profile-edit-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: auto;
  align-self: flex-start;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-container27 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-container28 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.candidate-profile-edit-text20 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container29 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-profile-edit-button1 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.candidate-profile-edit-button1:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-profile-edit-text21 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.candidate-profile-edit-button2 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.candidate-profile-edit-button2:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-profile-edit-text22 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.candidate-profile-edit-button3 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.candidate-profile-edit-button3:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-profile-edit-text23 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.candidate-profile-edit-button4 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.candidate-profile-edit-button4:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.candidate-profile-edit-text24 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.candidate-profile-edit-separator3 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.candidate-profile-edit-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: auto;
  align-self: flex-start;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-container31 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-container32 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.candidate-profile-edit-text25 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-text26 {
  color: var(--dl-color-primary-300);
  font-size: 14px;
  align-self: center;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  border-width: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.candidate-profile-edit-text26:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.candidate-profile-edit-container33 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.candidate-profile-edit-container34 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-profile-edit-container35 {
  flex: 0 0 auto;
  width: 240px;
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(89, 89, 89, 0.25);
}
.candidate-profile-edit-text27 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-container36 {
  flex: 0 0 auto;
  width: 240px;
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(89, 89, 89, 0.25);
}
.candidate-profile-edit-text28 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-container37 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.candidate-profile-edit-container38 {
  width: 512px;
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(89, 89, 89, 0.25);
}
.candidate-profile-edit-text29 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.candidate-profile-edit-separator4 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 0.25);
}
.candidate-profile-edit-container39 {
  flex: 0 0 auto;
  width: 100%;
  height: 350px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: auto;
  align-self: flex-start;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  background-color: #1F1D26;
}
.candidate-profile-edit-container40 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidate-profile-edit-container41 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.candidate-profile-edit-text30 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  font-size: 16px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.candidate-profile-edit-container42 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-profile-edit-container43 {
  width: 328px;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.candidate-profile-edit-icon10 {
  fill: var(--dl-color-primary-300);
  width: 24px;
  height: 24px;
  margin: 6px;
}
.candidate-profile-edit-textinput {
  color: #ffffff;
  width: 320px;
  height: 36px;
  font-size: 12px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: rgba(89, 89, 89, 0.25);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.candidate-profile-edit-container44 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-profile-edit-container45 {
  width: 328px;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.candidate-profile-edit-icon12 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin: 6px;
}
.candidate-profile-edit-textinput1 {
  color: #ffffff;
  width: 320px;
  height: 36px;
  font-size: 12px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: rgba(89, 89, 89, 0.25);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.candidate-profile-edit-container46 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-profile-edit-container47 {
  width: 328px;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.candidate-profile-edit-icon14 {
  fill: #ff3faa;
  width: 24px;
  height: 24px;
  margin: 6px;
}
.candidate-profile-edit-textinput2 {
  color: #ffffff;
  width: 320px;
  height: 36px;
  font-size: 12px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: rgba(89, 89, 89, 0.25);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.candidate-profile-edit-container48 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-profile-edit-container49 {
  width: 328px;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.candidate-profile-edit-icon16 {
  fill: var(--dl-color-primary-300);
  width: 24px;
  height: 24px;
  margin: 6px;
}
.candidate-profile-edit-textinput3 {
  color: #ffffff;
  width: 320px;
  height: 36px;
  font-size: 12px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: rgba(89, 89, 89, 0.25);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.candidate-profile-edit-container50 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.candidate-profile-edit-container51 {
  width: 328px;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-end;
}
.candidate-profile-edit-icon18 {
  fill: var(--dl-color-primary-skybue);
  width: 24px;
  height: 24px;
  margin: 6px;
}
.candidate-profile-edit-textinput4 {
  color: #ffffff;
  width: 320px;
  height: 36px;
  font-size: 12px;
  font-family: Space Grotesk;
  border-color: var(--dl-color-gray-500);
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
  background-color: rgba(89, 89, 89, 0.25);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media(max-width: 1200px) {
  .candidate-profile-edit-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .candidate-profile-edit-button1 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .candidate-profile-edit-button2 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .candidate-profile-edit-button3 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .candidate-profile-edit-button4 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .candidate-profile-edit-container43 {
    width: 328px;
  }
  .candidate-profile-edit-textinput {
    width: 320px;
    height: 36px;
  }
  .candidate-profile-edit-container45 {
    width: 328px;
  }
  .candidate-profile-edit-textinput1 {
    width: 320px;
    height: 36px;
  }
  .candidate-profile-edit-container47 {
    width: 328px;
  }
  .candidate-profile-edit-textinput2 {
    width: 320px;
    height: 36px;
  }
  .candidate-profile-edit-container49 {
    width: 328px;
  }
  .candidate-profile-edit-textinput3 {
    width: 320px;
    height: 36px;
  }
  .candidate-profile-edit-container51 {
    width: 328px;
  }
  .candidate-profile-edit-textinput4 {
    width: 320px;
    height: 36px;
  }

  .add-informations-popup.ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
    /* left: 25px !important; */
  }
}
@media(max-width: 991px) {
  .candidate-profile-edit-desktop-menu {
    display: none;
  }
  .candidate-profile-edit-btn-group {
    display: none;
  }
  .candidate-profile-edit-hamburger-menu {
    display: flex;
  }
  .candidate-profile-edit-burger-menu {
    display: flex;
  }
  .candidate-profile-edit-icon02 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .candidate-profile-edit-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .candidate-profile-edit-container04 {
    flex-direction: column;
  }
  .candidate-profile-edit-container05 {
    width: 100%;
  }
  .candidate-profile-edit-container06 {
    width: 100%;
    height: auto;
    align-self: center;
    align-items: center;
    border-right-width: 1px;
  }
  .candidate-profile-edit-container07 {
    width: auto;
    flex-direction: column;
  }
  .candidate-profile-edit-separator {
    width: 100%;
  }
  .candidate-profile-edit-container10 {
    width: 100%;
    height: auto;
    align-self: center;
    align-items: center;
    border-right-width: 1px;
  }
  .candidate-profile-edit-container11 {
    width: 100%;
    flex-direction: column;
  }
  .candidate-profile-edit-container18 {
    width: 100%;
    height: auto;
    align-self: center;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    border-right-width: 1px;
  }
  .candidate-profile-edit-container20 {
    width: 100%;
  }
  .candidate-profile-edit-container22 {
    /* width: 150px; */
    align-self: center;
    margin-left: 0px;
  }
  .candidate-profile-edit-container26 {
    /* width: 150px; */
    align-self: center;
    margin-left: 0px;
  }
  .candidate-profile-edit-button1 {
    width: auto;
    height: auto;
  }
  .candidate-profile-edit-button2 {
    width: auto;
    height: auto;
  }
  .candidate-profile-edit-button3 {
    width: auto;
    height: auto;
  }
  .candidate-profile-edit-button4 {
    width: auto;
    height: auto;
  }
  .candidate-profile-edit-container30 {
    /* width: 150px; */
    align-self: center;
    margin-left: 0px;
  }
  .candidate-profile-edit-container39 {
    /* width: 150px; */
    align-self: center;
    margin-left: 0px;
  }

  .add-informations-popup.ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
    /* left: 25px !important; */
  }
}
@media(max-width: 768px) {
  .candidate-profile-edit-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .candidate-profile-edit-navlink2 {
    margin-left: var(--dl-space-space-unit);
  }
  .candidate-profile-edit-navlink3 {
    margin-left: var(--dl-space-space-unit);
  }
  .candidate-profile-edit-container04 {
    gap: var(--dl-space-space-halfunit);
  }
  .candidate-profile-edit-container05 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .candidate-profile-edit-container06 {
    margin-bottom: 0px;
  }
  .candidate-profile-edit-container10 {
    margin-bottom: 0px;
  }
  .candidate-profile-edit-container18 {
    margin-bottom: 0px;
  }
  .candidate-profile-edit-container22 {
    width: 100%;
  }
  .candidate-profile-edit-container26 {
    width: 100%;
  }
  .candidate-profile-edit-button1 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .candidate-profile-edit-button2 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .candidate-profile-edit-button3 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .candidate-profile-edit-button4 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .candidate-profile-edit-container30 {
    width: 100%;
  }
  .candidate-profile-edit-container39 {
    width: 100%;
  }
  .candidate-profile-edit-container43 {
    width: auto;
  }
  .candidate-profile-edit-textinput {
    width: 440px;
  }
  .candidate-profile-edit-container45 {
    width: auto;
  }
  .candidate-profile-edit-textinput1 {
    width: 440px;
  }
  .candidate-profile-edit-container47 {
    width: auto;
  }
  .candidate-profile-edit-textinput2 {
    width: 440px;
  }
  .candidate-profile-edit-container49 {
    width: auto;
  }
  .candidate-profile-edit-textinput3 {
    width: 440px;
  }
  .candidate-profile-edit-container51 {
    width: auto;
  }
  .candidate-profile-edit-textinput4 {
    width: 440px;
  }

  .add-informations-popup.ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
    /* left: 25px !important; */
  }
}
@media(max-width: 479px) {
  .candidate-profile-edit-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .candidate-profile-edit-mobile-menu {
    padding: 16px;
  }
  .candidate-profile-edit-container24 {
    align-self: center;
  }
  .candidate-profile-edit-container25 {
    flex-direction: column;
  }
  .candidate-profile-edit-container28 {
    align-self: center;
  }
  .candidate-profile-edit-text21 {
    text-align: center;
  }
  .candidate-profile-edit-text22 {
    text-align: center;
  }
  .candidate-profile-edit-text23 {
    text-align: center;
  }
  .candidate-profile-edit-text24 {
    text-align: center;
  }
  .candidate-profile-edit-container32 {
    align-self: center;
  }
  .candidate-profile-edit-container34 {
    flex-direction: column;
  }
  .candidate-profile-edit-container38 {
    width: 240px;
  }
  .candidate-profile-edit-container41 {
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .candidate-profile-edit-container42 {
    align-self: center;
  }
  .candidate-profile-edit-container43 {
    width: 240px;
  }
  .candidate-profile-edit-textinput {
    width: 320px;
  }
  .candidate-profile-edit-container44 {
    align-self: center;
  }
  .candidate-profile-edit-container45 {
    width: 240px;
  }
  .candidate-profile-edit-textinput1 {
    width: 320px;
  }
  .candidate-profile-edit-container46 {
    align-self: center;
  }
  .candidate-profile-edit-container47 {
    width: 240px;
  }
  .candidate-profile-edit-textinput2 {
    width: 320px;
  }
  .candidate-profile-edit-container48 {
    align-self: center;
  }
  .candidate-profile-edit-container49 {
    width: 240px;
  }
  .candidate-profile-edit-textinput3 {
    width: 320px;
  }
  .candidate-profile-edit-container50 {
    align-self: center;
  }
  .candidate-profile-edit-container51 {
    width: 240px;
  }
  .candidate-profile-edit-textinput4 {
    width: 320px;
  }

  .add-informations-popup.ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
    left: 25px !important;
  }
}
