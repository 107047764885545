:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-300: #0074f0;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-color-primary-gray-2: #353535ff;
  --dl-color-primary-purple: #6741ffff;
  --dl-color-primary-skybue: #cfd9f0ff;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-primary-b-color: #0f0f0fff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-color-success-dark-blue: #15202bff;
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-primary-gray-componant: #292929ff;
}
.button {
  color: var(--dl-color-gray-black);
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem;
  animation: initial;
  transition: unset;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.hidden {
  display: none;
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0%) !important;
}
.section-head {
  color: rgb(128, 255, 68);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.section-heading {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 48px;
}
.section-description {
  color: rgb(204, 204, 204);
  width: 100%;
  max-width: 600px;
  font-family: Poppins;
  line-height: 28px;
}
.link {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.link:hover {
  color: rgba(0, 0, 0, 0.6);
}
.active-quote {
  display: flex !important;
  align-items: center;
  flex-direction: column;
}
.quote {
  display: none;
}
.accordion-element {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  max-width: 600px;
  transition: 0.5s all linear;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  user-select: none;
  border-color: #5a5a5a;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-bottom-width: 1px;
}
.card {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  transition: 0.3s;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: #ffffff;
}
.card:hover {
  /* transform: scale(1.05); */
}
.active-quote1 {
  display: flex !important;
  align-items: center;
  flex-direction: column;
}
.quote1 {
  display: none;
}
.Link {
  cursor: pointer;
  white-space: nowrap;
  margin-right: 40px;
}
.Link:hover {
  color: #5228f5;
}
.nav-link {
  color: #fff;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  transition: 0.3s;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.nav-link:hover {
  opacity: 0.5;
}
.get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: transparent;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 58px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: rgba(42, 42, 42, 1);
}
.get-started:hover {
  opacity: 0.5;
}
.active-quote2 {
  display: flex !important;
  align-items: center;
  flex-direction: column;
}
.quote2 {
  display: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

@media (max-width: 767px) {
  .accordion-element {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .card {
    padding-top: var(--dl-space-space-threeunits);
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .nav-link {
    color: black;
  }
}
@media (max-width: 479px) {
  .accordion-element {
    max-width: auto;
  }
  .card {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
