
.fade.blur.modal.show {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.fade.modal-backdrop.show {
    background: rgba(0, 0, 0, 0.1);
}

.modal-dialog .label{
    font-size: 18px;
    color: #fff;
    font-family: 'Space Grotesk', sans-serif;
    margin-left: -12px;
}


.modal-dialog input.form-control, .modal-dialog textarea.form-control, .modal-dialog select.form-control {
    background: #35333E;
    border-color: #C2C2C2;
    height: 48px !important;
    border-radius: 10px;
    color: #fff;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}
.modal-dialog input.form-control:focus, .modal-dialog textarea.form-control:focus {
    border-color: #6741FF;
}

.modal-dialog textarea[rows="4"] {
    min-height: 135px;
}

.modal-dialog textarea[rows="6"] {
    min-height: 250px;
}


.modal-dialog .modal-header {
    border: none;
}

.modal-dialog .modal-body {
    padding-top: 0px; 
}

.modal-dialog .modal-body {
    padding: 14px;
}
.modal-dialog .modal-footer {
    border: none;
    height: 76px;
    margin-bottom: 15px;
}

.modal-dialog .modal-footer button:nth-child(1){
    width: 45%;
    border-radius: 11px;
    background: #35333E;
    color: #fff;
    border-color: #f1f1f1;
    position: absolute; 
    left: 24px;
    height: 56px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
}


.modal-dialog .modal-footer button:nth-child(2){
    width: 45%;
    border-radius: 11px;
    background: #6741FF;
    color: #fff;
    border-color: #6741FF;
    position: absolute; 
    right: 24px;
    height: 56px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
}

.modal-dialog button.close.btn {
    color: #fff;
}

.modal-content {
    background: #1F1D26;
}


.quill {
    font-family: 'Space Grotesk', sans-serif !important;
    margin-top: 20px;
}

.ql-editor {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 15px;
    color:#fff;
    min-height: 400px;
}

.ql-toolbar.ql-snow {
    background: #fff !important;
    border-radius: 12px 12px 0px 0px;
    border-bottom: none;
}

.ql-container.ql-snow {
    border-radius: 0px 0px 12px 12px;
}