.ourvalues-container {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
}
.ourvalues-header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.ourvalues-heading {
  color: rgb(255, 255, 255);
  font-size: 48px;
  text-align: center;
  font-family: Space Grotesk;
}
.ourvalues-text {
  font-weight: 600;
}
.ourvalues-text01 {
  color: var(--dl-color-primary-purple);
  font-weight: 600;
  text-decoration: underline;
}
.ourvalues-cards {
  gap: 84px;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.ourvalues-card {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 320px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 240px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container02 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container03 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description1 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-container04 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourvalues-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container05 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description2 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header4 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container06 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description3 {
  color: rgb(194, 198, 204);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-text10 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-cards1 {
  gap: var(--dl-space-space-fourunits);
  width: auto;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.ourvalues-container07 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourvalues-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header5 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container08 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description4 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header6 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container09 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description5 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-container10 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ourvalues-card6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content6 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header7 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container11 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description6 {
  color: rgb(194, 198, 204);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-text15 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-card7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 240px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: auto;
  min-width: auto;
  min-height: 342px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1f1d26;
}
.ourvalues-content7 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ourvalues-header8 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.ourvalues-container12 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(
    90deg,
    rgb(103, 65, 255) 2%,
    rgb(56, 160, 237) 51%,
    rgb(0, 255, 231) 100%
  );
}
.ourvalues-description7 {
  color: rgb(194, 198, 204);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.ourvalues-text17 {
  color: #c2c6cc;
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
@media (max-width: 1200px) {
  .ourvalues-card {
    width: 25%;
  }
}
@media (max-width: 991px) {
  .ourvalues-header {
    gap: var(--dl-space-space-unit);
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ourvalues-heading {
    align-self: center;
  }
  .ourvalues-cards {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .ourvalues-container01 {
    width: 100%;
  }
  .ourvalues-card {
    width: 40%;
    min-height: 204px;
  }
  .ourvalues-card1 {
    width: 50%;
    min-height: 254px;
  }
  .ourvalues-container04 {
    width: 100%;
  }
  .ourvalues-card2 {
    width: 50%;
    min-height: 254px;
  }
  .ourvalues-card3 {
    width: 50%;
    min-height: 254px;
  }
  .ourvalues-cards1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .ourvalues-container07 {
    width: 100%;
  }
  .ourvalues-card4 {
    width: 50%;
    min-height: 254px;
  }
  .ourvalues-card5 {
    width: 50%;
    min-height: 254px;
  }
  .ourvalues-container10 {
    width: 100%;
  }
  .ourvalues-card6 {
    width: 50%;
    min-height: 254px;
  }
  .ourvalues-card7 {
    width: 50%;
    min-height: 254px;
  }
}
@media (max-width: 767px) {
  .ourvalues-container {
    gap: var(--dl-space-space-fourunits);
  }
  .ourvalues-header {
    padding-bottom: 0px;
  }
  .ourvalues-heading {
    font-size: 38px;
  }
  .ourvalues-cards {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .ourvalues-card {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-card1 {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-card2 {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-card3 {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-cards1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .ourvalues-card4 {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-card5 {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-card6 {
    width: 100%;
    max-width: 100%;
  }
  .ourvalues-card7 {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 479px) {
  .ourvalues-container {
    gap: var(--dl-space-space-fourunits);
  }
  .ourvalues-heading {
    font-size: 38px;
  }
  .ourvalues-cards {
    gap: var(--dl-space-space-fourunits);
  }
  .ourvalues-container01 {
    flex-direction: column;
  }
  .ourvalues-container04 {
    flex-direction: column;
  }
  .ourvalues-cards1 {
    gap: var(--dl-space-space-fourunits);
  }
  .ourvalues-container07 {
    flex-direction: column;
  }
  .ourvalues-container10 {
    flex-direction: column;
  }
}
