#overlayBlured {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
  z-index: 1;
  backdrop-filter: blur(2px);
  top: 0;
}

.jobs-sign-up-btn.disabled.button {
  background: #1F1D26;
  cursor: not-allowed;
}

.jobs-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(21, 14, 49) 3.00%,rgb(15, 15, 15) 61.00%);
}
.jobs-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.jobs-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.jobs-navlink {
  display: contents;
}
.jobs-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.jobs-desktop-menu {
  display: flex;
}
.jobs-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.jobs-navlink01 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.jobs-navlink01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.jobs-navlink02 {
  color: rgba(255, 255, 255, 1);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.jobs-navlink02:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.jobs-navlink03 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.jobs-navlink03:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.jobs-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.jobs-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.jobs-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.jobs-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.jobs-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.jobs-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.jobs-navlink04 {
  display: contents;
}
.jobs-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.jobs-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.jobs-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-navlink05 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.jobs-navlink06 {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.jobs-navlink07 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.jobs-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.jobs-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.jobs-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.jobs-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  /* padding: var(--dl-space-space-threeunits); */
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  /* flex-direction: column; */
  /* background-color: #ffffff; */
  overflow-y: scroll;
}
.jobs-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-image {
  width: 150px;
  object-fit: cover;
}
.jobs-icon04 {
  width: 28px;
  height: 28px;
}
.jobs-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.jobs-text {
  text-decoration: none;
}
.jobs-text01 {
  text-decoration: none;
}
.jobs-text02 {
  text-decoration: none;
}
.jobs-text03 {
  text-decoration: none;
}
.jobs-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.jobs-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.jobs-text04 {
  font-style: normal;
  font-weight: 500;
}
.jobs-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.jobs-text05 {
  font-style: normal;
  font-weight: 500;
}
.jobs-banner {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-text06 {
  color: rgb(255, 255, 255);
  font-size: 48px;
  text-align: center;
  font-family: Space Grotesk;
}
.jobs-text08 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.jobs-text10 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.jobs-text14 {
  color: var(--dl-color-gray-900);
  font-style: normal;
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-text16 {
  text-decoration: underline;
}
.jobs-text18 {
  text-decoration: underline;
}
.jobs-text20 {
  text-decoration: underline;
}
.jobs-jobs-sectors {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-blockchain {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.jobs-container03 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 284px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.jobs-text22 {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.jobs-text23 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-container05 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}

@media screen and (min-width: 1400px) {
  .jobs-container05 {
    min-width: 850px;
    min-height: 220px;
  }
}

.jobs-container05:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  cursor: pointer;
}
.jobs-container06 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image01 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text24 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon06 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink08 {
  display: contents;
}
.jobs-sign-up-btn {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container08 {
  gap: 4px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button01 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button01:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button02 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button02:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text25 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container09 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container09:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container10 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image02 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text28 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon08 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink09 {
  display: contents;
}
.jobs-sign-up-btn01 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn01:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up01 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button03 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button03:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button04 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button04:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button05 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button05:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text29 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container13 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container13:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container14 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image03 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text32 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon10 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink10 {
  display: contents;
}
.jobs-sign-up-btn02 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn02:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up02 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button06 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button06:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button07 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button07:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button08 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button08:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text33 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-gaming {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.jobs-container17 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 283px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.jobs-text36 {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.jobs-text37 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-container19 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container19:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container20 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image04 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text38 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon12 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink11 {
  display: contents;
}
.jobs-sign-up-btn03 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn03:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up03 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container22 {
  gap: 4px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button09 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button09:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button10 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button10:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button11 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button11:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text39 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container23 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container23:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container24 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container25 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image05 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text42 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon14 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink12 {
  display: contents;
}
.jobs-sign-up-btn04 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn04:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up04 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container26 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button12 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button12:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button13 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button13:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button14 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button14:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text43 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container27 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container27:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container28 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container29 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image06 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text46 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon16 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink13 {
  display: contents;
}
.jobs-sign-up-btn05 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn05:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up05 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container30 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button15 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button15:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button16 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button16:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button17 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button17:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text47 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-aiml {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.jobs-container31 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 284px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.jobs-text50 {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.jobs-text51 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container32 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-container33 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container33:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container34 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container35 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image07 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text52 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon18 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink14 {
  display: contents;
}
.jobs-sign-up-btn06 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn06:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up06 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container36 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button18 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button18:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button19 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button19:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button20 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button20:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text53 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container37 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container37:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container38 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container39 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image08 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text56 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon20 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink15 {
  display: contents;
}
.jobs-sign-up-btn07 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn07:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up07 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container40 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button21 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button21:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button22 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button22:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button23 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button23:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text57 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-design {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.jobs-container41 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 284px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.jobs-text60 {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.jobs-text61 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container42 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-container43 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container43:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container44 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container45 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image09 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text62 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon22 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink16 {
  display: contents;
}
.jobs-sign-up-btn08 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn08:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up08 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container46 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button24 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button24:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button25 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button25:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button26 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button26:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text63 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-c-level {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.jobs-container47 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 284px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.jobs-text66 {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.jobs-text67 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container48 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-container49 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container49:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container50 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container51 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image10 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text68 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon24 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink17 {
  display: contents;
}
.jobs-sign-up-btn09 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn09:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up09 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container52 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button27 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button27:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button28 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button28:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button29 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button29:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text69 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-other-jobs {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.jobs-container53 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 283px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.jobs-text72 {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.jobs-text73 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container54 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-container55 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container55:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container56 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container57 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image11 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text74 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon26 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink18 {
  display: contents;
}
.jobs-sign-up-btn10 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn10:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up10 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container58 {
  gap: 4px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button30 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button30:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button31 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button31:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button32 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  padding-top: 6px;
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button32:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text75 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container59 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container59:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container60 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container61 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image12 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text78 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon28 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink19 {
  display: contents;
}
.jobs-sign-up-btn11 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn11:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up11 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container62 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button33 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button33:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button34 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button34:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button35 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button35:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text79 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container63 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 850px;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(207, 217, 240, 0.5);
  border-width: 0.5px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #1F1D26;
  border-right-width: 1px;
}
.jobs-container63:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  background-color: #1F1D26;
  border-top-width: 1px;
  border-left-width: 12px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.jobs-container64 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-container65 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-image13 {
  width: 72px;
  align-self: flex-start;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.jobs-text82 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.jobs-icon30 {
  top: 0px;
  fill: var(--dl-color-primary-skybue);
  right: 8px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-navlink20 {
  display: contents;
}
.jobs-sign-up-btn12 {
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 12px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.jobs-sign-up-btn12:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.jobs-sign-up12 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-container66 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}
.jobs-button36 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button36:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button37 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button37:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-button38 {
  color: var(--dl-color-primary-300);
  font-size: 12px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 4px;
  padding-top: 6px;
  border-width: 0px;
  margin-right: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background-color: rgba(89, 89, 89, 0.5);
}
.jobs-button38:hover {
  background-color: rgba(89, 89, 89, 0.25);
}
.jobs-text83 {
  color: var(--dl-color-gray-700);
  width: auto;
  margin: var(--dl-space-space-halfunit);
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.jobs-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.jobs-section {
  gap: var(--dl-space-space-sixunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.jobs-header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.jobs-heading {
  font-size: 38px;
  text-align: center;
  font-family: Space Grotesk;
}
.jobs-text88 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.jobs-cards {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jobs-card {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 334px;
  min-height: 300px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.jobs-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-header1 {
  color: var(--dl-color-primary-purple);
  height: auto;
  margin: var(--dl-space-space-unit);
  font-size: 72px;
  font-style: normal;
  text-align: left;
  font-family: Space Grotesk;
  font-weight: 700;
  line-height: 36px;
}
.jobs-container67 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.jobs-header2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.jobs-description {
  color: rgb(194, 198, 204);
  font-family: Poppins;
  line-height: 28px;
}
.jobs-image14 {
  width: 100px;
  object-fit: cover;
}
.jobs-image15 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.jobs-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 334px;
  min-height: 300px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.jobs-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-header3 {
  color: var(--dl-color-primary-purple);
  height: auto;
  margin: var(--dl-space-space-unit);
  font-size: 72px;
  font-style: normal;
  text-align: left;
  font-family: Space Grotesk;
  font-weight: 700;
  line-height: 36px;
}
.jobs-container68 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.jobs-header4 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.jobs-description1 {
  color: #C2C6CC;
  font-family: Poppins;
  line-height: 28px;
}
.jobs-image16 {
  width: 100px;
  object-fit: cover;
}
.jobs-image17 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.jobs-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 334px;
  min-height: 300px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.jobs-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.jobs-header5 {
  color: var(--dl-color-primary-purple);
  height: auto;
  margin: var(--dl-space-space-unit);
  font-size: 72px;
  font-style: normal;
  text-align: left;
  font-family: Space Grotesk;
  font-weight: 700;
  line-height: 36px;
}
.jobs-container69 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.jobs-header6 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.jobs-description2 {
  color: #C2C6CC;
  font-family: Poppins;
  line-height: 28px;
}
@media(max-width: 1600px) {
  .jobs-text06 {
    font-size: 42px;
  }
  .jobs-text22 {
    font-size: 32px;
  }
  .jobs-container06 {
    position: relative;
  }
  .jobs-sign-up-btn {
    align-self: flex-end;
  }
  .jobs-container10 {
    position: relative;
  }
  .jobs-sign-up-btn01 {
    align-self: flex-end;
  }
  .jobs-container14 {
    position: relative;
  }
  .jobs-sign-up-btn02 {
    align-self: flex-end;
  }
  .jobs-text36 {
    font-size: 32px;
  }
  .jobs-container20 {
    position: relative;
  }
  .jobs-sign-up-btn03 {
    align-self: flex-end;
  }
  .jobs-container24 {
    position: relative;
  }
  .jobs-sign-up-btn04 {
    align-self: flex-end;
  }
  .jobs-container28 {
    position: relative;
  }
  .jobs-sign-up-btn05 {
    align-self: flex-end;
  }
  .jobs-text50 {
    font-size: 32px;
  }
  .jobs-container34 {
    position: relative;
  }
  .jobs-sign-up-btn06 {
    align-self: flex-end;
  }
  .jobs-container38 {
    position: relative;
  }
  .jobs-sign-up-btn07 {
    align-self: flex-end;
  }
  .jobs-text60 {
    font-size: 32px;
  }
  .jobs-container44 {
    position: relative;
  }
  .jobs-sign-up-btn08 {
    align-self: flex-end;
  }
  .jobs-text66 {
    font-size: 32px;
  }
  .jobs-container50 {
    position: relative;
  }
  .jobs-sign-up-btn09 {
    align-self: flex-end;
  }
  .jobs-text72 {
    font-size: 32px;
  }
  .jobs-container56 {
    position: relative;
  }
  .jobs-sign-up-btn10 {
    align-self: flex-end;
  }
  .jobs-container60 {
    position: relative;
  }
  .jobs-sign-up-btn11 {
    align-self: flex-end;
  }
  .jobs-container64 {
    position: relative;
  }
  .jobs-sign-up-btn12 {
    align-self: flex-end;
  }
}
@media(max-width: 1200px) {
  .jobs-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .jobs-blockchain {
    gap: var(--dl-space-space-fourunits);
  }
  .jobs-container03 {
    padding-left: 0its;
    padding-right: 0its;
  }
  .jobs-text22 {
    color: var(--dl-color-gray-white);
    font-size: 32px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .jobs-gaming {
    gap: var(--dl-space-space-fourunits);
  }
  .jobs-container17 {
    padding-left: 0its;
    padding-right: 0its;
  }
  .jobs-text36 {
    color: var(--dl-color-gray-white);
    font-size: 32px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .jobs-text37 {
    color: rgba(255, 255, 255, 0.75);
  }
  .jobs-aiml {
    gap: var(--dl-space-space-fourunits);
  }
  .jobs-container31 {
    padding-left: 0its;
    padding-right: 0its;
  }
  .jobs-text50 {
    color: var(--dl-color-gray-white);
    font-size: 32px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .jobs-text51 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-design {
    gap: var(--dl-space-space-fourunits);
  }
  .jobs-container41 {
    padding-left: 0its;
    padding-right: 0its;
  }
  .jobs-text60 {
    color: var(--dl-color-gray-white);
  }
  .jobs-text61 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-c-level {
    gap: var(--dl-space-space-fourunits);
  }
  .jobs-container47 {
    padding-left: 0its;
    padding-right: 0its;
  }
  .jobs-text66 {
    color: var(--dl-color-gray-white);
  }
  .jobs-text67 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-other-jobs {
    gap: var(--dl-space-space-fourunits);
  }
  .jobs-container53 {
    padding-left: 0its;
    padding-right: 0its;
  }
  .jobs-text72 {
    color: var(--dl-color-gray-white);
    font-size: 32px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .jobs-text73 {
    color: rgba(255, 255, 255, 0.75);
  }
}
@media(max-width: 991px) {
  .jobs-desktop-menu {
    display: none;
  }
  .jobs-btn-group {
    display: none;
  }
  .jobs-burger-menu {
    display: flex;
  }
  .jobs-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .jobs-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .jobs-banner {
    padding: var(--dl-space-space-twounits);
  }
  .jobs-text06 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-family: Space Grotesk;
  }
  .jobs-blockchain {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .jobs-container03 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .jobs-text22 {
    font-size: 32px;
  }
  .jobs-text23 {
    font-size: 22px;
  }
  .jobs-container05 {
    max-width: 100%;
  }
  .jobs-text24 {
    font-size: 22px;
  }
  .jobs-container09 {
    max-width: 100%;
  }
  .jobs-text28 {
    font-size: 22px;
  }
  .jobs-container13 {
    max-width: 100%;
  }
  .jobs-text32 {
    font-size: 22px;
  }
  .jobs-gaming {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .jobs-container17 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .jobs-text36 {
    font-size: 32px;
  }
  .jobs-text37 {
    font-size: 22px;
  }
  .jobs-container19 {
    max-width: 100%;
  }
  .jobs-text38 {
    font-size: 22px;
  }
  .jobs-container23 {
    max-width: 100%;
  }
  .jobs-text42 {
    font-size: 22px;
  }
  .jobs-container27 {
    max-width: 100%;
  }
  .jobs-text46 {
    font-size: 22px;
  }
  .jobs-aiml {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .jobs-container31 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .jobs-text50 {
    font-size: 32px;
  }
  .jobs-text51 {
    font-size: 22px;
  }
  .jobs-container33 {
    max-width: 100%;
  }
  .jobs-text52 {
    font-size: 22px;
  }
  .jobs-container37 {
    max-width: 100%;
  }
  .jobs-text56 {
    font-size: 22px;
  }
  .jobs-design {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .jobs-container41 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .jobs-text60 {
    font-size: 32px;
  }
  .jobs-text61 {
    font-size: 22px;
  }
  .jobs-container43 {
    max-width: 100%;
  }
  .jobs-text62 {
    font-size: 22px;
  }
  .jobs-c-level {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .jobs-container47 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .jobs-text66 {
    font-size: 32px;
  }
  .jobs-text67 {
    font-size: 22px;
  }
  .jobs-container49 {
    max-width: 100%;
  }
  .jobs-text68 {
    font-size: 22px;
  }
  .jobs-other-jobs {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .jobs-container53 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .jobs-text72 {
    font-size: 32px;
  }
  .jobs-text73 {
    font-size: 22px;
  }
  .jobs-container55 {
    max-width: 100%;
  }
  .jobs-text74 {
    font-size: 22px;
  }
  .jobs-container59 {
    max-width: 100%;
  }
  .jobs-text78 {
    font-size: 22px;
  }
  .jobs-container63 {
    max-width: 100%;
  }
  .jobs-text82 {
    font-size: 22px;
  }
  .jobs-header {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .jobs-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .jobs-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .jobs-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .jobs-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  /* .jobs-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  } */
  .jobs-text22 {
    font-size: 28px;
  }
  .jobs-text23 {
    font-size: 20px;
  }
  .jobs-text24 {
    font-size: 20px;
  }
  .jobs-text25 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text28 {
    font-size: 20px;
  }
  .jobs-text29 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text32 {
    font-size: 20px;
  }
  .jobs-text33 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text36 {
    font-size: 28px;
  }
  .jobs-text37 {
    font-size: 20px;
  }
  .jobs-text38 {
    font-size: 20px;
  }
  .jobs-text39 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text42 {
    font-size: 20px;
  }
  .jobs-text43 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text46 {
    font-size: 20px;
  }
  .jobs-text47 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text50 {
    font-size: 28px;
  }
  .jobs-text51 {
    font-size: 20px;
  }
  .jobs-text52 {
    font-size: 20px;
  }
  .jobs-text53 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text56 {
    font-size: 20px;
  }
  .jobs-text57 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text60 {
    font-size: 28px;
  }
  .jobs-text61 {
    font-size: 20px;
  }
  .jobs-text62 {
    font-size: 20px;
  }
  .jobs-text63 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text66 {
    font-size: 28px;
  }
  .jobs-text67 {
    font-size: 20px;
  }
  .jobs-text68 {
    font-size: 20px;
  }
  .jobs-text69 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text72 {
    font-size: 28px;
  }
  .jobs-text73 {
    font-size: 20px;
  }
  .jobs-text74 {
    font-size: 20px;
  }
  .jobs-text75 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text78 {
    font-size: 20px;
  }
  .jobs-text79 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-text82 {
    font-size: 20px;
  }
  .jobs-text83 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .jobs-left {
    gap: var(--dl-space-space-unit);
  }
  .jobs-cards {
    flex-direction: column;
  }
  .jobs-image14 {
    display: none;
  }
  .jobs-image15 {
    display: flex;
  }
  .jobs-image16 {
    display: none;
  }
  .jobs-image17 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .jobs-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .jobs-mobile-menu {
    padding: 16px;
  }
  .jobs-text06 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .jobs-container06 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container07 {
    flex-direction: column;
  }
  .jobs-container10 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container11 {
    flex-direction: column;
  }
  .jobs-container14 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container15 {
    flex-direction: column;
  }
  .jobs-container20 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container21 {
    flex-direction: column;
  }
  .jobs-container24 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container25 {
    flex-direction: column;
  }
  .jobs-container28 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container29 {
    flex-direction: column;
  }
  .jobs-container34 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container35 {
    flex-direction: column;
  }
  .jobs-container38 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container39 {
    flex-direction: column;
  }
  .jobs-container44 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container45 {
    flex-direction: column;
  }
  .jobs-container50 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container51 {
    flex-direction: column;
  }
  .jobs-container56 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container57 {
    flex-direction: column;
  }
  .jobs-container60 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container61 {
    flex-direction: column;
  }
  .jobs-container64 {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .jobs-container65 {
    flex-direction: column;
  }
}
