.swiper-slide {
  padding: 30px;
}

@media screen and (min-width: 1440px) {
  .ReactModal__Content.ReactModal__Content--after-open.job-description-popup-home {
    position: absolute !important;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .home-hero {
    padding-left: var(--dl-space-space-twounits) !important;
    padding-right: var(--dl-space-space-twounits) !important;
  }

  .job-decription-popup-home .job-description-component-container {
    width: 75% !important;
  }

  .ReactModal__Content.ReactModal__Content--after-open.job-description-popup-home {
    position: absolute !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .ReactModal__Content.ReactModal__Content--after-open.job-description-popup-home {
    position: absolute !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ReactModal__Content.ReactModal__Content--after-open.job-description-popup-home {
    position: static !important;
  }
}

.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(
    circle at right,
    rgb(12, 8, 23) 6%,
    rgb(21, 21, 21) 54%,
    rgb(9, 3, 25) 100%
  );
  min-width: 100vw;
  overflow: hidden;
}
.home-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.home-navlink {
  display: contents;
}
.home-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.home-desktop-menu {
  display: flex;
}
.home-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-navlink01 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-navlink01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.home-navlink02 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.home-navlink02:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.home-navlink03 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.home-navlink03:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.home-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.home-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.home-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  padding-left: 32px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-navlink04 {
  display: contents;
}
.home-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink05 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink06 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink07 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.home-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.home-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.home-login2 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.home-login2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-login3 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.home-login3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 150px;
  object-fit: cover;
}
.home-icon04 {
  width: 28px;
  height: 28px;
}
.home-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  text-decoration: none;
}
.home-text001 {
  text-decoration: none;
}
.home-text002 {
  text-decoration: none;
}
.home-text003 {
  text-decoration: none;
}
.home-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.home-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.home-text004 {
  font-style: normal;
  font-weight: 500;
}
.home-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ade2df;
}
.home-text005 {
  font-style: normal;
  font-weight: 500;
}
.home-hero {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-container03 {
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}
.home-text006 {
  font-size: 60px;
  margin-top: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text007 {
  color: var(--dl-color-gray-white);
}
.home-text008 {
  color: var(--dl-color-gray-white);
}
.home-text009 {
  color: var(--dl-color-gray-white);
}
.home-text011 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-text012 {
  color: var(--dl-color-gray-white);
}
.home-text013 {
  color: var(--dl-color-gray-white);
}
.home-text014 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-text018 {
  color: rgba(255, 255, 255, 0.75);
  width: 80%;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
}
.home-btn-group1 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-navlink08 {
  color: #ffffff;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #6741ff;
}
.home-navlink08:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-text024 {
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-navlink09 {
  display: contents;
}
.home-sign-up-btn {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  padding-top: 15px;
  border-color: #6741ff;
  border-width: 2px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: 15px;
  justify-content: center;
  text-decoration: none;
}
.home-sign-up-btn:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-sign-up {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-btn-group2 {
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-container04 {
  height: 120px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-direction: row;
  background-color: rgba(31, 29, 38, 0.75);
}
.home-container05 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  flex-direction: column;
}
.home-text025 {
  color: rgb(255, 255, 255);
  font-size: 28px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-text026 {
  color: var(--dl-color-primary-purple);
}
.home-text027 {
  color: var(--dl-color-gray-white);
}
.home-text028 {
  color: var(--dl-color-gray-900);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-container06 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  display: flex;
  margin-left: 0px;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  margin-right: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text029 {
  color: rgb(255, 255, 255);
  font-size: 28px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-text030 {
  color: var(--dl-color-primary-purple);
}
.home-text031 {
  color: var(--dl-color-gray-white);
}
.home-text032 {
  color: var(--dl-color-gray-900);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-container07 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  display: flex;
  margin-left: 0px;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  margin-right: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text033 {
  color: rgb(255, 255, 255);
  font-size: 28px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-text034 {
  color: var(--dl-color-primary-purple);
}
.home-text036 {
  color: var(--dl-color-gray-900);
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: 0px;
  justify-content: flex-start;
}
.home-text037 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
}
.home-container09 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-image01 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.home-image02 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.home-image03 {
  width: 36px;
  height: 36px;
  padding: 4px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.home-image04 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.home-image05 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.home-text038 {
  color: var(--dl-color-gray-white);
  font-size: 28px;
  font-family: Space Grotesk;
}
.home-image-1 {
  width: 40rem;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-about-us {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container10 {
  display: none;
  max-width: 600px;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.home-text039 {
  color: var(--dl-color-gray-white);
  font-size: 42px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text041 {
  text-decoration: underline;
}
.home-container11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-text043 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.4;
}
.home-container12 {
  display: flex;
  flex-direction: column;
}
.home-container13 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon06 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text046 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container14 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon08 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text047 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container15 {
  display: flex;
  flex-direction: column;
}
.home-container16 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon10 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text048 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container17 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon12 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text049 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container18 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon14 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text050 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-sign-up-btn1 {
  width: var(--dl-size-size-xlarge);
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.home-sign-up-btn1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-sign-up1 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-image06 {
  flex: 1;
  width: 37rem;
  height: auto;
  object-fit: cover;
}
.home-container19 {
  display: flex;
  max-width: 600px;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.home-text051 {
  color: var(--dl-color-gray-white);
  font-size: 42px;
  font-family: Space Grotesk;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text053 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-container20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-text056 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.4;
}
.home-container21 {
  display: flex;
  flex-direction: column;
}
.home-container22 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon16 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text059 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container23 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon18 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text060 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container24 {
  display: flex;
  flex-direction: column;
}
.home-container25 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon20 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text061 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container26 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon22 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text062 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container27 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon24 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text063 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-navlink11 {
  display: contents;
}
.home-sign-up-btn2 {
  width: 240px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.home-sign-up-btn2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-sign-up2 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.home-about-us1 {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container28 {
  display: flex;
  max-width: 600px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.home-text064 {
  color: var(--dl-color-gray-white);
  font-size: 42px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text066 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-container29 {
  gap: var(--dl-space-space-unit);
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-text068 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  line-height: 1.4;
}
.home-container30 {
  display: flex;
  flex-direction: column;
}
.home-container31 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon26 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text071 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container32 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon28 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text072 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container33 {
  display: flex;
  flex-direction: column;
}
.home-container34 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon30 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text073 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container35 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon32 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text074 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-container36 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-icon34 {
  fill: var(--dl-color-primary-purple);
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 4px;
}
.home-text075 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.home-navlink12 {
  display: contents;
}
.home-sign-up-btn3 {
  width: var(--dl-size-size-large);
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.home-sign-up-btn3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.home-sign-up3 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.home-image07 {
  flex: 1;
  width: 37rem;
  height: auto;
  object-fit: cover;
  margin-left: var(--dl-space-space-sixunits);
  margin-bottom: 40px;
}
.home-stats {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  z-index: 0;
}
.home-text076 {
  color: var(--dl-color-primary-skybue);
  font-size: 52px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.home-text077 {
  color: var(--dl-color-gray-white);
}
.home-text078 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-container37 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 2px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.home-stat:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
}
.home-caption {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 56px;
}
.home-description {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 27px;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 2px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.home-stat1:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
}
.home-caption1 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 56px;
}
.home-description1 {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 27px;
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-primary-skybue);
  border-width: 2px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.home-stat2:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 3px;
}
.home-caption2 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 56px;
}
.home-description2 {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 27px;
}
.home-text080 {
  font-size: 18px;
  font-family: Space Grotesk;
  line-height: 27px;
}
.home-container38 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text082 {
  color: var(--dl-color-gray-white);
  font-size: 48px;
  font-style: normal;
  margin-top: var(--dl-space-space-sixunits);
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: 0px;
}
.home-text084 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-text088 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-testimonials {
  width: auto;
  position: relative;
  max-width: auto;
  align-items: flex-start;
  padding-top: 66px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-sixunits);
  z-index: 0;
}
.home-container39 {
  width: 717px;
  display: flex;
  align-items: stretch;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.home-logo-container {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: #ffffff;
}
.home-logo {
  width: auto;
  height: 32px;
}
.home-text089 {
  color: rgba(255, 255, 255, 0.75);
  align-self: center;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 1.2;
}
.home-content {
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
}
.home-person {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text094 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.home-text095 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-family: Space Grotesk;
}
.home-quote01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-quote02 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.home-quote03 {
  font-size: 32px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 48px;
}
.home-people {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-person1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar1 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text096 {
  font-style: normal;
  font-weight: 600;
}
.home-quote05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-quote06 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.home-quote07 {
  font-size: 32px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 48px;
}
.home-people1 {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-person2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar2 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text098 {
  font-style: normal;
  font-weight: 600;
}
.home-container40 {
  width: 717px;
  display: flex;
  align-items: stretch;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  align-items: center;
}
.home-logo-container1 {
  align-self: center;
  display: flex;
  width: 49px;
  margin-left: auto;
  margin-right: auto;
}
.home-logo-container1 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: #ffffff;
}
.home-logo1 {
  width: auto;
  height: 32px;
}
.home-text100 {
  color: rgba(255, 255, 255, 0.75);
  align-self: center;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 1.2;
}
.home-content1 {
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
}
.home-person3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.home-avatar3 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text105 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.home-text106 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-family: Space Grotesk;
}
.home-quote09 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-quote10 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.home-quote11 {
  font-size: 32px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 48px;
}
.home-people2 {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-person4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar4 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text107 {
  font-style: normal;
  font-weight: 600;
}
.home-quote13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-quote14 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.home-quote15 {
  font-size: 32px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 48px;
}
.home-people3 {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-person5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar5 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text109 {
  font-style: normal;
  font-weight: 600;
}
.home-buttons1 {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 500px;
  align-self: center;
  margin-top: -150px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
}
.home-left {
  fill: #dbdbdb;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  width: 53px;
}
.home-left:hover {
  fill: #000;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
}
.home-icon36 {
  width: 19px;
  height: 19px;
}
.home-right {
  fill: #dbdbdb;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  width: 53px;
}
.home-right:hover {
  fill: #000;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
}
.home-icon38 {
  width: 19px;
  height: 19px;
}
.home-container41 {
  width: 717px;
  display: flex;
  align-items: stretch;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.home-logo-container2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: #ffffff;
}
.home-logo2 {
  width: auto;
  height: 32px;
}
.home-text111 {
  color: rgba(255, 255, 255, 0.75);
  align-self: center;
  text-align: center;
  font-family: Space Grotesk;
  line-height: 1.2;
}
.home-text113 {
  color: rgba(255, 255, 255, 0.75);
  font-family: Space Grotesk;
}
.home-content2 {
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
}
.home-person6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.home-avatar6 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details6 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text116 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.home-text117 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-family: Space Grotesk;
}
.home-quote17 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-quote18 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.home-quote19 {
  font-size: 32px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 48px;
}
.home-people4 {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-person7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar7 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details7 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text118 {
  font-style: normal;
  font-weight: 600;
}
.home-quote21 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-quote22 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.home-quote23 {
  font-size: 32px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 48px;
}
.home-people5 {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-person8 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar8 {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
}
.home-person-details8 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text120 {
  font-style: normal;
  font-weight: 600;
}
.home-separator1 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.home-separator2 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.home-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
  justify-content: center;
}
.home-container42 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-container43 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.home-text122 {
  color: var(--dl-color-gray-white);
  font-size: 52px;
  font-family: Space Grotesk;
  font-weight: 600;
}
.home-text124 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-container44 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container45 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1f1d26;
}
.home-link {
  display: contents;
}
.home-blog-post-card {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-blog-post-card:hover {
  transform: scale(1.02);
}
.home-image08 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.home-container46 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text125 {
  color: rgb(88, 146, 255);
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text126 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text134 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container47 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text135 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.home-container48 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1f1d26;
}
.home-link1 {
  display: contents;
}
.home-blog-post-card1 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-blog-post-card1:hover {
  transform: scale(1.02);
}
.home-image09 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.home-container49 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text136 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text137 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text138 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container50 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text141 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.home-container51 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1f1d26;
}
.home-link2 {
  display: contents;
}
.home-blog-post-card2 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-blog-post-card2:hover {
  transform: scale(1.02);
}
.home-image10 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.home-container52 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text142 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text145 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text150 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container53 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text151 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.home-container54 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container55 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1f1d26;
}
.home-link3 {
  display: contents;
}
.home-blog-post-card3 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-blog-post-card3:hover {
  transform: scale(1.02);
}
.home-image11 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.home-container56 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text152 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text153 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text154 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container57 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text155 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.home-container58 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1f1d26;
}
.home-link4 {
  display: contents;
}
.home-blog-post-card4 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-blog-post-card4:hover {
  transform: scale(1.02);
}
.home-image12 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.home-container59 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text156 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text157 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text158 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container60 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text159 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.home-container61 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1f1d26;
}
.home-link5 {
  display: contents;
}
.home-blog-post-card5 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-blog-post-card5:hover {
  transform: scale(1.02);
}
.home-image13 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.home-container62 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text160 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text161 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text162 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container63 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text163 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.home-navlink13 {
  color: #ffffff;
  width: auto;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.home-text164 {
  align-self: center;
  font-family: Space Grotesk;
}
.home-separator3 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.home-container64 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container65 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-container66 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text165 {
  color: rgb(255, 255, 255);
  /* margin: var(--dl-space-space-unit); */
  font-size: 56px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
}
.home-text167 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.home-container67 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-container68 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.home-text168 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.home-textinput {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.home-container69 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.home-text173 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.home-textinput1 {
  color: #ffffff;
  width: 350px;
  height: var(--dl-size-size-small);
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.home-container70 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.home-text178 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.home-textinput2 {
  color: #ffffff;
  width: 716px;
  height: var(--dl-size-size-large);
  align-items: stretch;
  font-family: Space Grotesk;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-gray-2);
}
.home-button {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #6741ff;
}

.home-text183 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .home-container {
    background-image: radial-gradient(
      circle at center,
      rgb(9, 4, 25) 4%,
      rgb(15, 15, 15) 50%,
      rgb(5, 1, 15) 100%
    );
  }
  .home-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text006 {
    color: rgb(255, 255, 255);
    font-size: 48px;
    font-weight: 600;
  }
  .home-container05 {
    width: 150px;
  }
  .home-text025 {
    font-size: 24px;
  }
  .home-text028 {
    font-size: 10px;
  }
  .home-container06 {
    width: 150px;
  }
  .home-text029 {
    font-size: 24px;
  }
  .home-text032 {
    font-size: 10px;
  }
  .home-container07 {
    width: 150px;
  }
  .home-text033 {
    font-size: 24px;
  }
  .home-text036 {
    font-size: 10px;
  }
  .home-image-1 {
    width: 35rem;
  }
  .home-container10 {
    display: none;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: 0px;
  }
  .home-text043 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
  }
  .home-image06 {
    width: 33rem;
  }
  .home-container19 {
    margin-left: var(--dl-space-space-fourunits);
    margin-right: 0px;
  }
  .home-text056 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
  }
  .home-text068 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
  }
  .home-image07 {
    width: 33rem;
  }
  .home-text125 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text126 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text136 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text137 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text142 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text145 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-navlink13 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .home-text168 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .home-textinput {
    width: 300px;
    height: 46px;
  }
  .home-text173 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .home-textinput1 {
    width: 300px;
    height: 46px;
  }
  .home-text178 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .home-textinput2 {
    width: 616px;
    height: var(--dl-size-size-large);
  }
  .home-button {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .home-about-us,
  .home-about-us1 {
    padding: 0;
  }

  .home-container {
    background-image: radial-gradient(
      circle at center,
      rgb(9, 4, 25) 4%,
      rgb(15, 15, 15) 50%,
      rgb(5, 1, 15) 100%
    );
  }
  .home-desktop-menu {
    display: none;
  }
  .home-btn-group {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .home-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .home-hero {
    flex-direction: column;
  }
  .home-container03 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text006 {
    text-align: center;
  }
  .home-text018 {
    text-align: center;
    padding-left: 0px;
  }
  .home-btn-group1 {
    flex-direction: column;
  }
  .home-sign-up-btn {
    margin: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .home-container08 {
    justify-content: center;
  }
  .home-image-1 {
    order: 2;
  }
  .home-about-us {
    flex-direction: column;
  }
  .home-container10 {
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text039 {
    color: var(--dl-color-gray-white);
    font-size: 42px;
    font-style: normal;
    text-align: center;
    font-family: Space Grotesk;
    font-weight: 600;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text041 {
    color: var(--dl-color-primary-purple);
    text-decoration: underline;
  }
  .home-text043 {
    text-align: left;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-sign-up-btn1 {
    display: none;
  }
  .home-container19 {
    display: none;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text051 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text052 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text053 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text054 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text056 {
    text-align: left;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-sign-up-btn2 {
    display: none;
  }
  .home-about-us1 {
    flex-direction: column;
  }
  .home-container28 {
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text064 {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text068 {
    text-align: left;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-sign-up-btn3 {
    display: none;
  }
  .home-image07 {
    margin-left: 0px;
  }
  .home-container37 {
    flex-direction: column;
  }
  .home-stat {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
    border-right-width: 2px;
  }
  .home-caption {
    text-align: center;
    line-height: 32px;
  }
  .home-stat1 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-caption1 {
    text-align: center;
    line-height: 32px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
    border-left-width: 2px;
  }
  .home-caption2 {
    text-align: center;
    line-height: 32px;
  }
  .home-text082 {
    font-size: 32px;
  }
  .home-container40 {
    width: 100%;
  }
  .home-text100 {
    color: rgba(255, 255, 255, 0.75);
    font-family: Space Grotesk;
    line-height: 1.2;
  }
  .home-container44 {
    flex-direction: column;
  }
  .home-container45 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image08 {
    height: 350px;
  }
  .home-container48 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image09 {
    height: 350px;
  }
  .home-text136 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text137 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-image10 {
    height: 350px;
  }
  .home-text142 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-text145 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .home-container54 {
    flex-direction: column;
  }
  .home-container55 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image11 {
    height: 350px;
  }
  .home-container58 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image12 {
    height: 350px;
  }
  .home-image13 {
    height: 350px;
  }
  .home-navlink13 {
    width: auto;
    height: var(--dl-size-size-small);
  }
  .home-text168 {
    text-align: left;
    padding-left: 0px;
  }
  .home-text173 {
    text-align: left;
    padding-left: 0px;
  }
  .home-text178 {
    text-align: left;
    padding-left: 0px;
  }
  .home-textinput2 {
    height: var(--dl-size-size-large);
  }
  .home-button {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media (max-width: 767px) {
  .home-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text018 {
    font-size: 14px;
  }
  .home-container05 {
    width: 140px;
  }
  .home-container06 {
    width: 140px;
  }
  .home-container07 {
    width: 140px;
  }
  .home-container08 {
    justify-content: center;
  }
  .home-image-1 {
    width: 100%;
  }
  .home-text039 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text043 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image06 {
    width: 80%;
  }
  .home-text051 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text052 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text053 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text054 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text056 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text064 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text068 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image07 {
    width: 80%;
  }
  .home-text076 {
    font-size: 42px;
  }
  .home-caption {
    font-size: 32px;
  }
  .home-description {
    font-size: 16px;
    line-height: 24px;
  }
  .home-caption1 {
    font-size: 32px;
  }
  .home-description1 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-caption2 {
    font-size: 32px;
  }
  .home-description2 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text079 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text081 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text082 {
    font-size: 32px;
  }
  .home-logo-container {
    padding: 12px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-logo {
    width: 16px;
    height: 16px;
  }
  .home-person {
    flex-direction: column;
  }
  .home-person-details {
    align-items: center;
  }
  .home-quote03 {
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    line-height: 36px;
  }
  .home-person1 {
    flex-direction: column;
  }
  .home-person-details1 {
    align-items: center;
  }
  .home-quote07 {
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    line-height: 36px;
  }
  .home-person2 {
    flex-direction: column;
  }
  .home-person-details2 {
    align-items: center;
  }
  .home-container40 {
    width: 767px;
  }
  .home-logo-container1 {
    padding: 12px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-logo1 {
    width: 16px;
    height: 16px;
  }
  .home-person3 {
    flex-direction: column;
  }
  .home-person-details3 {
    align-items: center;
  }
  .home-quote11 {
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    line-height: 36px;
  }
  .home-person4 {
    flex-direction: column;
  }
  .home-person-details4 {
    align-items: center;
  }
  .home-quote15 {
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    line-height: 36px;
  }
  .home-person5 {
    flex-direction: column;
  }
  .home-person-details5 {
    align-items: center;
  }
  .home-left {
    display: flex;
  }
  .home-right {
    display: flex;
  }
  .home-logo-container2 {
    padding: 12px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-logo2 {
    width: 16px;
    height: 16px;
  }
  .home-person6 {
    flex-direction: column;
  }
  .home-person-details6 {
    align-items: center;
  }
  .home-quote19 {
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    line-height: 36px;
  }
  .home-person7 {
    flex-direction: column;
  }
  .home-person-details7 {
    align-items: center;
  }
  .home-quote23 {
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    line-height: 36px;
  }
  .home-person8 {
    flex-direction: column;
  }
  .home-person-details8 {
    align-items: center;
  }
  .home-container67 {
    flex-direction: column;
  }
  .home-textinput {
    width: 440px;
  }
  .home-textinput1 {
    width: 440px;
  }
  .home-textinput2 {
    width: 440px;
  }
}
@media (max-width: 479px) {
  .home-container {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-blog {
    padding-left: 0;
    padding-right: 0;
  }
  .home-stats {
    padding-left: 0;
    padding-right: 0;
  }
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text018 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-btn-group1 {
    margin-bottom: 0px;
    flex-direction: column;
  }
  .home-btn-group2 {
    margin-top: 0px;
    align-items: flex-start;
    margin-bottom: 0px;
    flex-direction: column;
  }
  .home-container04 {
    margin-top: var(--dl-space-space-unit);
  }
  .home-container05 {
    width: 120px;
  }
  .home-text028 {
    font-size: 9px;
  }
  .home-container06 {
    width: 120px;
  }
  .home-text032 {
    font-size: 9px;
  }
  .home-container07 {
    width: 120px;
  }
  .home-text036 {
    font-size: 9px;
  }
  .home-container08 {
    flex-direction: column;
  }
  .home-container09 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }

  .home-container10 {
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text043 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
  }
  .home-text046 {
    text-align: center;
  }
  .home-text047 {
    text-align: center;
  }
  .home-text048 {
    text-align: left;
  }
  .home-text049 {
    text-align: center;
  }
  .home-text050 {
    text-align: center;
  }
  .home-image06 {
    width: 90%;
    height: 100%;
  }
  .home-container19 {
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text056 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
  }
  .home-text059 {
    text-align: center;
  }
  .home-text060 {
    text-align: center;
  }
  .home-text061 {
    text-align: center;
  }
  .home-text062 {
    text-align: center;
  }
  .home-text063 {
    text-align: center;
  }

  .home-container28 {
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text068 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    line-height: 1.4;
  }
  .home-text071 {
    text-align: center;
  }
  .home-text072 {
    text-align: center;
  }
  .home-text073 {
    text-align: center;
  }
  .home-text074 {
    text-align: center;
  }
  .home-text075 {
    text-align: center;
  }
  .home-image07 {
    width: 90%;
    height: 100%;
  }
  .home-text076 {
    text-align: center;
  }
  .home-stat {
    border-right-width: 2px;
  }
  .home-stat2 {
    border-left-width: 2px;
  }
  .home-text082 {
    text-align: center;
  }
  .home-container40 {
    width: 479px;
  }
  .home-buttons1 {
    max-width: 400px;
    margin-top: 30px;
    justify-content: space-evenly;
  }
  .home-container43 {
    height: auto;
    flex-direction: column;
  }
  .home-text122 {
    margin: var(--dl-space-space-unit);
    text-align: center;
  }
  .home-container66 {
    width: 100%;
    height: auto;
  }
  .home-text168 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-textinput {
    width: 320px;
  }
  .home-text173 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-textinput1 {
    width: 320px;
  }
  .home-container70 {
    align-self: center;
  }
  .home-text178 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-textinput2 {
    width: 320px;
  }
}
