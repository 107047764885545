.privacy-policy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(12, 8, 23) 6.00%,rgb(21, 21, 21) 54.00%,rgb(9, 3, 25) 100.00%);
}
.privacy-policy-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.privacy-policy-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.privacy-policy-navlink {
  display: contents;
}
.privacy-policy-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.privacy-policy-desktop-menu {
  display: flex;
}
.privacy-policy-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.privacy-policy-navlink01 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.privacy-policy-navlink01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.privacy-policy-navlink02 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-navlink02:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.privacy-policy-navlink03 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-navlink03:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.privacy-policy-navlink04 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-navlink04:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.privacy-policy-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.privacy-policy-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.privacy-policy-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.privacy-policy-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.privacy-policy-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.privacy-policy-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.privacy-policy-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.privacy-policy-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.privacy-policy-navlink05 {
  display: contents;
}
.privacy-policy-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.privacy-policy-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-policy-icon2 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.privacy-policy-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-navlink06 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-policy-navlink07 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-policy-navlink08 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-policy-navlink09 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-policy-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-login2 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-login2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.privacy-policy-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.privacy-policy-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.privacy-policy-login3 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.privacy-policy-login3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.privacy-policy-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.privacy-policy-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-image {
  width: 150px;
  object-fit: cover;
}
.privacy-policy-icon4 {
  width: 28px;
  height: 28px;
}
.privacy-policy-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.privacy-policy-text {
  text-decoration: none;
}
.privacy-policy-text001 {
  text-decoration: none;
}
.privacy-policy-text002 {
  text-decoration: none;
}
.privacy-policy-text003 {
  text-decoration: none;
}
.privacy-policy-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.privacy-policy-text004 {
  font-style: normal;
  font-weight: 500;
}
.privacy-policy-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.privacy-policy-text005 {
  font-style: normal;
  font-weight: 500;
}
.privacy-policy-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.privacy-policy-text006 {
  color: rgb(255, 255, 255);
  font-size: 4rem;
  text-align: center;
  font-family: Space Grotesk;
}
.privacy-policy-text007 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: var(--dl-size-size-maxwidth);
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-twounits);
}
.privacy-policy-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.privacy-policy-container03 {
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.privacy-policy-card {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text008 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text009 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card01 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text018 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text019 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card02 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text048 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text049 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-text053 {
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.privacy-policy-text058 {
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.privacy-policy-text063 {
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.privacy-policy-text068 {
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.privacy-policy-text073 {
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.privacy-policy-card03 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text082 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-container04 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-policy-text107 {
  color: #ffffff;
  width: var(--dl-size-size-large);
  height: 58px;
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  border-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.privacy-policy-text108 {
  color: #ffffff;
  width: var(--dl-size-size-large);
  height: 58px;
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  border-left-width: 0px;
}
.privacy-policy-text109 {
  color: rgb(255, 255, 255);
  width: 300px;
  height: 58px;
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  border-radius: 15px;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 50;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.privacy-policy-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-policy-text110 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xlarge);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-sixunits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-sixunits);
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-text111 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xlarge);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-sixunits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-sixunits);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-text112 {
  color: rgba(255, 255, 255, 0.5);
  width: 300px;
  height: 192px;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: 0px;
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-policy-text113 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-bottom-width: 0px;
}
.privacy-policy-text114 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-text115 {
  color: rgba(255, 255, 255, 0.5);
  width: 300px;
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-policy-text116 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-bottom-width: 0px;
}
.privacy-policy-text117 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-text118 {
  color: rgba(255, 255, 255, 0.5);
  width: 300px;
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-policy-text119 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  border-radius: 15px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.privacy-policy-text120 {
  color: rgba(255, 255, 255, 0.5);
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-left-width: 0px;
}
.privacy-policy-text121 {
  color: rgba(255, 255, 255, 0.5);
  width: 300px;
  height: var(--dl-size-size-large);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(255, 255, 255, 0.75);
  border-width: 0.5px;
  padding-left: 4px;
  border-radius: 15px;
  padding-right: 4px;
  padding-bottom: var(--dl-space-space-twounits);
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.privacy-policy-card04 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text122 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card05 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text138 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text139 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card06 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text164 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text165 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card07 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text246 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text247 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card08 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text301 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text302 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card09 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text359 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text360 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.privacy-policy-card10 {
  width: 60rem;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.privacy-policy-text372 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text373 {
  color: rgba(255, 255, 255, 0.5);
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
@media(max-width: 1200px) {
  .privacy-policy-container {
    background-image: radial-gradient(circle at center, rgb(9, 4, 25) 4.00%,rgb(15, 15, 15) 50.00%,rgb(5, 1, 15) 100.00%);
  }
  .privacy-policy-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .privacy-policy-navlink01 {
    color: rgba(255, 255, 255, 0.75);
    font-style: normal;
    font-weight: 500;
    border-bottom-width: 0px;
  }
  .privacy-policy-card {
    width: 55rem;
  }
  .privacy-policy-text009 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card01 {
    width: 55rem;
  }
  .privacy-policy-card02 {
    width: 55rem;
  }
  .privacy-policy-card03 {
    width: 55rem;
  }
  .privacy-policy-text082 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text086 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text090 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text094 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text098 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text107 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text108 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text109 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text110 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text111 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text112 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text113 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text114 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text115 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text116 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text117 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text118 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text119 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text120 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text121 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-card04 {
    width: 55rem;
  }
  .privacy-policy-text122 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text126 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card05 {
    width: 55rem;
  }
  .privacy-policy-text138 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Space Grotesk;
  }
  .privacy-policy-text139 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text141 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text146 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text151 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card06 {
    width: 55rem;
  }
  .privacy-policy-text164 {
    color: rgb(255, 255, 255);
  }
  .privacy-policy-text165 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text167 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text170 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text175 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text180 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text183 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text186 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text191 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text196 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text201 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text205 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text209 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text213 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text217 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text221 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text223 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text227 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text231 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card07 {
    width: 55rem;
  }
  .privacy-policy-text246 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Space Grotesk;
  }
  .privacy-policy-text247 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text251 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text256 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text261 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text266 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text271 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text276 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text281 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text286 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text291 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text296 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card08 {
    width: 55rem;
  }
  .privacy-policy-text301 {
    color: rgb(255, 255, 255);
  }
  .privacy-policy-text302 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text306 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text311 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text316 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text321 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text326 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text331 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text336 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text341 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text346 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text351 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card09 {
    width: 55rem;
  }
  .privacy-policy-text359 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Space Grotesk;
  }
  .privacy-policy-text360 {
    color: rgba(255, 255, 255, 0.5);
  }
  .privacy-policy-text364 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text367 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card10 {
    width: 55rem;
  }
  .privacy-policy-text372 {
    color: rgb(255, 255, 255);
  }
  .privacy-policy-text373 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
}
@media(max-width: 991px) {
  .privacy-policy-desktop-menu {
    display: none;
  }
  .privacy-policy-btn-group {
    display: none;
  }
  .privacy-policy-burger-menu {
    display: flex;
  }
  .privacy-policy-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .privacy-policy-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .privacy-policy-text007 {
    max-width: 100%;
  }
  .privacy-policy-card {
    width: 50rem;
  }
  .privacy-policy-text008 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Space Grotesk;
  }
  .privacy-policy-text009 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-card01 {
    width: 50rem;
  }
  .privacy-policy-text019 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-card02 {
    width: 50rem;
  }
  .privacy-policy-text049 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-card03 {
    width: 50rem;
  }
  .privacy-policy-text082 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-card04 {
    width: 50rem;
  }
  .privacy-policy-text122 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-card05 {
    width: 50rem;
  }
  .privacy-policy-text139 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-text141 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text146 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text151 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card06 {
    width: 50rem;
  }
  .privacy-policy-text165 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-text167 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text170 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text175 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text180 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text183 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text186 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text191 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text196 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text201 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text205 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text209 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text213 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text217 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text221 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text223 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text227 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text231 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card07 {
    width: 50rem;
  }
  .privacy-policy-text247 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-text251 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text256 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text261 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text266 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text271 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text276 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text281 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text286 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text291 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text296 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text300 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card08 {
    width: 50rem;
  }
  .privacy-policy-text302 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-text306 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text311 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text316 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text321 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text326 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text331 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text336 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text341 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text346 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text351 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card09 {
    width: 50rem;
  }
  .privacy-policy-text360 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .privacy-policy-card10 {
    width: 50rem;
  }
  .privacy-policy-text373 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .privacy-policy-container {
    background-image: radial-gradient(circle at center, rgb(9, 4, 25) 4.00%,rgb(15, 15, 15) 50.00%,rgb(5, 1, 15) 100.00%);
  }
  .privacy-policy-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .privacy-policy-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-navlink04 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-text007 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .privacy-policy-card {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text008 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text009 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card01 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text018 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text019 {
    color: rgba(255, 255, 255, 0.5);
    margin: var(--dl-space-space-unit);
    font-size: 14px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-text023 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text027 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text032 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text036 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text040 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card02 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text048 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text049 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card03 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text082 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card04 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text122 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card05 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text138 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text139 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card06 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text164 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text165 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card07 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text246 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text247 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card08 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text301 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text302 {
    color: rgba(255, 255, 255, 0.5);
    margin: var(--dl-space-space-unit);
    font-size: 14px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    font-family: Space Grotesk;
    font-weight: 300;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-text306 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text311 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text316 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text321 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text326 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text331 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text336 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text341 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text346 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-text351 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .privacy-policy-card09 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text359 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text360 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .privacy-policy-card10 {
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-text372 {
    margin-left: var(--dl-space-space-twounits);
  }
  .privacy-policy-text373 {
    margin: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    text-align: left;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .privacy-policy-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .privacy-policy-mobile-menu {
    padding: 16px;
  }
  .privacy-policy-text006 {
    color: rgb(255, 255, 255);
    font-size: 84px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
  .privacy-policy-text008 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text009 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text018 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text019 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text048 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text049 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text082 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text107 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .privacy-policy-text108 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .privacy-policy-text109 {
    width: 164px;
    height: var(--dl-size-size-medium);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .privacy-policy-text110 {
    width: var(--dl-size-size-medium);
    height: 354px;
  }
  .privacy-policy-text111 {
    width: var(--dl-size-size-medium);
    height: 354px;
  }
  .privacy-policy-text112 {
    width: 164px;
    height: 354px;
  }
  .privacy-policy-text113 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text114 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text115 {
    width: 164px;
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text116 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text117 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text118 {
    width: 164px;
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text119 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text120 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text121 {
    width: 164px;
    height: var(--dl-size-size-xlarge);
  }
  .privacy-policy-text122 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text138 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text139 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text164 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text165 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text246 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text247 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text301 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text302 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text359 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text360 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .privacy-policy-text372 {
    margin-left: var(--dl-space-space-unit);
  }
  .privacy-policy-text373 {
    color: rgba(255, 255, 255, 0.5);
    align-self: center;
    text-align: left;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
