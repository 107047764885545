.add-skills-container {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 500px;
  align-items: flex-start;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-primary-gray-2);
}
.add-skills-container1 {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.add-skills-text {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-family: Space Grotesk;
}
.add-skills-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.add-skills-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: rgba(207, 217, 240, 1);
}
.add-skills-container2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.add-skills-button {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.add-skills-button:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.add-skills-text1 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.add-skills-button1 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.add-skills-button1:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.add-skills-text2 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.add-skills-button2 {
  color: var(--dl-color-gray-700);
  width: auto;
  height: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.add-skills-button2:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.add-skills-text3 {
  font-size: 14px;
  align-self: center;
  font-family: Space Grotesk;
}
.add-skills-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.add-skills-button3 {
  color: #ffffff;
  width: 50%;
  height: 42px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-500);
}
.add-skills-button3:hover {
  background-color: var(--dl-color-gray-700);
}
.add-skills-text4 {
  align-self: center;
  font-family: Space Grotesk;
}
.add-skills-button4 {
  color: #ffffff;
  width: 50%;
  height: 42px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #6741ff;
}
.add-skills-button4:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.add-skills-text5 {
  align-self: center;
  font-family: Space Grotesk;
}
@media (max-width: 1200px) {
  .add-skills-button {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .add-skills-button1 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .add-skills-button2 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .add-skills-button3 {
    width: 50%;
    height: 42px;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .add-skills-button4 {
    width: 50%;
    height: 42px;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .add-skills-button {
    width: auto;
    height: auto;
  }
  .add-skills-button1 {
    width: auto;
    height: auto;
  }
  .add-skills-button2 {
    width: auto;
    height: auto;
  }
  .add-skills-container3 {
    width: 100%;
    flex-direction: row;
  }
  .add-skills-button3 {
    width: 50%;
    height: 42px;
  }
  .add-skills-button4 {
    width: 50%;
    height: 42px;
  }
}
@media (max-width: 767px) {
  .add-skills-button {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .add-skills-button1 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
  .add-skills-button2 {
    width: auto;
    margin-right: 0px;
    flex-direction: row;
  }
}
@media (max-width: 479px) {
  .add-skills-container {
    width: 80%;
  }
  .add-skills-text1 {
    text-align: center;
  }
  .add-skills-text2 {
    text-align: center;
  }
  .add-skills-text3 {
    text-align: center;
  }
}
