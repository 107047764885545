.success-wrapper,
.error-wrapper {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 20px;
}

.text-green-500 {
  color: green;
}

.text-danger-500 {
  color: red;
}

.success-title,
.error-title {
  padding: 15px 0;
}
