.email-verification-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.email-verification-container1 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  max-width: 500px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-unit);
  border-radius: 16px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-primary-gray-componant);
}
.email-verification-container2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.email-verification-text {
  color: var(--dl-color-gray-900);
  font-size: 28px;
  font-family: Space Grotesk;
}
.email-verification-text1 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-family: Space Grotesk;
}
.email-verification-text2 {
  color: var(--dl-color-gray-900);
  font-size: 14px;
  font-family: Space Grotesk;
}
.email-verification-container3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.email-verification-image {
  width: 140px;
  object-fit: cover;
}
.email-verification-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-verification-textinput {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: 36px;
  padding: var(--dl-space-space-halfunit);
  font-size: 22px;
  transition: 0.3s;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-halfunit);
  border-color: rgba(255, 255, 255, 0.5);
  margin-right: var(--dl-space-space-halfunit);
  background-color: rgba(255, 255, 255, 0.1);
}
.email-verification-textinput:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.email-verification-textinput1 {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: 36px;
  padding: var(--dl-space-space-halfunit);
  font-size: 22px;
  transition: 0.3s;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-halfunit);
  border-color: rgba(255, 255, 255, 0.5);
  margin-right: var(--dl-space-space-halfunit);
  background-color: rgba(255, 255, 255, 0.1);
}
.email-verification-textinput1:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.email-verification-textinput2 {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: 36px;
  padding: var(--dl-space-space-halfunit);
  font-size: 22px;
  transition: 0.3s;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-halfunit);
  border-color: rgba(255, 255, 255, 0.5);
  margin-right: var(--dl-space-space-halfunit);
  background-color: rgba(255, 255, 255, 0.1);
}
.email-verification-textinput2:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.email-verification-textinput3 {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: 36px;
  padding: var(--dl-space-space-halfunit);
  font-size: 22px;
  transition: 0.3s;
  font-family: Space Grotesk;
  margin-left: var(--dl-space-space-halfunit);
  border-color: rgba(255, 255, 255, 0.5);
  margin-right: var(--dl-space-space-halfunit);
  background-color: rgba(255, 255, 255, 0.1);
}
.email-verification-textinput3:hover {
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
}
.email-verification-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-verification-text3 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.email-verification-text4 {
  color: var(--dl-color-primary-300);
  padding: 0px;
  font-size: 14px;
  font-family: Space Grotesk;
  border-width: 0px;
  background-color: transparent;
}
.email-verification-button {
  color: var(--dl-color-gray-white);
  height: 36px;
  font-size: 14px;
  font-family: Space Grotesk;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-purple);
}
@media(max-width: 767px) {
  .email-verification-container1 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .email-verification-container2 {
    gap: var(--dl-space-space-halfunit);
  }
  .email-verification-container3 {
    gap: var(--dl-space-space-unit);
  }
  .email-verification-text3 {
    color: var(--dl-color-gray-900);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 300;
  }
  .email-verification-button {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
    border-width: 0px;
    border-radius: 4px;
    background-color: var(--dl-color-primary-purple);
  }
}
