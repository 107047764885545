.apply-as-a-candidate-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(12, 8, 23) 6.00%,rgb(21, 21, 21) 54.00%,rgb(9, 3, 25) 100.00%);
}
.apply-as-a-candidate-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.apply-as-a-candidate-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.apply-as-a-candidate-navlink {
  display: contents;
}
.apply-as-a-candidate-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.apply-as-a-candidate-desktop-menu {
  display: flex;
}
.apply-as-a-candidate-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.apply-as-a-candidate-navlink01 {
  color: #ffffff;
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  border-color: var(--dl-color-primary-purple);
  border-width: 0.5px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.apply-as-a-candidate-navlink01:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.apply-as-a-candidate-navlink02 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.apply-as-a-candidate-navlink02:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.apply-as-a-candidate-navlink03 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.apply-as-a-candidate-navlink03:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.apply-as-a-candidate-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.apply-as-a-candidate-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.apply-as-a-candidate-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.apply-as-a-candidate-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.apply-as-a-candidate-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.apply-as-a-candidate-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.apply-as-a-candidate-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.apply-as-a-candidate-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.apply-as-a-candidate-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.apply-as-a-candidate-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.apply-as-a-candidate-navlink04 {
  display: contents;
}
.apply-as-a-candidate-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.apply-as-a-candidate-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-as-a-candidate-icon2 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.apply-as-a-candidate-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.apply-as-a-candidate-navlink05 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.apply-as-a-candidate-navlink06 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.apply-as-a-candidate-navlink07 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.apply-as-a-candidate-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.apply-as-a-candidate-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.apply-as-a-candidate-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.apply-as-a-candidate-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.apply-as-a-candidate-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.apply-as-a-candidate-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.apply-as-a-candidate-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.apply-as-a-candidate-image {
  width: 150px;
  object-fit: cover;
}
.apply-as-a-candidate-icon4 {
  width: 28px;
  height: 28px;
}
.apply-as-a-candidate-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.apply-as-a-candidate-text {
  text-decoration: none;
}
.apply-as-a-candidate-text01 {
  text-decoration: none;
}
.apply-as-a-candidate-text02 {
  text-decoration: none;
}
.apply-as-a-candidate-text03 {
  text-decoration: none;
}
.apply-as-a-candidate-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.apply-as-a-candidate-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.apply-as-a-candidate-text04 {
  font-style: normal;
  font-weight: 500;
}
.apply-as-a-candidate-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.apply-as-a-candidate-text05 {
  font-style: normal;
  font-weight: 500;
}
.apply-as-a-candidate-a-s-t {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40rem;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.apply-as-a-candidate-container03 {
  display: flex;
  margin-left: 0px;
  flex-direction: column;
}
.apply-as-a-candidate-text06 {
  color: var(--dl-color-primary-300);
  font-size: 18px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  letter-spacing: 4px;
}
.apply-as-a-candidate-text07 {
  color: rgb(255, 255, 255);
  width: 700px;
  font-size: 52px;
  margin-top: var(--dl-space-space-unit);
  font-family: Space Grotesk;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}
.apply-as-a-candidate-text10 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.apply-as-a-candidate-text11 {
  color: var(--dl-color-primary-purple);
}
.apply-as-a-candidate-text12 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.apply-as-a-candidate-text13 {
  color: rgba(255, 255, 255, 0.75);
  width: 80%;
  font-size: 14px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
}
.apply-as-a-candidate-btn-group1 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.apply-as-a-candidate-navlink08 {
  display: contents;
}
.apply-as-a-candidate-sign-up-btn {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.apply-as-a-candidate-sign-up-btn:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.apply-as-a-candidate-sign-up {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
}
.apply-as-a-candidate-container04 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.apply-as-a-candidate-text18 {
  color: var(--dl-color-gray-white);
  max-width: 1440px;
  align-self: center;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.apply-as-a-candidate-navlink09 {
  color: var(--dl-color-primary-300);
  max-width: 1440px;
  align-self: center;
  font-family: Space Grotesk;
  text-decoration: underline;
}
.apply-as-a-candidate-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: 0px;
  justify-content: flex-start;
}
.apply-as-a-candidate-text19 {
  color: var(--dl-color-gray-white);
  font-family: Space Grotesk;
}
.apply-as-a-candidate-container06 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.apply-as-a-candidate-image01 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.apply-as-a-candidate-image02 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.apply-as-a-candidate-image03 {
  width: 36px;
  height: 36px;
  padding: 4px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.apply-as-a-candidate-image04 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.apply-as-a-candidate-image05 {
  width: 36px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
}
.apply-as-a-candidate-text20 {
  color: var(--dl-color-gray-white);
  font-size: 28px;
  font-family: Space Grotesk;
}
.apply-as-a-candidate-image06 {
  width: 37rem;
  object-fit: cover;
}
.apply-as-a-candidate-section {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.apply-as-a-candidate-header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.apply-as-a-candidate-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.apply-as-a-candidate-heading {
  font-size: 38px;
  font-family: Space Grotesk;
}
.apply-as-a-candidate-text22 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.apply-as-a-candidate-text25 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.apply-as-a-candidate-text26 {
  color: var(--dl-color-primary-purple);
}
.apply-as-a-candidate-right {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.apply-as-a-candidate-paragraph {
  width: 100%;
  font-size: 14px;
  max-width: 480px;
  font-family: Space Grotesk;
}
.apply-as-a-candidate-cards {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.apply-as-a-candidate-card {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  margin-top: 128px;
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.apply-as-a-candidate-image07 {
  width: 100px;
  align-self: flex-start;
  object-fit: cover;
}
.apply-as-a-candidate-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.apply-as-a-candidate-header01 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}
.apply-as-a-candidate-description {
  color: rgb(194, 198, 204);
  font-family: Poppins;
  line-height: 28px;
}
.apply-as-a-candidate-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  margin-top: var(--dl-space-space-fourunits);
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.apply-as-a-candidate-image08 {
  width: 100px;
  object-fit: cover;
}
.apply-as-a-candidate-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.apply-as-a-candidate-header02 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}
.apply-as-a-candidate-description1 {
  color: rgb(194, 198, 204);
  font-family: Poppins;
  line-height: 28px;
}
.apply-as-a-candidate-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 334px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.apply-as-a-candidate-image09 {
  width: 100px;
  object-fit: cover;
}
.apply-as-a-candidate-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.apply-as-a-candidate-header03 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}
.apply-as-a-candidate-description2 {
  color: rgb(194, 198, 204);
  font-family: Poppins;
  line-height: 28px;
}
.apply-as-a-candidate-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.apply-as-a-candidate-section1 {
  gap: var(--dl-space-space-sixunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.apply-as-a-candidate-header04 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.apply-as-a-candidate-left1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.apply-as-a-candidate-heading1 {
  font-size: 38px;
  text-align: center;
  font-family: Space Grotesk;
}
.apply-as-a-candidate-text30 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.apply-as-a-candidate-cards1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.apply-as-a-candidate-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 334px;
  min-height: 300px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.apply-as-a-candidate-content3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.apply-as-a-candidate-header05 {
  color: var(--dl-color-primary-purple);
  height: auto;
  margin: var(--dl-space-space-unit);
  font-size: 72px;
  font-style: normal;
  text-align: left;
  font-family: Space Grotesk;
  font-weight: 700;
  line-height: 36px;
}
.apply-as-a-candidate-container07 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.apply-as-a-candidate-header06 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.apply-as-a-candidate-description3 {
  color: rgb(194, 198, 204);
  font-family: Poppins;
  line-height: 28px;
}
.apply-as-a-candidate-image10 {
  width: 100px;
  object-fit: cover;
}
.apply-as-a-candidate-image11 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.apply-as-a-candidate-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 334px;
  min-height: 300px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.apply-as-a-candidate-content4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.apply-as-a-candidate-header07 {
  color: var(--dl-color-primary-purple);
  height: auto;
  margin: var(--dl-space-space-unit);
  font-size: 72px;
  font-style: normal;
  text-align: left;
  font-family: Space Grotesk;
  font-weight: 700;
  line-height: 36px;
}
.apply-as-a-candidate-container08 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.apply-as-a-candidate-header08 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.apply-as-a-candidate-description4 {
  color: #C2C6CC;
  font-family: Poppins;
  line-height: 28px;
}
.apply-as-a-candidate-image12 {
  width: 100px;
  object-fit: cover;
}
.apply-as-a-candidate-image13 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.apply-as-a-candidate-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 334px;
  min-height: 300px;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1F1D26;
}
.apply-as-a-candidate-content5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.apply-as-a-candidate-header09 {
  color: var(--dl-color-primary-purple);
  height: auto;
  margin: var(--dl-space-space-unit);
  font-size: 72px;
  font-style: normal;
  text-align: left;
  font-family: Space Grotesk;
  font-weight: 700;
  line-height: 36px;
}
.apply-as-a-candidate-container09 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 3px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.apply-as-a-candidate-header10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 600;
  line-height: 36px;
}
.apply-as-a-candidate-description5 {
  color: #C2C6CC;
  font-family: Poppins;
  line-height: 28px;
}
.apply-as-a-candidate-separator1 {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
@media(max-width: 1200px) {
  .apply-as-a-candidate-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .apply-as-a-candidate-text06 {
    color: var(--dl-color-primary-300);
  }
  .apply-as-a-candidate-text07 {
    color: rgb(255, 255, 255);
    width: auto;
    font-size: 52px;
    font-family: Space Grotesk;
    font-weight: 600;
  }
  .apply-as-a-candidate-text10 {
    color: var(--dl-color-primary-purple);
    text-decoration: underline;
  }
  .apply-as-a-candidate-text11 {
    color: var(--dl-color-primary-purple);
  }
  .apply-as-a-candidate-text12 {
    color: var(--dl-color-primary-purple);
    text-decoration: underline;
  }
  .apply-as-a-candidate-image06 {
    width: 35rem;
  }
  .apply-as-a-candidate-heading {
    font-size: 32px;
  }
  .apply-as-a-candidate-card1 {
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
  }
}
@media(max-width: 991px) {
  .apply-as-a-candidate-desktop-menu {
    display: none;
  }
  .apply-as-a-candidate-btn-group {
    display: none;
  }
  .apply-as-a-candidate-burger-menu {
    display: flex;
  }
  .apply-as-a-candidate-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .apply-as-a-candidate-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .apply-as-a-candidate-a-s-t {
    flex-direction: column;
  }
  .apply-as-a-candidate-container03 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .apply-as-a-candidate-text06 {
    font-size: 16px;
    text-align: center;
  }
  .apply-as-a-candidate-text07 {
    color: rgb(255, 255, 255);
    font-size: 38px;
    text-align: center;
    font-family: Space Grotesk;
    font-weight: 600;
  }
  .apply-as-a-candidate-text09 {
    color: var(--dl-color-primary-purple);
    text-decoration: underline;
  }
  .apply-as-a-candidate-text10 {
    color: var(--dl-color-primary-purple);
    text-decoration: underline;
  }
  .apply-as-a-candidate-text11 {
    color: var(--dl-color-primary-purple);
  }
  .apply-as-a-candidate-text12 {
    color: var(--dl-color-primary-purple);
    text-decoration: underline;
  }
  .apply-as-a-candidate-text13 {
    text-align: center;
    padding-left: 0px;
  }
  .apply-as-a-candidate-container05 {
    justify-content: center;
  }
  .apply-as-a-candidate-image06 {
    order: 2;
  }
  .apply-as-a-candidate-header {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .apply-as-a-candidate-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .apply-as-a-candidate-card2 {
    height: auto;
  }
  .apply-as-a-candidate-header04 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .apply-as-a-candidate-cards1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .apply-as-a-candidate-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .apply-as-a-candidate-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .apply-as-a-candidate-text07 {
    font-size: 28px;
  }
  .apply-as-a-candidate-text13 {
    font-size: 14px;
  }
  .apply-as-a-candidate-container05 {
    justify-content: center;
  }
  .apply-as-a-candidate-image06 {
    width: 80%;
  }
  .apply-as-a-candidate-left {
    gap: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-cards {
    flex-direction: column;
  }
  .apply-as-a-candidate-card1 {
    margin-top: 0px;
  }
  .apply-as-a-candidate-left1 {
    gap: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-cards1 {
    flex-direction: column;
  }
  .apply-as-a-candidate-image10 {
    display: none;
  }
  .apply-as-a-candidate-image11 {
    display: flex;
  }
  .apply-as-a-candidate-image12 {
    display: none;
  }
  .apply-as-a-candidate-image13 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .apply-as-a-candidate-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-mobile-menu {
    padding: 16px;
  }
  .apply-as-a-candidate-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-text13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .apply-as-a-candidate-btn-group1 {
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .apply-as-a-candidate-text18 {
    text-align: center;
  }
  .apply-as-a-candidate-navlink09 {
    text-align: center;
  }
  .apply-as-a-candidate-container05 {
    margin-top: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .apply-as-a-candidate-container06 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
}
