.navbar-log-in-navbar {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-skybue);
  border-width: 0.5px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-log-in-desktop {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-log-in-branding {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navbar-log-in-navlink {
  display: contents;
}
.navbar-log-in-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.navbar-log-in-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.navbar-log-in-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-log-in-image {
  width: 150px;
  object-fit: cover;
}
.navbar-log-in-icon {
  width: 28px;
  height: 28px;
}
.navbar-log-in-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.navbar-log-in-text {
  text-decoration: none;
}
.navbar-log-in-text1 {
  text-decoration: none;
}
.navbar-log-in-text2 {
  text-decoration: none;
}
.navbar-log-in-text3 {
  text-decoration: none;
}
.navbar-log-in-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.navbar-log-in-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.navbar-log-in-text4 {
  font-style: normal;
  font-weight: 500;
}
.navbar-log-in-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.navbar-log-in-text5 {
  font-style: normal;
  font-weight: 500;
}


















@media(max-width: 767px) {
  .navbar-log-in-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
