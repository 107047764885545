.courses-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(21, 14, 49) 3.00%,rgb(15, 15, 15) 61.00%);
}
.courses-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.courses-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.courses-navlink {
  display: contents;
}
.courses-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.courses-desktop-menu {
  display: flex;
}
.courses-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.courses-navlink1 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.courses-navlink1:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.courses-navlink2 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
}
.courses-navlink2:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.courses-text {
  color: rgba(255, 255, 255, 1);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-purple);
  border-width: 0px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom-width: 1px;
}
.courses-text:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.courses-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.courses-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.courses-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.courses-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.courses-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.courses-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.courses-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.courses-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.courses-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.courses-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.courses-navlink3 {
  display: contents;
}
.courses-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.courses-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.courses-icon2 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.courses-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.courses-navlink4 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.courses-navlink5 {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.courses-navlink6 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.courses-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.courses-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.courses-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.courses-login2 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.courses-login2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.courses-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.courses-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.courses-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.courses-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.courses-image {
  width: 150px;
  object-fit: cover;
}
.courses-icon4 {
  width: 28px;
  height: 28px;
}
.courses-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.courses-text01 {
  text-decoration: none;
}
.courses-text02 {
  text-decoration: none;
}
.courses-text03 {
  text-decoration: none;
}
.courses-text04 {
  text-decoration: none;
}
.courses-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.courses-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.courses-text05 {
  font-style: normal;
  font-weight: 500;
}
.courses-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.courses-text06 {
  font-style: normal;
  font-weight: 500;
}
.courses-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.courses-container3 {
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.courses-text07 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-size: 74px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.courses-text08 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-size: 68px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.courses-container4 {
  flex: 0 0 auto;
  width: var(--dl-size-size-xlarge);
  height: 6px;
  margin: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(90deg, rgb(103, 65, 255) 2.00%,rgb(56, 160, 237) 51.00%,rgb(0, 255, 231) 100.00%);
}
.courses-text09 {
  color: rgb(255, 255, 255);
  padding: var(--dl-space-space-unit);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
}
.courses-btn-group1 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.courses-navlink7 {
  color: #ffffff;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.courses-navlink7:hover {
  transform: scale(1.02);
}
.courses-text14 {
  font-family: Space Grotesk;
}
.courses-image1 {
  width: 35rem;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .courses-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .courses-text07 {
    color: rgb(255, 255, 255);
  }
  .courses-text08 {
    color: rgb(255, 255, 255);
    font-size: 74px;
    font-style: normal;
    font-weight: 400;
  }
  .courses-image1 {
    width: 50%;
  }
}
@media(max-width: 991px) {
  .courses-desktop-menu {
    display: none;
  }
  .courses-btn-group {
    display: none;
  }
  .courses-burger-menu {
    display: flex;
  }
  .courses-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .courses-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .courses-hero {
    flex-direction: column;
  }
  .courses-container3 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .courses-text07 {
    text-align: center;
  }
  .courses-text08 {
    text-align: center;
  }
  .courses-text09 {
    text-align: center;
  }
  .courses-image1 {
    order: 2;
    width: 80%;
  }
}
@media(max-width: 767px) {
  .courses-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .courses-navlink2 {
    margin-left: var(--dl-space-space-unit);
  }
  .courses-text {
    margin-left: var(--dl-space-space-unit);
  }
  .courses-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .courses-text07 {
    font-size: 66px;
  }
  .courses-text08 {
    font-size: 54px;
  }
  .courses-text09 {
    font-size: 24px;
  }
  .courses-image1 {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .courses-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .courses-mobile-menu {
    padding: 16px;
  }
  .courses-container3 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .courses-btn-group1 {
    flex-direction: column;
  }
}
