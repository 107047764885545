.blogs-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(12, 8, 23) 6.00%,rgb(21, 21, 21) 54.00%,rgb(9, 3, 25) 100.00%);
}
.blogs-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.blogs-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
.blogs-navlink {
  display: contents;
}
.blogs-finbest {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.blogs-desktop-menu {
  display: flex;
}
.blogs-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.blogs-navlink1 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.blogs-navlink1:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.blogs-navlink2 {
  color: rgba(255, 255, 255, 0.75);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
}
.blogs-navlink2:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.blogs-navlink3 {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-purple);
  border-width: 1px;
  border-radius: 0px;
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.blogs-navlink3:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}
.blogs-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blogs-login {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.blogs-login:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.blogs-login1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.blogs-login1:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.blogs-register {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.blogs-register:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.blogs-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.blogs-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blogs-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  max-width: auto;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090319;
}
.blogs-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blogs-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.blogs-navlink4 {
  display: contents;
}
.blogs-finbest1 {
  width: var(--dl-size-size-large);
  height: auto;
  text-decoration: none;
}
.blogs-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogs-icon2 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.blogs-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blogs-navlink5 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blogs-navlink6 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blogs-navlink7 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blogs-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.blogs-login2 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-purple);
}
.blogs-login2:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.blogs-register1 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.blogs-register1:hover {
  background-color: rgba(103, 65, 255, 0.25);
}
.blogs-login3 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Space Grotesk;
  font-weight: 500;
  padding-top: 14px;
  border-color: var(--dl-color-primary-purple);
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 8px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 14px;
  text-decoration: none;
  background-color: transparent;
}
.blogs-login3:hover {
  background-color: rgba(103, 65, 255, 0.75);
}
.blogs-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.blogs-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blogs-image {
  width: 150px;
  object-fit: cover;
}
.blogs-icon4 {
  width: 28px;
  height: 28px;
}
.blogs-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.blogs-text {
  text-decoration: none;
}
.blogs-text01 {
  text-decoration: none;
}
.blogs-text02 {
  text-decoration: none;
}
.blogs-text03 {
  text-decoration: none;
}
.blogs-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.blogs-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.blogs-text04 {
  font-style: normal;
  font-weight: 500;
}
.blogs-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.blogs-text05 {
  font-style: normal;
  font-weight: 500;
}
.blogs-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-banner {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.blogs-text06 {
  color: rgb(255, 255, 255);
  font-size: 5rem;
  text-align: center;
  font-family: Space Grotesk;
}
.blogs-text07 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.blogs-text09 {
  color: var(--dl-color-primary-purple);
  text-decoration: underline;
}
.blogs-text12 {
  color: var(--dl-color-gray-900);
  text-align: center;
  font-family: Space Grotesk;
}
.blogs-separator {
  width: 100%;
  height: 0.5px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-skybue);
}
.blogs-blog {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: Four0Units;
  align-items: center;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.blogs-container04 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.blogs-container05 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blogs-container06 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link {
  display: contents;
}
.blogs-blog-post-card {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card:hover {
  transform: scale(1.02);
}
.blogs-image01 {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.blogs-container07 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text16 {
  color: rgb(88, 146, 255);
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text17 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text19 {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 300;
}
.blogs-text23 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container08 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text26 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container09 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blogs-container10 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link01 {
  display: contents;
}
.blogs-blog-post-card01 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card01:hover {
  transform: scale(1.02);
}
.blogs-image02 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.blogs-container11 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text27 {
  color: rgb(88, 146, 255);
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text28 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text36 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container12 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text37 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container13 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link02 {
  display: contents;
}
.blogs-blog-post-card02 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card02:hover {
  transform: scale(1.02);
}
.blogs-image03 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container14 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text38 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text39 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text40 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container15 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text43 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container16 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link03 {
  display: contents;
}
.blogs-blog-post-card03 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card03:hover {
  transform: scale(1.02);
}
.blogs-image04 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container17 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text44 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text47 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text52 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container18 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text53 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container19 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blogs-container20 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link04 {
  display: contents;
}
.blogs-blog-post-card04 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card04:hover {
  transform: scale(1.02);
}
.blogs-image05 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container21 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text54 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text55 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text56 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container22 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text57 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container23 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link05 {
  display: contents;
}
.blogs-blog-post-card05 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card05:hover {
  transform: scale(1.02);
}
.blogs-image06 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container24 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text58 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text59 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text60 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container25 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text63 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container26 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link06 {
  display: contents;
}
.blogs-blog-post-card06 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card06:hover {
  transform: scale(1.02);
}
.blogs-image07 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container27 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text64 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text65 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text66 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container28 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text67 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container29 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blogs-container30 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link07 {
  display: contents;
}
.blogs-blog-post-card07 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card07:hover {
  transform: scale(1.02);
}
.blogs-image08 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container31 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text68 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text69 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text70 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container32 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text71 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container33 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link08 {
  display: contents;
}
.blogs-blog-post-card08 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card08:hover {
  transform: scale(1.02);
}
.blogs-image09 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container34 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text72 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text73 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text74 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container35 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text75 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container36 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link09 {
  display: contents;
}
.blogs-blog-post-card09 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card09:hover {
  transform: scale(1.02);
}
.blogs-image10 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container37 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text76 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text77 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text78 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container38 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text79 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container39 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blogs-container40 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link10 {
  display: contents;
}
.blogs-blog-post-card10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card10:hover {
  transform: scale(1.02);
}
.blogs-image11 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container41 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text80 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text81 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text82 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container42 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text83 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container43 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link11 {
  display: contents;
}
.blogs-blog-post-card11 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card11:hover {
  transform: scale(1.02);
}
.blogs-image12 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container44 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text84 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text85 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text86 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container45 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text89 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
.blogs-container46 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 26px;
  justify-content: space-between;
  background-color: #1F1D26;
}
.blogs-link12 {
  display: contents;
}
.blogs-blog-post-card12 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.blogs-blog-post-card12:hover {
  transform: scale(1.02);
}
.blogs-image13 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs-container47 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.blogs-text90 {
  color: #5892ff;
  font-size: 16px;
  font-style: normal;
  margin-top: 0px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-unit);
}
.blogs-text91 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-text92 {
  color: rgba(255, 255, 255, 0.75);
  font-style: normal;
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-twounits);
}
.blogs-container48 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blogs-text93 {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  font-weight: 300;
  border-radius: 25px;
}
@media(max-width: 1200px) {
  .blogs-navbar {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .blogs-blog-post-card {
    max-width: auto;
  }
  .blogs-image01 {
    width: 548px;
    height: auto;
  }
  .blogs-text16 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text17 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text23 {
    margin-bottom: 0px;
  }
  .blogs-container08 {
    display: none;
  }
  .blogs-text27 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text28 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text38 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text39 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text44 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text47 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text54 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text55 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text58 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text59 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text64 {
    color: rgb(88, 146, 255);
    font-size: 16px;
    font-weight: 300;
  }
  .blogs-text65 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
}
@media(max-width: 991px) {
  .blogs-desktop-menu {
    display: none;
  }
  .blogs-btn-group {
    display: none;
  }
  .blogs-burger-menu {
    display: flex;
  }
  .blogs-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .blogs-mobile-menu {
    border-color: var(--dl-color-primary-skybue);
    border-width: 0.5px;
    background-color: #05010f;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .blogs-banner {
    padding: var(--dl-space-space-twounits);
  }
  .blogs-text06 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-family: Space Grotesk;
  }
  .blogs-container05 {
    flex-direction: column;
  }
  .blogs-container06 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-blog-post-card {
    flex-direction: column;
  }
  .blogs-image01 {
    width: 100%;
    height: 350px;
  }
  .blogs-container08 {
    display: flex;
  }
  .blogs-container09 {
    flex-direction: column;
  }
  .blogs-container10 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image02 {
    height: 350px;
  }
  .blogs-container13 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image03 {
    height: 350px;
  }
  .blogs-text38 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text39 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-image04 {
    height: 350px;
  }
  .blogs-text44 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text47 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-container19 {
    flex-direction: column;
  }
  .blogs-container20 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image05 {
    height: 350px;
  }
  .blogs-container23 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image06 {
    height: 350px;
  }
  .blogs-text58 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text59 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-image07 {
    height: 350px;
  }
  .blogs-text64 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-text65 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 300;
  }
  .blogs-container29 {
    flex-direction: column;
  }
  .blogs-container30 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image08 {
    height: 350px;
  }
  .blogs-container33 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image09 {
    height: 350px;
  }
  .blogs-image10 {
    height: 350px;
  }
  .blogs-container39 {
    flex-direction: column;
  }
  .blogs-container40 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image11 {
    height: 350px;
  }
  .blogs-container43 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blogs-image12 {
    height: 350px;
  }
  .blogs-image13 {
    height: 350px;
  }
}
@media(max-width: 767px) {
  .blogs-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blogs-navlink2 {
    margin-left: var(--dl-space-space-unit);
  }
  .blogs-navlink3 {
    margin-left: var(--dl-space-space-unit);
  }
  .blogs-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .blogs-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .blogs-mobile-menu {
    padding: 16px;
  }
  .blogs-text06 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 500;
  }
}
