.client-step2-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at right, rgb(21, 14, 49) 3.00%,rgb(15, 15, 15) 59.00%);
}
.client-step2-container01 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.client-step2-container02 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.client-step2-text {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.client-step2-container03 {
  flex: 0 0 auto;
  width: 420px;
  height: 6px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-gray-2);
}
.client-step2-container04 {
  flex: 0 0 auto;
  width: 40%;
  height: 6px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-start;
  background-color: var(--dl-color-primary-purple);
}
.client-step2-container05 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.client-step2-text01 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 26px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.client-step2-text02 {
  color: var(--dl-color-gray-900);
  width: auto;
  font-size: 14px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.client-step2-container06 {
  width: 780px;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.client-step2-text03 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.client-step2-container07 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.client-step2-button {
  color: var(--dl-color-gray-700);
  width: 120px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text04 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-button1 {
  color: var(--dl-color-gray-700);
  width: 120px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button1:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text05 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-button2 {
  color: var(--dl-color-gray-700);
  width: 120px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button2:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text06 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-container08 {
  width: 780px;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.client-step2-text07 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 16px;
  font-style: normal;
  margin-top: 12px;
  font-family: Space Grotesk;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 0px;
}
.client-step2-container09 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.client-step2-button3 {
  color: var(--dl-color-gray-700);
  width: 82px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button3:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text08 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-button4 {
  color: var(--dl-color-gray-700);
  width: 82px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button4:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text09 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-button5 {
  color: var(--dl-color-gray-700);
  width: 82px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button5:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text10 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-button6 {
  color: var(--dl-color-gray-700);
  width: 82px;
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: transparent;
}
.client-step2-button6:active {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-purple);
}
.client-step2-text11 {
  align-self: center;
  font-family: Space Grotesk;
}
.client-step2-container10 {
  width: 616px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.client-step2-button7 {
  color: rgba(255, 255, 255, 0.5);
  padding: 0px;
  font-size: 16px;
  align-self: center;
  font-family: Space Grotesk;
  border-width: 0px;
  border-radius: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
  background-color: transparent;
}
.client-step2-navlink {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #6741ff;
}

.client-step2-text12 {
  align-self: center;
  font-family: Space Grotesk;
}
@media(max-width: 1200px) {
  .client-step2-text {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .client-step2-text01 {
    font-size: 26px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .client-step2-text02 {
    font-size: 14px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .client-step2-text03 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .client-step2-button {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-button1 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-button2 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-text07 {
    font-size: 16px;
    font-style: normal;
    font-family: Space Grotesk;
    font-weight: 400;
  }
  .client-step2-button3 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-button4 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-button5 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-button6 {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .client-step2-navlink {
    width: 120px;
    height: var(--dl-size-size-small);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .client-step2-text {
    text-align: left;
    padding-left: 0px;
  }
  .client-step2-text01 {
    text-align: left;
    padding-left: 0px;
  }
  .client-step2-text02 {
    text-align: left;
    padding-left: 0px;
  }
  .client-step2-text03 {
    text-align: left;
    padding-left: 0px;
  }
  .client-step2-button {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-button1 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-button2 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-text07 {
    text-align: left;
    padding-left: 0px;
  }
  .client-step2-button3 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-button4 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-button5 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-button6 {
    width: 120px;
    height: var(--dl-size-size-small);
  }
  .client-step2-navlink {
    width: 120px;
    height: var(--dl-size-size-small);
  }
}
@media(max-width: 767px) {
  .client-step2-container06 {
    align-self: center;
    flex-direction: column;
  }
  .client-step2-container08 {
    align-self: center;
    flex-direction: column;
  }
  .client-step2-container10 {
    width: 440px;
  }
}
@media(max-width: 479px) {
  .client-step2-container01 {
    align-items: center;
  }
  .client-step2-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .client-step2-container03 {
    width: 300px;
  }
  .client-step2-text01 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .client-step2-text02 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .client-step2-container06 {
    align-self: center;
  }
  .client-step2-text03 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .client-step2-container08 {
    align-self: center;
  }
  .client-step2-text07 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .client-step2-container10 {
    width: 320px;
  }
}
