.footer-v2-footer-v2 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: auto;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-primary-skybue);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.footer-v2-desktop {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-v2-text {
  color: var(--dl-color-gray-700);
}
.footer-v2-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-v2-navlink {
  color: var(--dl-color-gray-700);
  font-family: Space Grotesk;
  text-decoration: none;
}
.footer-v2-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.footer-v2-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-v2-image {
  width: 150px;
  object-fit: cover;
}
.footer-v2-icon {
  width: 28px;
  height: 28px;
}
.footer-v2-links {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-v2-text1 {
  text-decoration: none;
}
.footer-v2-text2 {
  text-decoration: none;
}
.footer-v2-text3 {
  text-decoration: none;
}
.footer-v2-text4 {
  text-decoration: none;
}
.footer-v2-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.footer-v2-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.footer-v2-text5 {
  font-style: normal;
  font-weight: 500;
}
.footer-v2-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.footer-v2-text6 {
  font-style: normal;
  font-weight: 500;
}



.footer-v2-root-class-name3 {
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
}








.footer-v2-root-class-name12 {
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
}


@media(max-width: 767px) {
  .footer-v2-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .footer-v2-root-class-name3 {
    position: static;
  }
  .footer-v2-root-class-name12 {
    position: static;
  }
}
@media(max-width: 479px) {
  .footer-v2-desktop {
    flex-direction: column;
  }
  .footer-v2-text {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-v2-root-class-name3 {
    left: 0px;
    bottom: 15px;
    position: static;
  }
  .footer-v2-root-class-name12 {
    left: 0px;
    bottom: 15px;
    position: static;
  }
}
